import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Col, Row, Spin, Tabs } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { useRouter } from "next/router";
import clsx from "clsx";
import styles from "@aspen/theme/Intelligence.module.less";
import type { IExpertAuthor, IExpertAuthorIResponse, IReqExpertAuthorList } from "@aspen/model";
import { message } from "@aspen/ui";
import { fecthExpertAuthorList, followExpert } from "@aspen/services";
import { GA_EVENT_NAME, i18nUtil, reportEvent } from "@aspen/libs";
import { ExpertItem, NewsCard } from "@aspen/widgets";

interface IProps {
  data: IExpertAuthorIResponse;
}

const ExpertList: React.FC<IProps> = ({ data }) => {
  const intl = i18nUtil.t();
  const allExperts = intl["intelligence.all.experts"];
  const followingList = intl["intelligence.expert.following.list"];
  const featuredExpertsText = intl["intelligence.featured.experts"];

  const router = useRouter();
  const defaultType = router?.query?.type?.toString() ?? "all";

  const [expertList, setExpertList] = useState<IExpertAuthor[]>([]);
  const [nextToken, setNextToken] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>(defaultType);
  const [featuredExperts, setFeaturedExperts] = useState<IExpertAuthor[]>([]);
  const [featuredNextToken, setFeaturedNextToken] = useState<string>("");
  const [featuredLoading, setFeaturedLoading] = useState<boolean>(false);

  useLayoutEffect(() => {
    // 获取精选列表
    getFeaturedExperts();
  }, []);

  useEffect(() => {
    if (defaultType === "followed") {
      getFollowedExpertList();
    } else {
      setNextToken(data?.nextToken);
      setExpertList(data?.rows);
    }
  }, [defaultType]);

  // 所有专栏
  const getExpertList: (next?: string) => void = (next = "") => {
    setLoading(true);
    const params: IReqExpertAuthorList = {
      limit: 15,
      expertsType: "ALL"
    };
    if (next) {
      params.nextToken = next;
    }
    fecthExpertAuthorList(params)
      .then((res) => {
        setExpertList(next ? expertList.concat(res?.data?.rows ?? []) : (res?.data?.rows ?? []));
        setNextToken(res?.data?.token);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // 已关注专栏
  const getFollowedExpertList: () => void = () => {
    setLoading(true);
    const params: IReqExpertAuthorList = {
      limit: 100,
      expertsType: "FOLLOWED"
    };
    fecthExpertAuthorList(params)
      .then((res) => {
        setExpertList(res?.data?.rows ?? []);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // 精选专栏
  const getFeaturedExperts = () => {
    setFeaturedLoading(true);
    const params: IReqExpertAuthorList = {
      limit: 10,
      expertsType: "CHOSEN" // 精选
    };
    fecthExpertAuthorList(params)
      .then((res) => {
        if (res?.code == "0") {
          setFeaturedExperts(
            nextToken ? featuredExperts.concat(res?.data?.rows ?? []) : (res?.data?.rows ?? [])
          );
          setFeaturedNextToken(res?.data?.token);
        }
      })
      .finally(() => {
        setFeaturedLoading(false);
      });
  };

  const handleLoadMore = () => {
    getExpertList(nextToken);
    reportEvent({ moduleName: GA_EVENT_NAME.intelligence.expert.loadMore });
  };

  const handleFeaturedLoadMore = () => {
    getFeaturedExperts();
    reportEvent({ moduleName: GA_EVENT_NAME.intelligence.expert.loadMore });
  };

  const tabItems = [
    {
      label: allExperts,
      key: "all"
    },
    {
      label: followingList,
      key: "followed"
    }
  ];

  // 切换Tab
  const handleChangeTab = (activeKey) => {
    reportEvent({
      moduleName: GA_EVENT_NAME.intelligence.expert_list.switchTab,
      detailParams: {
        activeKey
      }
    });
    // 切换tab时 去除路由传递的type
    if (defaultType === "followed") {
      router.replace({ query: null });
    }
    setCurrentTab(activeKey);
    setNextToken("");
    setExpertList([]);

    // 请求数据
    if (activeKey === "all") {
      getExpertList();
    } else {
      getFollowedExpertList();
    }
  };

  // 关注
  const handleFollowClick = (id) => {
    followExpert({ action: "follow", followId: id }).then((res) => {
      if (res?.code == "0") {
        if (currentTab === "all") {
          getExpertList();
        } else {
          getFollowedExpertList();
        }
      } else {
        message.error(intl?.[res?.msg] ?? res?.msg);
      }
    });
    reportEvent({
      moduleName: GA_EVENT_NAME.intelligence.expert_list.follow,
      detailParams: { id }
    });
  };

  // 取消关注
  const handleUnfollowClick = (id) => {
    followExpert({ action: "unfollow", followId: id }).then((res) => {
      if (res?.code == "0") {
        if (currentTab === "all") {
          getExpertList();
        } else {
          getFollowedExpertList();
        }
      } else {
        message.error(intl?.[res?.msg] ?? res?.msg);
      }
    });
    reportEvent({
      moduleName: GA_EVENT_NAME.intelligence.expert_list.unfollow,
      detailParams: { id }
    });
  };

  return (
    <section className={clsx(styles.intelligence, styles.expertList)}>
      <Row className={styles.row}>
        <Col flex="auto" className={styles.expertListContainer}>
          <Tabs
            className={styles.tab}
            activeKey={currentTab}
            items={tabItems}
            onChange={handleChangeTab}
          />
          <Spin spinning={loading}>
            <Row className={styles.articleList} gutter={16}>
              {expertList?.length > 0
                ? expertList?.map((item) => (
                    <Col key={item.id} span={8}>
                      <ExpertItem
                        data={item}
                        handleFollowClick={handleFollowClick}
                        handleUnfollowClick={handleUnfollowClick}
                      />
                    </Col>
                  ))
                : loading || <p className={styles.emptyText}>{intl?.["empty.list"]}</p>}
              {/* 分页 */}
              {currentTab === "all" &&
                !loading &&
                expertList?.length > 0 &&
                (nextToken && expertList.length >= 15 ? (
                  <div className={styles.paginationMask}>
                    <Button
                      className={styles.loadMore}
                      icon={<SyncOutlined />}
                      onClick={handleLoadMore}>
                      {intl["intelligence.loadMore"]}
                    </Button>
                  </div>
                ) : (
                  <p className={styles.noMoreData}>{intl["noMoreData"]}</p>
                ))}
            </Row>
          </Spin>
        </Col>
        {/* Featured Experts */}
        <Col flex="340px">
          <NewsCard
            className={styles.expertListRight}
            showLink={false}
            loading={featuredLoading}
            title={featuredExpertsText}>
            <div className={styles.featuredExpertList}>
              {featuredExperts?.length > 0 &&
                featuredExperts?.map((item, index) => (
                  <>
                    <ExpertItem key={item.id} data={item} showActions={false} showSummary={false} />
                    {index !== featuredExperts?.length - 1 && (
                      <div className={styles.divider} style={{ margin: "8px 0 24px" }} />
                    )}
                  </>
                ))}
              {/* 分页 */}
              {!featuredLoading &&
                featuredExperts?.length > 0 &&
                (featuredNextToken && featuredExperts.length >= 10 ? (
                  <div className={styles.loadMore} onClick={handleFeaturedLoadMore}>
                    {intl["button.loadMore"]}
                  </div>
                ) : (
                  <p className={styles.noMoreData}>{intl["noMoreData"]}</p>
                ))}
            </div>
          </NewsCard>
        </Col>
      </Row>
    </section>
  );
};

export const PageExpertList = React.memo(ExpertList);
