import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { withRouter } from "next/router";
import Link from "next/link";
import { Breadcrumb, Spin } from "antd";
import styles from "@aspen/theme/Wallet.module.less";
import { getCircleDepositInfo } from "@aspen/services";
import { getReferenceCurrencyBySystem, i18nUtil, systemDetection, WALLET_PATHS } from "@aspen/libs";
import type { IInjectProps } from "@aspen/model";
import { message } from "@aspen/ui";
import { History } from "@aspen/widgets";

interface IProps extends IInjectProps {}

function DepositUSDC(props: IProps) {
  const intl = i18nUtil.t();
  const system = systemDetection();
  const title_text: string = intl["wallet.circle.title"];
  const notice_text: string = intl["wallet.circle.notice"];
  const location_text: string = intl["kyc.certify.location"];
  const wallet_text: string = intl["wallet"];
  const bankInformation_text: string = intl["wallet.circle.bank.information"];
  const bankAccount_text: string = intl["wallet.circle.bank.account"];
  const acountNumber_text: string = intl["wallet.circle.account.number"];
  const maxDepositAmount_text: string = intl["wallet.circle.max.deposit.amount"];
  const pin_text: string = intl["wallet.circle.pin"];
  const noticeContent_text: string = intl["wallet.circle.deposit.notice.content"];
  const wiringInformation_text: string = intl["wallet.circle.wiring.information"];
  const beneficiary_text: string = intl["wallet.circle.beneficiary"];
  const beneficiaryName_text: string = intl["wallet.circle.bank.beneficiary.name"];
  const address_text: string = intl["wallet.circle.address"];
  const bankName_text: string = intl["wallet.circle.bank.name"];
  const swiftCode_text: string = intl["wallet.circle.swift"];
  const wireRouting_text: string = intl["wallet.circle.wire.routing"];
  const bankAddress_text: string = intl["wallet.circle.bank.address"];
  const city_text: string = intl["wallet.circle.city"];
  const country_text: string = intl["wallet.circle.country"];
  const postalCode_text: string = intl["wallet.circle.postal"];
  const instructions_text: string = intl["wallet.circle.instructions"];
  const instructionsContent_text = intl["wallet.circle.instructions.content"];
  const copyText: string = intl["button.copy"];

  const bankInfo: string[] = [
    bankName_text,
    swiftCode_text,
    wireRouting_text,
    bankAddress_text,
    city_text,
    country_text,
    postalCode_text
  ];

  useEffect(() => {
    getInitData();
  }, []);
  const [loading, setLoading] = useState<boolean>(false);
  const [pin, setPin] = useState<string>("");
  const [beneficiaryName, setBeneficiaryName] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [bankInfomation, setBankInfomation] = useState<any>([]);

  // 初始化数据,
  const getInitData: () => void = () => {
    setLoading(true);
    getCircleDepositInfo()
      .then((res) => {
        if (res?.code == "0") {
          const data = res?.data?.beneficiaryBank;
          const info = [
            data.name,
            data.swiftCode,
            data.routingNumber,
            data.address,
            data.city,
            data.country,
            data.postalCode
          ];
          setBankInfomation(info);
          setPin(res.data.referenceId);
          setBeneficiaryName(res.data?.beneficiary?.name);
          setAddress(`${res.data?.beneficiary?.address1},${res.data?.beneficiary?.address2}`);
          setAccountNumber(data.accountNumber);
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onCopy = () => {
    message.success(intl["copied"]);
  };

  return (
    <section className={styles.depositCircle}>
      <Spin spinning={loading}>
        <Breadcrumb>
          <Breadcrumb.Item>{location_text}: </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link href={WALLET_PATHS.WALLET}>{wallet_text}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{title_text}</Breadcrumb.Item>
        </Breadcrumb>
        <div className={styles.circleContent}>
          <p className={styles.title}>{title_text}</p>
          <div className={styles.bankInformation}>
            <p className={styles.title}>{bankInformation_text}</p>
            <div className={styles.container}>
              <div className={styles.infoContent}>
                <p className={styles.infoContentTitle}>{bankInformation_text}</p>
                <div className={styles.infoContentArea}>
                  <p>
                    <span>{acountNumber_text}</span>
                    <span>{props.router.query?.accountNumber}</span>
                  </p>
                  <div className={styles.limitContent}>
                    <p>{maxDepositAmount_text}</p>
                    <div className={styles.limitNumber}>
                      <p>{intl["constant.usdc.amount.week"]}</p>
                      <p>{intl["constant.usdc.amount.month"]}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.pin}>
                <p className={styles.pinTitle}>{pin_text}</p>
                <div className={styles.copyArea}>
                  <p className={styles.pinNumber}>{pin}</p>
                  <CopyToClipboard text={pin} onCopy={onCopy}>
                    <span>{copyText}</span>
                  </CopyToClipboard>
                </div>
                <div className={styles.pinNotice}>
                  <p>{notice_text}</p>
                  <p>{noticeContent_text}</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.wiringInformation}>
            <p className={styles.title}>{wiringInformation_text}</p>
            <div className={styles.container}>
              <p className={styles.WiringTitle}>{beneficiary_text}</p>
              <div className={styles.beneficiary}>
                <p>
                  <span>{beneficiaryName_text}</span>
                  <span>{beneficiaryName}</span>
                </p>
                <p>
                  <span>{address_text}</span>
                  <span>{address}</span>
                </p>
                <p>
                  <span>{bankAccount_text}</span>
                  <span>{accountNumber}</span>
                </p>
              </div>
              <p className={styles.WiringTitle}>{bankInformation_text}</p>
              <ul className={styles.beneficiary}>
                {bankInfo.map((item, index) => {
                  return (
                    <li key={index}>
                      <span>{item}</span>
                      <span>{bankInfomation[index]}</span>
                    </li>
                  );
                })}
              </ul>
              <p className={styles.instructionsTitle}>{instructions_text}</p>
              {instructionsContent_text.map((item, index) => {
                return (
                  <p key={index} className={styles.instructions}>
                    {item}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      </Spin>
      <History showViewAll type={1} currency={getReferenceCurrencyBySystem(system)} fiat />
    </section>
  );
}

export const PageDepositUsdc = withRouter(DepositUSDC);
