import React, { useEffect, useRef, useState } from "react";
import { Breadcrumb, Button, Spin } from "antd";
import { useRouter } from "next/router";
import Link from "next/link";
import styles from "@aspen/theme/Strategy.module.less";
import type { IKycLevel, IVentureListItem } from "@aspen/model";
import { COMMON_CACHE_PRIORTY_KEYS, FUND_TYPE } from "@aspen/model";
import { redeemFund, subscribeFund } from "@aspen/services";
import {
  FUNDS_PATHS,
  GA_EVENT_NAME,
  GA_EVENT_TAG,
  i18nUtil,
  judgeProductStatus,
  reportEvent
} from "@aspen/libs";
import { useCacheDataPriority } from "@aspen/hooks";
import { WithKYCInfo, HtmlContentComponent, message } from "@aspen/ui";
import type {
  ISubscribeInfoRefModel,
  SubVentureResultInfoType,
  SellVentureResultInfoType
} from "@aspen/widgets";
import {
  Disclaimer,
  ModalSubscribeSuccResult,
  ModalPrimarySubscribe,
  ModalFundRedeem,
  ModalSharesSuccResult
} from "@aspen/widgets";

interface IProps {
  showGuideModal: (level: IKycLevel, isClose?: boolean) => boolean;
}

const FundDetail: React.FC<IProps> = (props) => {
  const subResultInfoRef = useRef<SubVentureResultInfoType | null>(null);
  const redeemResultInfoRef = useRef<SellVentureResultInfoType | null>(null);
  const [modalSubResultVisible, setModalSubResultVisible] = useState<boolean>(false);
  const [primarySubscribe, setPrimarySubscribe] = useState<boolean>(false);
  const [modalRedeem, setModalRedeem] = useState<boolean>(false);
  const [modalRedeemResult, setmodalRedeemResult] = useState<boolean>(false);
  const intl = i18nUtil.t();
  const ventures_text: string = intl["column.fund"];
  const details_text: string = intl["ventures.detail.details"];

  const router = useRouter();
  const [info, updateInfo] = useState<IVentureListItem | null>(null);
  const { data, isLoading } = useCacheDataPriority({
    key: COMMON_CACHE_PRIORTY_KEYS.fundsDetail,
    params: {
      fundId: router.query?.id
    },
    useQueryExtraOptions: {
      enabled: !!router.query?.id
    }
  });

  useEffect(() => {
    if (data?.code == "0") {
      updateInfo(data?.data);
    } else if (data?.msg) {
      message.error(intl[data?.msg] ?? data?.msg);
    }
  }, [data?.code]);

  const handlePrimarySubmit = (subscribeInfo: ISubscribeInfoRefModel) => {
    if (!info?.id) return;
    const { amount } = subscribeInfo;
    subResultInfoRef.current = {
      assetType: info?.type,
      amount,
      currency: info?.currency,
      type: undefined
    };
    const subscribeParams = {
      productId: info?.id,
      amount
    };
    reportEvent({
      moduleName: GA_EVENT_NAME.fund.detail.submitSubscribe,
      detailParams: {
        ...subscribeParams
      }
    });
    subscribeFund(subscribeParams)
      .then((res) => {
        if (res?.code == "0") {
          reportEvent({
            joinedTag: GA_EVENT_TAG.Modal,
            moduleName: GA_EVENT_NAME.fund.detail.subsResult,
            detailParams: {
              ...subscribeParams
            }
          });
          setModalSubResultVisible(true);
        } else {
          message.error(intl["venture.modal.subscribe.error.tips"]);
        }
      })
      .finally(() => {
        setPrimarySubscribe(false);
      });
  };
  // 点击申购赎回
  const handleModalRedeem = () => {
    if (props.showGuideModal(2)) {
      if (!info?.id) return;
      setModalRedeem(true);
      reportEvent({
        joinedTag: GA_EVENT_TAG.Modal,
        moduleName: GA_EVENT_NAME.fund.detail.redeemModal,
        detailParams: {
          id: info?.id
        }
      });
    }
  };

  const handleRedeemSubmit = (listAmount: string) => {
    const params: any = {
      productId: info?.id ?? "",
      amount: listAmount
    };
    redeemResultInfoRef.current = {
      isOther: true,
      projectName: info?.name ?? "",
      assetType: intl[`fund.capital.${info?.type ?? 0}`],
      amount: listAmount,
      shares: 0,
      currency: info?.currency
    };
    reportEvent({
      moduleName: GA_EVENT_NAME.fund.detail.submitRedeem,
      detailParams: params
    });
    redeemFund(params)
      .then((res) => {
        if (res?.code == 0) {
          setmodalRedeemResult(true);
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .catch((error) => {
        message.error(intl?.[error?.msg] ?? error?.msg);
      })
      .finally(() => {
        setModalRedeem(false);
      });
  };

  if (!info) return null;

  return (
    <div className={styles.detail}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link
            href={{
              pathname: FUNDS_PATHS.FUNDS
            }}>
            {ventures_text}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{details_text}</Breadcrumb.Item>
      </Breadcrumb>
      <section id="title">
        <p className={styles.title}>{info?.name}</p>
        <div className="flex" style={{ marginBottom: 24 }}>
          {intl[FUND_TYPE[info?.type]] ? (
            <div className={styles.categroy}>{intl[FUND_TYPE[info?.type]]}</div>
          ) : null}
          {info?.attachmentUrl && info?.attachmentName ? (
            <Button
              type="ghost"
              className={styles.attachment}
              onClick={() => {
                reportEvent({
                  moduleName: GA_EVENT_NAME.fund.detail.openLink,
                  detailParams: {
                    type: "venture"
                  }
                });
                window.open(info?.attachmentUrl ?? "");
              }}>
              <span>{info?.attachmentName}</span>
            </Button>
          ) : null}
        </div>
      </section>
      <Spin spinning={isLoading}>
        <div className={styles.detailContent}>
          <HtmlContentComponent htmlContent={info?.detailInformation} />
          {info?.name ? (
            <div className={styles.btnWrap}>
              {judgeProductStatus(Number(info.startTime), Number(info.endTime)) === "ongoing" ? (
                <div className={styles.subBtnWrap}>
                  <Button
                    type="primary"
                    className={styles.actionBtn}
                    onClick={() => {
                      reportEvent({
                        joinedTag: GA_EVENT_TAG.Modal,
                        moduleName: GA_EVENT_NAME.fund.detail.submitSubscribe,
                        detailParams: {
                          id: info?.id
                        }
                      });
                      setPrimarySubscribe(true);
                    }}>
                    {intl["button.subscribe"]}
                  </Button>
                  {info?.amount ? (
                    <Button type="primary" className={styles.actionBtn} onClick={handleModalRedeem}>
                      {intl["button.redeem"]}
                    </Button>
                  ) : null}
                </div>
              ) : (
                <div className={styles.subBtnWrap}>
                  <Button type="primary" disabled className={styles.actionBtn}>
                    {judgeProductStatus(Number(info.startTime), Number(info.endTime)) === "close"
                      ? intl["button.closed"]
                      : intl["button.comingSoon"]}
                  </Button>
                </div>
              )}
            </div>
          ) : null}
        </div>
        {info?.name ? <Disclaimer /> : null}
      </Spin>
      {primarySubscribe ? (
        <ModalPrimarySubscribe
          productType="fund"
          visible={primarySubscribe}
          info={info}
          purchaseInformation={info?.purchaseInformation || ""}
          handleSubmit={handlePrimarySubmit}
          cancelModal={() => {
            setPrimarySubscribe(false);
          }}
        />
      ) : null}
      {modalSubResultVisible ? (
        <ModalSubscribeSuccResult
          productType="fund"
          visible={modalSubResultVisible}
          ventureName={info?.name}
          subResultInfo={subResultInfoRef?.current}
          cancelModal={() => {
            setModalSubResultVisible(false);
          }}
        />
      ) : null}
      {modalRedeem ? (
        <ModalFundRedeem
          visible={modalRedeem}
          cancelModal={() => {
            setModalRedeem(false);
          }}
          handleSubmit={handleRedeemSubmit}
          selectFund={info}
        />
      ) : null}
      {modalRedeemResult ? (
        <ModalSharesSuccResult
          productType="fund"
          visible={modalRedeemResult}
          sellResultInfo={redeemResultInfoRef.current}
          cancelModal={() => {
            setmodalRedeemResult(false);
          }}
        />
      ) : null}
    </div>
  );
};

export const PageFundDetail = WithKYCInfo(FundDetail);
