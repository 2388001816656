import React, { useEffect, useState } from "react";
import { Col, Row, Spin, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import styles from "@aspen/theme/Wallet.module.less";
import {
  convertUSD2USDC,
  decimalPointNoMoreX,
  i18nUtil,
  numberToThousands,
  reportEvent,
  GA_EVENT_NAME
} from "@aspen/libs";
import { getAMFList, getAMFSummary } from "@aspen/services";
import { message, WithEmptyContent } from "@aspen/ui";

function AUMFee() {
  const intl = i18nUtil.t();
  const loadMore = intl["button.loadMore"];
  const currency_text = intl["column.currency"];
  const feeTotal_text = intl["wallet.AUM.fee.total"];
  const feeSettled_text = intl["wallet.AUM.fee.settled"];
  const feeUnsettled_text = intl["wallet.AUM.fee.unsettled"];
  const status_text = intl["column.status"];
  const title_aum_fee = intl["title.aum.fee"];
  const column_aum_fee = intl["column.aum.fee"];
  const month_text = intl["column.month"];
  const column_deduction_time = intl["column.deduction.time"];
  const table_title = intl["aum.table.title"];

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [assetInfo, setAssetInfo] = useState({
    settledAmount: 0,
    unsettledAmount: 0,
    failedAmount: 0
  });

  useEffect(() => {
    initData();
  }, []);

  // 初始化数据,
  const initData: () => void = async () => {
    fetchAMFSummary();
    fetchAMFList();
  };

  //资产管理费总额
  const fetchAMFSummary = () => {
    getAMFSummary().then((res) => {
      if (res?.code == "0") {
        setAssetInfo(res?.data);
      } else {
        message.error(intl?.[res?.msg] ?? res?.msg);
      }
    });
  };

  //资产管理费历史
  const fetchAMFList = (type?: string) => {
    setLoading(true);
    const param = {
      limit: 10,
      nextToken: nextToken
    };
    getAMFList(param)
      .then((res) => {
        if (res?.code == "0") {
          if (type === "loadMore") {
            setDataSource(dataSource.concat(res.data?.rows || []));
          } else {
            setDataSource(res.data?.rows || []);
          }
          setNextToken(res.data?.nextToken);
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const columns: ColumnsType<any> = [
    {
      title: month_text,
      dataIndex: "amfDate",
      render: (value) => <span>{value?.substring(0, 4) + "-" + value?.substring(4, 6)}</span>, //目前前端截取
      align: "center"
    },
    {
      title: currency_text,
      dataIndex: "currency",
      render: (value) => <span>{convertUSD2USDC(value)}</span>,
      align: "center"
    },
    {
      title: column_aum_fee,
      dataIndex: "amount",
      render: (value) => <span>{numberToThousands(decimalPointNoMoreX(value, 2))}</span>,
      align: "center"
    },
    {
      title: column_deduction_time,
      dataIndex: "deductedTime",
      render: (value) => <span>{value ? dayjs(value).format("YYYY-MM-DD HH:mm:ss") : "--"}</span>,
      align: "center"
    },
    {
      title: status_text,
      dataIndex: "status",
      render: (value) => (
        <span>
          {value != "2" ? (value == "0" ? intl["unsettled"] : intl["settled"]) : intl["failed"]}
        </span>
      ),
      align: "center"
    }
  ];
  return (
    <section className={styles.wallet + " " + "customer"}>
      <div className="customerTop">
        <Row className="top">
          <Col span={24}>
            <p className="title">{title_aum_fee}</p>
          </Col>
        </Row>
      </div>
      <Spin spinning={loading}>
        <div className={styles.AUMFee}>
          <Row className={styles.assetContent}>
            <Col span={8} className={styles.assetItem}>
              <p className={styles.assetValue}>
                {`$ ${numberToThousands(
                  decimalPointNoMoreX(
                    assetInfo?.settledAmount + assetInfo?.unsettledAmount + assetInfo?.failedAmount,
                    2
                  )
                )}`}
              </p>
              <p className={styles.assetText}>{feeTotal_text}</p>
            </Col>
            <Col span={8} className={styles.assetItem + " " + styles.assetItemMiddle}>
              <p className={styles.assetValue}>
                {`$ ${numberToThousands(decimalPointNoMoreX(assetInfo?.settledAmount, 2))}`}
              </p>
              <p className={styles.assetText}>{feeSettled_text}</p>
            </Col>
            <Col span={8} className={styles.assetItem}>
              <p className={styles.assetValue}>
                {`$ ${numberToThousands(
                  decimalPointNoMoreX(assetInfo?.unsettledAmount + assetInfo?.failedAmount, 2)
                )}`}
              </p>
              <p className={styles.assetText}>{feeUnsettled_text}</p>
            </Col>
          </Row>

          <div className={styles.tableArea}>
            <div className={styles.tableTitle}>{table_title}</div>
            <WithEmptyContent showEmpty={true}>
              <Table
                className={styles.table}
                bordered={false}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
              />
            </WithEmptyContent>
            {nextToken && dataSource.length >= 10 && (
              <div
                className={styles.loadMore}
                onClick={() => {
                  fetchAMFList("loadMore");
                  reportEvent({ moduleName: GA_EVENT_NAME.wallet.loadMoreAum });
                }}>
                {loadMore}
              </div>
            )}
          </div>
        </div>
      </Spin>
    </section>
  );
}

export const PageAumFee = AUMFee;
