import React, { useEffect, useState } from "react";
import { Table, Row, Col, Spin } from "antd";
import { partial } from "lodash-es";
import styles from "@aspen/theme/Profile.module.less";
import { message, WithEmptyContent } from "@aspen/ui";
import { transformTime, i18nUtil } from "@aspen/libs";
import { getMyInvitationList } from "@aspen/services";

const MyInvitation = () => {
  const intl = i18nUtil.t();
  const title = intl["profile.my.invitation.title"];
  const email_text = intl["column.email"];
  const name_text = intl["column.name"];
  const status_text = intl["column.status"];
  const registration_text = intl["column.registration"];
  const loadMore = intl["button.loadMore"];

  const [showLoadMore, setShowLoadMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [offset, setOffset] = useState(0);

  const columns = [
    {
      title: email_text,
      dataIndex: "email"
    },
    {
      title: name_text,
      dataIndex: "name",
      ellipsis: true,
      render: (value) => value || "-"
    },
    {
      title: registration_text,
      dataIndex: "registerTime",
      render: (value: number) => <span>{transformTime(value)}</span>
    },
    {
      title: status_text,
      dataIndex: "kycLevel",
      render: (value: string) => (
        <span>{value == "L0" ? intl["registered"] : intl["verified"]}</span>
      )
    }
  ];

  useEffect(() => {
    fetchData(offset);
  }, []);

  const fetchData = (offset: number) => {
    setLoading(true);
    const params = {
      offset,
      limit: 10
    };
    getMyInvitationList(params)
      .then((res) => {
        if (res?.code == "0") {
          setDataSource(showLoadMore ? dataSource.concat(res?.data?.rows) : res?.data?.rows);
          setOffset(offset + 10);
          setShowLoadMore(res?.data?.rows?.length === 10);
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <section className={`${styles.myReferrals} ` + `customer`}>
      <div className="customerTop">
        <Row className="top">
          <Col span={24}>
            <p className="title">{title}</p>
          </Col>
        </Row>
      </div>
      <div className={styles.tableWrap}>
        <div className="section" style={{ width: "100%", marginTop: 0 }}>
          <div className={styles.myInvitation} style={{ marginTop: 0 }}>
            <div className={styles.myInvitationArea}>
              <WithEmptyContent showEmpty>
                <Spin spinning={loading}>
                  <Table
                    className={styles.table}
                    rowKey={(record: any) => record?.id}
                    bordered={false}
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                  />
                </Spin>
              </WithEmptyContent>
              {showLoadMore ? (
                <div className={styles.loadMore} onClick={partial(fetchData, offset)}>
                  {loadMore}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const PageMyInvitation = React.memo(MyInvitation);
