import React, { useEffect, useState } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import clsx from "clsx";
import { Button, Col, message, Row, Spin, Table } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import styles from "@aspen/theme/Wallet.module.less";
import {
  useAppDispatch,
  useAppSelector,
  showAmount,
  updateIsShowAmount,
  walletInfo
} from "@aspen/store";
import {
  convertUSD2USDC,
  decimalPointNoMoreX,
  i18nUtil,
  numberToThousands,
  BENCHMARK_CURRENCY_SYMBOL,
  USER_AUTH,
  USER_ROLE,
  WALLET_PATHS,
  isClient
} from "@aspen/libs";
import { getVaultAssetCurrencyConfig, getWalletVaultAccounts } from "@aspen/services";
import { IWalletSpotAccount, IKycLevel, ICurrencyConfig } from "@aspen/model";
import { CoinImage, WithCoinList, WithKYCInfo, WithEmptyContent } from "@aspen/ui";
import { ModalVaultStorageType } from "@aspen/widgets";

import securityImg from "@aspen/assets/images/security.webp";

interface IProps {
  showGuideModal: (level: IKycLevel, isClose?: boolean) => boolean;
}

const WalletVault: React.FC<IProps> = (props) => {
  const intl = i18nUtil.t();
  const router = useRouter();
  const title = intl["wallet.aspen.cold.storage"];
  const totalValue = intl["wallet.total.value"];
  const vaultTipsFirst = intl["wallet.vault.tips.first"];
  const vaultTipsSecond = intl["wallet.vault.tips.second"];
  const vaultTipsThird = intl["wallet.vault.tips.third"];

  const currencyText = intl["column.currency"];
  const amount = intl["column.amount"];
  const estValue = intl["wallet.vault.row.est"]; // 预估价值 amount * 实时币价
  const action = intl["column.action"];
  const storage = intl["action.deposit"];
  const withdrawal = intl["button.withdrawal"];

  const hiddenAmountText = intl["hiddenAmount"];

  const dispatch = useAppDispatch();
  const isShowAmount = useAppSelector(showAmount);
  const walletInfoData = useAppSelector(walletInfo);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<IWalletSpotAccount[]>([]);
  const [vaultAssetList, setVaultAssetList] = useState<ICurrencyConfig[]>([]);
  const [storageVisible, setStorageVisible] = useState<boolean>(false);
  const [currency, setCurrency] = useState<string>("");

  useEffect(() => {
    getData();
    _requestDepositList();
  }, []);

  const getData = () => {
    setLoading(true);
    getWalletVaultAccounts()
      .then((res) => {
        if (res?.code == "0") {
          setDataSource(res?.data ?? []);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const _requestDepositList = () => {
    if (isClient && localStorage.getItem(USER_AUTH) === USER_ROLE.ROLE_CUSTOMER) {
      getVaultAssetCurrencyConfig({}).then((res) => {
        if (res?.code == "0") {
          console.log(res?.data);
          setVaultAssetList(res?.data || []);
        } else {
          message.destroy();
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      });
    }
  };

  // 存储
  const handleClickStorage = (record) => {
    if (props.showGuideModal(1)) {
      setCurrency(record?.currency);
      setStorageVisible(true);
    }
  };

  const handleNavigateStorage = (type: string) => {
    router.push({
      pathname:
        type === "external" ? WALLET_PATHS.WALLET_VAULT_STORAGE : WALLET_PATHS.WALLET_VAULT_STORAGE,
      query: { type, currency }
    });
  };

  // 转出
  const handleClickWithdrawal = (record) => {
    if (props.showGuideModal(1)) {
      router.push({
        pathname: WALLET_PATHS.WALLET_VAULT_WITHDRAW,
        query: {
          type: "withdraw",
          currency: record?.currency
        }
      });
    }
  };

  // 判断是否能够充提
  const enableDepositorWithdraw = (record: IWalletSpotAccount, type: string) => {
    const targetCurrency = vaultAssetList?.filter((e) => e.currency === record.currency);
    return targetCurrency.length && targetCurrency[0][`enable${type}`];
  };

  const columns = [
    {
      title: currencyText,
      dataIndex: "currency",
      render: (value) => (
        <div className={styles.tableFlex}>
          <CoinImage size={48} coins={value} />
          <p style={{ marginLeft: 21 }}>{convertUSD2USDC(value)}</p>
        </div>
      )
    },
    {
      title: amount,
      dataIndex: "totalMoney",
      render: (value, row) => (
        <>
          <p>{isShowAmount ? decimalPointNoMoreX(value) : hiddenAmountText}</p>
        </>
      )
    },
    {
      title: estValue,
      dataIndex: "totalUsdMoney",
      render: (value) => (
        <p>
          {isShowAmount
            ? BENCHMARK_CURRENCY_SYMBOL + numberToThousands(decimalPointNoMoreX(value, 2))
            : hiddenAmountText}
        </p>
      )
    },
    {
      title: action,
      width: 260,
      // 子账号、代理商、帮客系统不显示storage入口
      render: (value, record) => (
        <div className={styles.tableFlex}>
          {isClient && localStorage.getItem(USER_AUTH) === USER_ROLE.ROLE_CUSTOMER && (
            <>
              <Button
                className={styles.actionBtn}
                disabled={!enableDepositorWithdraw(record, "Deposit")}
                onClick={() => handleClickStorage(record)}>
                {storage}
              </Button>
              <Button
                className={styles.actionBtn}
                disabled={!enableDepositorWithdraw(record, "Withdraw")}
                onClick={() => handleClickWithdrawal(record)}>
                {withdrawal}
              </Button>
            </>
          )}
        </div>
      )
    }
  ];

  const tipsText = [vaultTipsFirst, vaultTipsSecond, vaultTipsThird];

  return (
    <section className={styles.wallet + " " + "customer"}>
      <div className="customerTop">
        <div className="top">
          <span className="title">{title}</span>
          <div
            style={{ display: "inline-block" }}
            onClick={() => dispatch(updateIsShowAmount(!isShowAmount))}>
            {isShowAmount ? (
              <EyeOutlined className={styles.hideIcon} />
            ) : (
              <EyeInvisibleOutlined className={styles.hideIcon} />
            )}
          </div>
        </div>
      </div>
      <div className="section">
        <div className={styles.wallet}>
          <Row className={styles.walletDashboard}>
            <Col className={styles.dashboard} style={{ width: 506 }}>
              <div className={styles.inner} style={{ width: "100%" }}>
                <p className={styles.number}>
                  {isShowAmount
                    ? BENCHMARK_CURRENCY_SYMBOL +
                      numberToThousands(
                        decimalPointNoMoreX(walletInfoData?.coldWalletAmount, 2) || 0
                      )
                    : hiddenAmountText}
                </p>
                <p className={styles.name}>{totalValue}</p>
              </div>
            </Col>
            <Col className={styles.dashboard} style={{ width: 676, padding: 0 }}>
              <div className={styles.vaultContent}>
                <span className={styles.img}>
                  <Image src={securityImg} alt="" width={86} height={86} />
                </span>
                <div className={styles.tips}>
                  {tipsText.map((text, index) => (
                    <p key={index}>
                      <i className={styles.circle} />
                      <span>{text}</span>
                    </p>
                  ))}
                </div>
                {isClient && localStorage.getItem(USER_AUTH) === USER_ROLE.ROLE_CUSTOMER && (
                  <Button type="primary" className={styles.storageBtn} onClick={handleClickStorage}>
                    {storage}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
          <section className={clsx(styles.walletList, styles.vaultList)}>
            <Spin spinning={loading}>
              <WithEmptyContent showEmpty={true}>
                <Table
                  className={styles.table}
                  bordered={false}
                  rowKey={(record) => record.id}
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                />
              </WithEmptyContent>
            </Spin>
          </section>
        </div>
      </div>
      {storageVisible ? (
        <ModalVaultStorageType
          visible={storageVisible}
          handleCancel={() => setStorageVisible(false)}
          handleNavigate={(type) => handleNavigateStorage(type)}
        />
      ) : null}
    </section>
  );
};

export const PageVault = WithKYCInfo(WithCoinList(React.memo(WalletVault)));
