import React from "react";
import { Col, Row, Space } from "antd";
import styles from "@aspen/theme/Intelligence.module.less";
import {
  NewsMenu,
  InsightsBanner,
  TabInsights,
  Research,
  Expert,
  LatestNews,
  Announcement
} from "@aspen/widgets";
import { useAsideHeight } from "@aspen/hooks";

const Insights = () => {
  const { ref: asideRef } = useAsideHeight();
  return (
    <section className={styles.intelligence}>
      <Row className={styles.row}>
        <Col>
          <NewsMenu />
        </Col>
        <Col flex="auto" className={styles.middleContainer}>
          {/*置顶显示最新的日报、周报、月报轮播*/}
          <InsightsBanner />
          {/*日报、周报、月报tab*/}
          <TabInsights />
        </Col>
        <Col flex="276px" ref={asideRef} className={styles.rightContainer}>
          <Space size={16} direction="vertical" style={{ width: 276 }}>
            {/* 最新公告 */}
            <Announcement />
            {/* 热点追踪 */}
            <LatestNews />
            {/* 精选研报 */}
            <Research />
            {/* 推荐专栏 */}
            <Expert />
          </Space>
        </Col>
      </Row>
    </section>
  );
};

export const PageInsights = React.memo(Insights);
