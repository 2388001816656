import React, { useState, useLayoutEffect } from "react";
import { Row, Col, Space, Button, Spin } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { useRouter } from "next/router";
import Image from "next/image";
import clsx from "clsx";
import styles from "@aspen/theme/Intelligence.module.less";
import { message } from "@aspen/ui";
import type {
  INews,
  IReqIntelligenceNews,
  IIntelligenceIResponse,
  IExpertAuthor
} from "@aspen/model";
import { ISSRParmas, NEWSTYPE } from "@aspen/model";
import {
  NewsMenu,
  Report,
  ExpertAuthorCardItem,
  ExpertTopArticle,
  FeaturedExperts,
  NewsCard,
  NewsCardItem
} from "@aspen/widgets";
import {
  GA_EVENT_NAME,
  INTELLIGENCE_PATHS,
  NOT_LOGIN_CODE,
  ssrSetHeaders,
  ssrToLogin,
  transformTime,
  i18nUtil,
  reportEvent
} from "@aspen/libs";
import { fecthExpertAuthorList, fecthIntelligenceNews, followExpert } from "@aspen/services";
import { useAsideHeight } from "@aspen/hooks";

import defaultAvatar from "@aspen/assets/images/avatar.png";

interface IProps {
  data: IIntelligenceIResponse;
}

const Expert: React.FC<IProps> = ({ data }) => {
  const intl = i18nUtil.t();
  const followingText = intl["intelligence.expert.my.following"];
  const moreText = intl["link.more"];
  const featuredExpertsText = intl["intelligence.featured.experts"];
  const latestArticles = intl["intelligence.latest.articles"];
  const researchReport = intl["intelligence.research.report"];
  const hotNewsText = intl["intelligence.news.hot.news.text"];

  // 专栏文章列表
  const [expertList, setExpertList] = useState<INews[]>([]);
  const [nextToken, setNextToken] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  // 置顶专栏文章
  const [topArticles, setTopArticles] = useState<INews[]>([]);
  const [topArticleLoading, setTopArticleLoading] = useState<boolean>(false);
  // 精选专栏
  const [featuredExperts, setFeaturedExperts] = useState<IExpertAuthor[]>([]);
  const [featuredExpertLoading, setFeaturedExpertLoading] = useState<boolean>(false);
  // 已关注专栏
  const [followedExperts, setFollowedExperts] = useState<IExpertAuthor[]>([]);
  const [followedExpertLoading, setFollowedExpertLoading] = useState<boolean>(false);
  // 研报
  const [researchList, setResearchList] = useState<INews[]>([]);
  const [researchLoading, setResearchLoading] = useState<boolean>(false);
  // 热点追踪
  const [newsList, setNewsList] = useState<INews[]>([]);
  const [newsLoading, setNewsLoading] = useState<boolean>(false);
  const router = useRouter();

  const { ref: asideRef } = useAsideHeight();

  useLayoutEffect(() => {
    setNextToken(data?.nextToken);
    setExpertList(data?.rows ?? []);

    // 获取置顶专栏文章
    getTopArticle();
    // 获取精选专栏
    getFeaturedExpertList();
    // 获取已关注的专栏
    getFollowedExpertList();

    getResearchList();
    getNewsList();
  }, []);

  // 置顶的专栏文章
  const getTopArticle = () => {
    setTopArticleLoading(true);
    const params: IReqIntelligenceNews = {
      limit: 6,
      action: "recommend",
      type: NEWSTYPE.expert // 专栏
    };
    fecthIntelligenceNews(params)
      .then((res) => {
        if (res?.code == "0") {
          setTopArticles(res?.data?.rows ?? []);
        }
      })
      .finally(() => {
        setTopArticleLoading(false);
      });
  };

  // 获取精选专栏
  const getFeaturedExpertList: () => void = () => {
    setFeaturedExpertLoading(true);
    fecthExpertAuthorList({
      limit: 10,
      expertsType: "CHOSEN"
    })
      .then((res) => {
        if (res?.code == "0") {
          setFeaturedExperts(res?.data?.rows ?? []);
        }
      })
      .finally(() => {
        setFeaturedExpertLoading(false);
      });
  };

  // 获取关注的专栏作家
  const getFollowedExpertList: () => void = () => {
    setFollowedExpertLoading(true);
    fecthExpertAuthorList({
      limit: 10, // 后端不支持limit参数，
      expertsType: "FOLLOWED"
    })
      .then((res) => {
        if (res?.code == "0") {
          const data = res?.data?.rows ?? [];
          setFollowedExperts(data.slice(0, 2));
        }
      })
      .finally(() => {
        setFollowedExpertLoading(false);
      });
  };

  // 获取最新专栏文章列表
  const getExpertArticles = () => {
    setLoading(true);
    const params: IReqIntelligenceNews = {
      limit: 15,
      action: "all",
      type: NEWSTYPE.expert, // 专栏
      nextToken
    };

    fecthIntelligenceNews(params)
      .then((res) => {
        if (res?.code == "0") {
          setNextToken(res?.data?.nextToken);
          if (res?.data?.rows?.length > 0) {
            setExpertList(nextToken ? expertList.concat(res?.data?.rows) : (res?.data?.rows ?? []));
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // 获取研报列表
  const getResearchList = () => {
    setResearchLoading(true);
    const params: IReqIntelligenceNews = {
      limit: 5,
      action: "all",
      type: NEWSTYPE.research // 研报
    };
    fecthIntelligenceNews(params)
      .then((res) => {
        if (res?.code == "0") {
          setResearchList(res?.data?.rows ?? []);
        }
      })
      .finally(() => {
        setResearchLoading(false);
      });
  };

  // 获取 Hot News List
  const getNewsList = () => {
    setNewsLoading(true);
    const params: IReqIntelligenceNews = {
      limit: 4,
      action: "all",
      type: NEWSTYPE.news // 爬虫新闻
    };
    fecthIntelligenceNews(params)
      .then((res) => {
        if (res?.code == "0") {
          setNewsList(res?.data?.rows ?? []);
        }
      })
      .finally(() => {
        setNewsLoading(false);
      });
  };

  const handleLoadMore = () => {
    getExpertArticles();
    reportEvent({ moduleName: GA_EVENT_NAME.intelligence.expert.loadMore });
  };

  // 查看文章详情
  const handleClickArticle = (id) => {
    reportEvent({
      moduleName: GA_EVENT_NAME.intelligence.expert.articleList,
      detailParams: { id }
    });
    router.push(`${INTELLIGENCE_PATHS.INTELLIGENCE_EXPERT_DETAIL}/${id}`);
  };

  // 查看作者主页
  const handleClickViewAuthor = (id) => {
    reportEvent({
      moduleName: GA_EVENT_NAME.intelligence.expert.listExpert,
      detailParams: { id }
    });
    router.push(`${INTELLIGENCE_PATHS.INTELLIGENCE_EXPERT_AUTHOR_DETAIL}/${id}`);
  };

  // 关注
  const handleFollowClick = (id) => {
    followExpert({ action: "follow", followId: id }).then((res) => {
      if (res?.code == "0") {
        // 获取精选专栏
        getFeaturedExpertList();
        // 获取已关注的专栏
        getFollowedExpertList();
      } else {
        message.error(intl?.[res?.msg] ?? res?.msg);
      }
    });
    reportEvent({
      moduleName: GA_EVENT_NAME.intelligence.expert.follow,
      detailParams: { id }
    });
  };

  // 取消关注
  const handleUnfollowClick = (id) => {
    followExpert({ action: "unfollow", followId: id }).then((res) => {
      if (res?.code == "0") {
        // 获取精选专栏
        getFeaturedExpertList();
        // 获取已关注的专栏
        getFollowedExpertList();
      } else {
        message.error(intl?.[res?.msg] ?? res?.msg);
      }
    });
    reportEvent({
      moduleName: GA_EVENT_NAME.intelligence.expert.unfollow,
      detailParams: { id }
    });
  };

  return (
    <section className={clsx(styles.intelligence, styles.expert)}>
      <Row className={styles.row}>
        <Col>
          <NewsMenu />
        </Col>
        <Col flex="auto" className={styles.expertContainer}>
          {/* 置顶专栏轮播 */}
          {topArticles && topArticles?.length > 0 ? (
            <ExpertTopArticle data={topArticles} loading={topArticleLoading} />
          ) : null}
          {/* 精选专栏 */}
          {featuredExperts?.length >= 3 && (
            <div className={styles.featuredExperts}>
              <div className={styles.header}>
                <p className={styles.title}>{featuredExpertsText}</p>
                <a
                  onClick={() => {
                    reportEvent({
                      moduleName: GA_EVENT_NAME.intelligence.expert.viewAll
                    });
                    router.push(INTELLIGENCE_PATHS.INTELLIGENCE_EXPERT_LIST);
                  }}>
                  {intl["link.view.all"]} &gt;
                </a>
              </div>
              <FeaturedExperts
                data={featuredExperts}
                loading={featuredExpertLoading}
                handleFollowClick={handleFollowClick}
                handleUnfollowClick={handleUnfollowClick}
              />
            </div>
          )}
          {/* 最新文章 */}
          <div className={styles.latestArticles}>
            <p className={styles.title}>{latestArticles}</p>
            <Spin spinning={loading}>
              <div className={styles.articles}>
                {expertList?.length > 0
                  ? expertList?.map((item) => (
                      <div key={item.id} className={styles.articleItem}>
                        <div
                          className={styles.userInfoContent}
                          onClick={() => handleClickViewAuthor(item?.authorCustomer?.id)}>
                          <Image
                            unoptimized
                            className={styles.img}
                            src={item?.authorCustomer?.avatar || defaultAvatar}
                            alt=""
                            width={48}
                            height={48}
                          />
                          <div className={styles.userInfo}>
                            <p className={styles.author}>{item?.authorCustomer?.name}</p>
                            <p className={styles.date}>{transformTime(+item?.newsTime)}</p>
                          </div>
                        </div>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => handleClickArticle(item?.id)}>
                          <p className={styles.articleTitle}>{item?.title}</p>
                          <p className={styles.articleSummary}>{item?.summary}</p>
                        </div>
                        <div className={styles.articleType}>
                          {item?.keyWords?.length > 0
                            ? item?.keyWords?.map(
                                (key, index) =>
                                  index < 5 && (
                                    <div key={index} className={styles.type}>
                                      {key?.length > 15 ? `${key?.slice(0, 15)}...` : key}
                                    </div>
                                  )
                              )
                            : null}
                        </div>
                        <div className={styles.divider} />
                      </div>
                    ))
                  : loading || <p className={styles.emptyText}>{intl?.["empty.list"]}</p>}
                {/* 分页 */}
                {!loading &&
                  expertList?.length > 0 &&
                  (nextToken && expertList?.length >= 15 ? (
                    <div className={styles.paginationMask}>
                      <Button
                        className={styles.loadMore}
                        icon={<SyncOutlined />}
                        onClick={handleLoadMore}>
                        {intl["intelligence.loadMore"]}
                      </Button>
                    </div>
                  ) : (
                    <>
                      <p className={styles.noMoreData}>{intl["noMoreData"]}</p>
                      {/* 解决分页的占位问题 */}
                      <div style={{ height: 200 }} />
                    </>
                  ))}
              </div>
            </Spin>
          </div>
        </Col>
        <Col
          flex="276px"
          ref={asideRef}
          className={styles.expertRightContainer}
          style={{
            position: followedExpertLoading || researchLoading || newsLoading ? "static" : "sticky"
          }}>
          <Spin spinning={followedExpertLoading || researchLoading || newsLoading}>
            <Space size={16} direction="vertical" style={{ width: 276 }}>
              {/* 我关注的 */}
              {followedExperts?.length > 0 && (
                <NewsCard
                  eventType="follow"
                  title={followingText}
                  linkText={moreText}
                  link={`${INTELLIGENCE_PATHS.INTELLIGENCE_EXPERT_LIST}?type=followed`}>
                  {followedExperts?.map((item) => (
                    <ExpertAuthorCardItem key={item.id} data={item} />
                  ))}
                </NewsCard>
              )}
              {/* 日报 */}
              <Report title="intelligence.insights.daily.briefing" type={NEWSTYPE.daily} />
              {/* 周报 */}
              <Report
                title="intelligence.insights.weekly.newsletter"
                type={NEWSTYPE.weeklyReport}
              />
              {/* 月报 */}
              <Report title="intelligence.insights.monthly.review" type={NEWSTYPE.monthlyReport} />
              {/* 精选研报 */}
              <NewsCard
                eventType="research"
                title={researchReport}
                linkText={moreText}
                link={INTELLIGENCE_PATHS.INTELLIGENCE_RESEARCH}>
                {researchList?.length > 0 ? (
                  researchList?.map((item, index) => (
                    <div
                      key={item.id}
                      className={styles.researchCardItem}
                      onClick={() => {
                        router.push(
                          `${INTELLIGENCE_PATHS.INTELLIGENCE_RESEARCH_DETAIL}/${item?.id}`
                        );
                      }}>
                      <div className={styles.number}>{index + 1}</div>
                      <div className={styles.articleTitle}>{item.title}</div>
                    </div>
                  ))
                ) : (
                  <p className={styles.noMoreData}>{intl["noMoreData"]}</p>
                )}
              </NewsCard>
              {/* 热点追踪 */}
              <NewsCard
                eventType="news"
                title={hotNewsText}
                linkText={moreText}
                link={INTELLIGENCE_PATHS.INTELLIGENCE_NEWS}>
                {newsList?.length > 0 ? (
                  newsList?.map((item) => (
                    <NewsCardItem
                      key={item.id}
                      data={item}
                      path={INTELLIGENCE_PATHS.INTELLIGENCE_NEWS_DETAIL}
                    />
                  ))
                ) : (
                  <p className={styles.noMoreData}>{intl["noMoreData"]}</p>
                )}
              </NewsCard>
            </Space>
          </Spin>
        </Col>
      </Row>
    </section>
  );
};

export const PageExpert = React.memo(Expert);
