import React, { useEffect, useState } from "react";
import { EVENTS_PATHS, getEventStatus, i18nUtil } from "@aspen/libs";
import { useRouter } from "next/router";
import type { ConferenceVO, IEventStatus } from "@aspen/model";
import { EVENT_ACTIONS } from "@aspen/model";
import styles from "@aspen/theme/Event.module.less";
import { CommonVideo, HtmlContentComponent } from "@aspen/ui";
import { Button } from "antd";
import { AppEventBanner, AppEventRegisterInfo, JumpApp, EmptyShareDetail } from "@aspen/widgets";

interface IProps {
  detailData: ConferenceVO | undefined;
}

const EventDetailView: React.FC<IProps> = (props) => {
  const { detailData } = props;
  const intl = i18nUtil.t();
  const router = useRouter();
  const id = (router.query?.id as string) || "";
  const [status, setStatus] = useState<IEventStatus>("coming");
  const [link, setLink] = useState<string | undefined>(undefined);

  useEffect(() => {
    detailData && setStatus(getEventStatus(detailData.startTime, detailData.endTime));
  }, [id]);

  const register = () => {
    if (!detailData) {
      return;
    }
    if (detailData.type === "ONLINE") {
      //线上活动直接跳转报名链接
      detailData.address && window.open(detailData.address);
    } else {
      //线下活动跳转app
      link && window.open(link, "_blank");
    }
  };

  if (!detailData) {
    return (
      <div className={styles.mobileDetail}>
        <EmptyShareDetail />
      </div>
    );
  }

  return (
    <div className={styles.mobileDetail}>
      <div className={styles.content}>
        <AppEventBanner data={detailData} status={status} />
        <div className={styles.box}>
          <AppEventRegisterInfo data={detailData} status={status} />
          {detailData.video ? (
            <div style={{ marginTop: 16 }}>
              <CommonVideo url={detailData.video} />
            </div>
          ) : undefined}
          <div className={styles.aboutTitle}>{intl["event.detail.about"]}</div>
          {detailData.detail ? (
            <HtmlContentComponent
              htmlContent={detailData.detail}
              style={{
                minHeight: undefined,
                justifyContent: "flex-start",
                marginTop: 16
              }}
            />
          ) : null}

          {status == "coming" ? (
            <Button type="primary" className={styles.registerBtn} onClick={register}>
              {intl["event.detail.Register"]}
            </Button>
          ) : undefined}
        </div>
      </div>
      <JumpApp
        event_action={EVENT_ACTIONS.conferenceDetail}
        id={id}
        path={EVENTS_PATHS.EVENTS_DETAIL}
        onLinkBuild={(link: string) => {
          setLink(link);
        }}
      />
    </div>
  );
};
export const PageAppEventDetail = React.memo(EventDetailView);
