import React from "react";
import { Row, Col, Breadcrumb, Spin, Select, Modal, Button } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import Link from "next/link";
import { withRouter } from "next/router";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styles from "@aspen/theme/Wallet.module.less";
import { getDepositAddress, getAssetCurrencyConfig } from "@aspen/services";
import {
  convertUSD2USDC,
  getQueryValue,
  scientificToNumber,
  i18nUtil,
  certifiedKycLevel,
  WALLET_PATHS,
  systemDetection
} from "@aspen/libs";
import {
  ADGM_BRAND,
  type ICurrencyAsset,
  type ICurrencyConfig,
  type IInjectProps,
  type IKycLevel
} from "@aspen/model";
import { message, WithTrimInput, WithKYCInfo } from "@aspen/ui";
import { AdgmInputArea, History } from "@aspen/widgets";

const { Option } = Select;

interface IProps extends IInjectProps {
  showGuideModal: (level: IKycLevel, isClose?: boolean) => boolean;
}

interface IState {
  depositAddress: string;
  coinList: ICurrencyConfig[];
  currentCoin: string;
  currentTag: string;
  loading: boolean;
  showTips: boolean;
  showAdgmTips: boolean;
  chainList: ICurrencyAsset[];
  currentChain: string;
  availableNetwork: number;
  depositNetwork: number;
}

class Deposit extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      depositAddress: "",
      coinList: [],
      currentCoin: "",
      currentTag: "",
      loading: false,
      showTips: false,
      showAdgmTips: false,
      chainList: [],
      currentChain: "",
      availableNetwork: 0,
      depositNetwork: 0
    };
  }

  componentDidMount() {
    const system = systemDetection();
    if (this.props.showGuideModal(1, true)) {
      this.getCoinList();
      if (system === ADGM_BRAND) {
        this.setState({ showAdgmTips: true });
      }
    }
  }

  getCoinList: () => void = () => {
    const intl = i18nUtil.t();
    const currency = this.props?.router?.query?.currency?.toString() ?? getQueryValue("currency");
    this.setState({
      loading: true
    });
    // todo adgm coinList 是否返回usdt？
    getAssetCurrencyConfig({})
      .then((res) => {
        if (res?.code == "0") {
          const list = res.data ?? [];
          const hasCurrency = currency && list.some((e) => e?.currency == currency);
          if (hasCurrency) {
            this.setState({
              coinList: list,
              currentCoin: currency
            });
            this.fetchChainList(currency, list);
          } else {
            this.setState({
              coinList: list,
              currentCoin: list[0]?.currency,
              showTips: !!currency
            });
            this.fetchChainList(list[0]?.currency, list);
          }
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .catch(() => {})
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  //获取链地址
  fetchChainList = (currency: string, list?: ICurrencyConfig[]) => {
    const arr = list ?? this.state.coinList;
    const currentList = arr?.filter((e) => e.currency == currency) || [];
    const chainList = currentList?.[0]?.assets?.filter((item) => item.chainType !== "FIAT") || [];
    this.showDepositAddress(chainList[0]?.asset || "");
    this.setState({
      chainList,
      currentChain: chainList?.[0]?.asset || "",
      availableNetwork: currentList?.[0]?.assets?.[0]?.availableNetwork || 0,
      depositNetwork: currentList?.[0]?.assets?.[0]?.depositNetwork || 0
    });
  };

  //先获取电子货币地址
  showDepositAddress(coincode) {
    let value: string = coincode;
    this.setState({
      loading: true
    });
    const params = { currency: value, required: true };
    getDepositAddress(params)
      .then((res) => {
        if (res?.code == "0") {
          this.setState({
            depositAddress: res.data.address,
            currentTag: res.data?.memo && res.data?.memo,
            loading: false
          });
        } else {
          this.setState({
            depositAddress: "",
            loading: false
          });
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  getText(): { [propName: string]: any } {
    // 不修改
    const intl = i18nUtil.t();
    const location: string = intl["kyc.certify.location"];
    const wallet: string = intl["wallet"];
    const information: string = intl["wallet.deposit.btc"];
    const title: string = intl["wallet.deposit.btc.title"];
    const select: string = intl["wallet.deposit.btc.select"];
    const address = intl["wallet.deposit.btc.address"];
    const tag = intl["wallet.deposit.btc.tag"];
    const qrcodeTips = intl["wallet.deposit.btc.qrcode.tips"];
    const tips1 = intl["wallet.deposit.btc.tips1"];
    const tips2 = intl["wallet.deposit.btc.tips2"];
    const notice = intl["wallet.deposit.btc.notice"];
    const modalTitle = intl["wallet.deposit.modal.title"];
    const modalContent = intl["wallet.deposit.modal.content"];
    const okText: string = intl["button.confirm"];
    const cancelText: string = intl["button.cancel"];
    const copyText: string = intl["button.copy"];

    return {
      location,
      wallet,
      title,
      select,
      information,
      address,
      tag,
      qrcodeTips,
      tips1,
      tips2,
      notice,
      modalTitle,
      modalContent,
      okText,
      cancelText,
      copyText
    };
  }

  handleChange = (value) => {
    // this.showDepositAddress(value);
    this.fetchChainList(value);
    this.setState({
      depositAddress: "",
      currentCoin: value
    });
  };
  handleCancelTips = () => {
    this.setState({
      showTips: false
    });
  };
  handleChangeChain = (value) => {
    this.showDepositAddress(value);
    this.setState({
      currentChain: value
    });
  };
  onCopy = () => {
    const intl = i18nUtil.t();
    message.success(intl["copied"]);
  };

  render(): React.ReactNode {
    const {
      location,
      wallet,
      information,
      select,
      address,
      tag,
      tips1,
      notice,
      modalTitle,
      okText,
      copyText
    } = this.getText();
    const {
      depositAddress,
      coinList,
      loading,
      currentCoin,
      currentTag,
      showTips,
      chainList,
      showAdgmTips,
      currentChain,
      availableNetwork,
      depositNetwork
    } = this.state;
    // 获取当前链数据
    const currentChainData = chainList.find((item) => item.asset == currentChain);
    const intl = i18nUtil.t();
    const system = systemDetection();

    return (
      <section className={styles.deposit}>
        <Spin spinning={loading}>
          <Breadcrumb>
            <Breadcrumb.Item>{location}:</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link href={WALLET_PATHS.WALLET}>{wallet}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{convertUSD2USDC(currentCoin) + information}</Breadcrumb.Item>
          </Breadcrumb>
          <div className={styles.depositContent}>
            <p className={styles.title}>{convertUSD2USDC(currentCoin) + information}</p>
            <Row>
              <Col flex="auto" className={styles.depositLeft}>
                <div className={styles.currentCoin}>
                  <div className={styles.currentCoinLeft}>
                    <p className={styles.label}>{select}</p>
                    <Select
                      className={styles.selectCoin}
                      value={currentCoin}
                      style={{ width: 100 }}
                      onChange={(e) => this.handleChange(e)}>
                      {coinList.map((item, index) => {
                        return (
                          <Option key={index} value={item.currency}>
                            {convertUSD2USDC(item.currency)}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className={styles.currentCoinRight}>
                    <p className={styles.label}>{intl["wallet.deposit.btc.select.network"]}</p>
                    <Select
                      className={styles.selectCoin}
                      value={currentChain}
                      style={{ width: 100 }}
                      onChange={(e) => this.handleChangeChain(e)}>
                      {chainList.map((item, index) => {
                        return (
                          <Option key={index} value={item.asset}>
                            {item.chainName}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
                <div style={{ marginBottom: 20 }}>
                  <p className={styles.label}>{intl["wallet.minimum.deposit"]}</p>
                  <p className={styles.minDepositAmount}>
                    {currentChainData?.minDepositAmount
                      ? `${scientificToNumber(
                          currentChainData?.minDepositAmount
                        )} ${convertUSD2USDC(currentCoin)}`
                      : "--"}
                  </p>
                </div>
                <p className={styles.label}>{convertUSD2USDC(currentCoin) + address}</p>
                {!showAdgmTips && (
                  <div className={styles.copyArea}>
                    <WithTrimInput type="text" value={depositAddress} disabled />
                    <CopyToClipboard text={depositAddress} onCopy={this.onCopy}>
                      <span>{copyText}</span>
                    </CopyToClipboard>
                  </div>
                )}
                {currentCoin == "XRP" ? (
                  <div>
                    <p className={styles.label}>{currentCoin + tag}</p>
                    <div className={styles.copyArea}>
                      <WithTrimInput type="text" value={currentTag} disabled />
                      <CopyToClipboard text={currentTag} onCopy={this.onCopy}>
                        <span>{copyText}</span>
                      </CopyToClipboard>
                    </div>
                  </div>
                ) : null}
                <Row>
                  <Col flex="20px">
                    <InfoCircleFilled className={styles.redInfoCircleFilledStyle} />
                  </Col>
                  <Col flex="auto">
                    <p className={styles.tips}>{tips1}</p>
                    <p className={styles.tips}>
                      {i18nUtil.formatMessage(
                        { id: "wallet.deposit.btc.tips2" },
                        {
                          currentCoin: convertUSD2USDC(currentCoin)
                        }
                      )}
                    </p>
                  </Col>
                </Row>
              </Col>
              {certifiedKycLevel(1) ? (
                <Col
                  flex="300px"
                  style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <p className={styles.label}>
                    {i18nUtil.formatMessage(
                      { id: "wallet.deposit.btc.qrcode.tips" },
                      {
                        currentCoin: convertUSD2USDC(currentCoin)
                      }
                    )}
                  </p>
                  {!showAdgmTips && (
                    <div className={styles.QRCode}>
                      <QRCode value={depositAddress} size={170} />
                    </div>
                  )}
                </Col>
              ) : null}
            </Row>

            {
              // 仅用于充币提币页面 adgm虚假的input输入框 没有交互功能
              system === ADGM_BRAND && <AdgmInputArea type="deposit" />
            }
            <div className={styles.noticeContent}>
              <p className={styles.title}>{notice}</p>
              <p>
                {i18nUtil.formatMessage(
                  { id: "wallet.deposit.btc.notice.first" },
                  {
                    currentCoin: convertUSD2USDC(currentCoin)
                  }
                )}
              </p>
              <p>
                {i18nUtil.formatMessage(
                  { id: "wallet.deposit.btc.notice.second" },
                  {
                    depositNetwork,
                    availableNetwork
                  }
                )}
              </p>
              <p>{intl["wallet.deposit.btc.notice.third"]}</p>
            </div>
          </div>
        </Spin>

        <History showViewAll type={1} currency={currentCoin} fiat={false} />
        <Modal
          wrapClassName={styles.modal}
          open={showAdgmTips}
          closable={false}
          maskClosable={false}
          centered
          onOk={() => this.setState({ showAdgmTips: false })}
          onCancel={() => this.setState({ showAdgmTips: false })}
          footer={[
            <Button
              type="primary"
              key="cancel"
              onClick={() => this.setState({ showAdgmTips: false })}>
              {intl["modal.compliance.agree"]}
            </Button>
          ]}>
          <div style={{ marginTop: 30, fontSize: 16 }}>{intl["adgm.wallet.modal.content"]}</div>
        </Modal>
        <Modal
          title={modalTitle}
          wrapClassName={styles.modal}
          open={showTips}
          onOk={this.handleCancelTips}
          onCancel={this.handleCancelTips}
          footer={[
            <Button type="primary" key="cancel" onClick={this.handleCancelTips}>
              {okText}
            </Button>
          ]}>
          <p>
            {i18nUtil.formatMessage(
              { id: "wallet.deposit.modal.content" },
              {
                currency: this.props.router?.query?.currency
              }
            )}
          </p>
        </Modal>
      </section>
    );
  }
}

export const PageDeposit = WithKYCInfo(withRouter(Deposit));
