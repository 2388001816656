import React from "react";
import styles from "@aspen/widgets/login/Login.module.less";
import { LoginLayoutPortal, RegisterSubAccount } from "@aspen/widgets";
import { LOGIN_REDIRECT, REDIRECT_ID } from "@aspen/libs";

class RegisterMulti extends React.Component {
  componentDidMount(): void {
    // 防止主测子账号直接登录后跳转之前账号保存的路由
    localStorage.removeItem(LOGIN_REDIRECT);
    localStorage.removeItem(REDIRECT_ID);
  }

  render(): React.ReactNode {
    return (
      <div className={styles.form}>
        <RegisterSubAccount />
      </div>
    );
  }
}

export const PageRegisterMulti = LoginLayoutPortal(RegisterMulti);
