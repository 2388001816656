import React, { useEffect, useState } from "react";
import { Button, Col, Row, Spin, Tabs } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { useRouter } from "next/router";
import clsx from "clsx";
import styles from "@aspen/theme/Intelligence.module.less";
import type { IIntelligenceIResponse, INews, IReqIntelligenceNews } from "@aspen/model";
import { NEWSTYPE } from "@aspen/model";
import { fecthIntelligenceNews } from "@aspen/services";
import { GA_EVENT_NAME, i18nUtil, INTELLIGENCE_PATHS, reportEvent } from "@aspen/libs";
import { AnnouncementList, MarketDailyItem } from "@aspen/widgets";

interface IProps {
  marketDailyData: IIntelligenceIResponse;
  noticeData: IIntelligenceIResponse;
}

const MarketDaily: React.FC<IProps> = ({ marketDailyData, noticeData }) => {
  const intl = i18nUtil.t();
  const marketDaily = intl["intelligence.market.daily"];
  const weeklyReport = intl["intelligence.weekly.report"];

  const router = useRouter();
  const defaultType = router?.query?.type?.toString() ?? "daily";

  const [marketDailyList, setMarketDailyList] = useState<INews[]>([]);
  const [nextToken, setNextToken] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>(defaultType);

  useEffect(() => {
    if (defaultType === "weekly") {
      getMarketDailyList({ type: "weekly" });
    } else {
      setNextToken(marketDailyData?.nextToken);
      setMarketDailyList(marketDailyData?.rows);
    }
  }, [defaultType]);

  // 获取简报/周报数据
  const getMarketDailyList: (params: { type?: string; next?: string }) => void = ({
    type = currentTab,
    next = ""
  }) => {
    setLoading(true);
    const params: IReqIntelligenceNews = {
      limit: 20,
      action: "all",
      type: type === "daily" ? NEWSTYPE.daily : NEWSTYPE.weeklyReport
    };
    if (next) {
      params.nextToken = next;
    }
    fecthIntelligenceNews(params)
      .then((res) => {
        setMarketDailyList(
          next ? marketDailyList.concat(res?.data?.rows ?? []) : (res?.data?.rows ?? [])
        );
        setNextToken(res?.data?.nextToken);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleLoadMore = () => {
    getMarketDailyList({ next: nextToken });
    reportEvent({
      moduleName: GA_EVENT_NAME.intelligence.briefing.loadMore,
      detailParams: { currentTab }
    });
  };

  const tabItems = [
    {
      label: marketDaily,
      key: "daily"
    },
    {
      label: weeklyReport,
      key: "weekly"
    }
  ];

  const handleChangeTab = (activeKey: string) => {
    reportEvent({
      moduleName: GA_EVENT_NAME.intelligence.briefing.switchTab,
      detailParams: { currentTab: activeKey }
    });
    // 切换tab时 去除路由传递的type
    if (defaultType === "weekly") {
      router.replace({ query: null });
    }
    setCurrentTab(activeKey);
    setMarketDailyList([]);
    setNextToken("");
    getMarketDailyList({ type: activeKey });
  };

  return (
    <section className={clsx(styles.intelligence, styles.marketDaily)}>
      <Row className={styles.row}>
        <Col flex="auto" style={{ marginRight: 16 }}>
          <div className={styles.marketDailyContainer}>
            <Tabs
              className={styles.tab}
              activeKey={currentTab}
              items={tabItems}
              onChange={handleChangeTab}
            />
            <Spin spinning={loading}>
              <div className={styles.articleList}>
                {marketDailyList?.length > 0
                  ? marketDailyList?.map((item) => (
                      <MarketDailyItem
                        key={item.id}
                        data={item}
                        path={INTELLIGENCE_PATHS.INTELLIGENCE_MARKET_DAILY_DETAIL}
                      />
                    ))
                  : loading || <p className={styles.emptyText}>{intl?.["empty.list"]}</p>}
                {/* 分页 */}
                {!loading &&
                  marketDailyList?.length > 0 &&
                  (nextToken && marketDailyList.length >= 20 ? (
                    <div className={styles.paginationMask}>
                      <Button
                        className={styles.loadMore}
                        icon={<SyncOutlined />}
                        onClick={handleLoadMore}>
                        {intl["intelligence.loadMore"]}
                      </Button>
                    </div>
                  ) : (
                    <>
                      <p className={styles.noMoreData}>{intl["noMoreData"]}</p>
                      {/* 解决分页的占位问题 */}
                      <div style={{ height: 230 }} />
                    </>
                  ))}
              </div>
            </Spin>
          </div>
        </Col>
        {/* Announcement List */}
        <Col flex="340px">
          <AnnouncementList initData={noticeData} />
        </Col>
      </Row>
    </section>
  );
};

export const PageBriefing = React.memo(MarketDaily);
