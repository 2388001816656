import React, { useEffect, useState } from "react";
import Link from "next/link";
import { Breadcrumb, Steps } from "antd";
import Image from "next/image";
import { useRouter } from "next/router";
import { assign } from "lodash-es";
import styles from "@aspen/theme/Profile.module.less";
import {
  IkycInfo,
  IReqKycIndividuals,
  IReqKycInstitutions,
  IQuestionnaireItem,
  KYC_RISK_LEVEL,
  KYC_TYPE_QUERY
} from "@aspen/model";
import {
  PROFILE_PATHS,
  KYC_DATA_CACHE,
  GA_EVENT_NAME,
  i18nUtil,
  reportEvent,
  md5Encrypted
} from "@aspen/libs";
import { submitKyc, getKycRiskLevel } from "@aspen/services";
import { message, WithKYCInfo } from "@aspen/ui";
import {
  InstitutionsBasic,
  IndividualsBasic,
  Questionnaire,
  IndividualsUploadFiles,
  InstitutionsUploadFiles,
  ModalResult,
  OnfidoComp
} from "@aspen/widgets";
import countriesRiskScores from "@aspen/assets/offlineData/countriesRiskScores.json";
import tips_red from "@aspen/assets/images/tips_red.svg";

interface IProps {
  kycInfo: IkycInfo;
}

const Verification: React.FC<IProps> = (props) => {
  const { kycInfo } = props;
  const router = useRouter();
  const kycType = router?.query?.type;
  const isIndividual = kycType === "individuals";
  const intl = i18nUtil.t();

  const [loading, setLoading] = useState<boolean>(false);
  // 问卷数据
  const [individualsQuestionnaire, setIndividualsQuestionnaire] = useState<IQuestionnaireItem[]>();
  const [institutionsQuestionnaire, setInstitutionsQuestionnaire] =
    useState<IQuestionnaireItem[]>();

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [showOnfido, setShowOnfido] = useState<boolean>(false);
  const [surveyScore, setSurveyScore] = useState<number>(0);
  const [showResultModal, setShowResultModal] = useState<boolean>(false);

  const [submitInfo, setsubmitInfo] = useState<IReqKycIndividuals | IReqKycInstitutions>({});
  const [riskLevel, setRiskLevel] = useState<string>(KYC_RISK_LEVEL.low);

  useEffect(() => {
    formatQuestionnaireData();
    // 产品要求仅用于再kyc填写过程中缓存，退出后不再缓存数据，仅回显被拒绝后的数据
    const type = isIndividual ? KYC_TYPE_QUERY.individuals : KYC_TYPE_QUERY.institutions;
    kycInfo &&
      !localStorage.getItem(KYC_DATA_CACHE) &&
      kycInfo.type == type &&
      localStorage.setItem(KYC_DATA_CACHE, JSON.stringify(kycInfo));
    return () => {
      localStorage.setItem(KYC_DATA_CACHE, "");
    };
  }, []);

  // format问卷数据结构
  const formatQuestionnaireData = () => {
    const questionnaireIndividual = intl["kyc.individual.questionnaire"];
    const questionnaireInstitutions = intl["kyc.institutions.questionnaire"];
    const questionnaireCommon = intl["kyc.common.questionnaire"];
    setIndividualsQuestionnaire([...questionnaireIndividual, ...questionnaireCommon]);
    setInstitutionsQuestionnaire([...questionnaireInstitutions, ...questionnaireCommon]);
  };

  // 缓存数据
  const handleCacheInfo = (params: IReqKycIndividuals | IReqKycInstitutions) => {
    let values = { ...params };
    if (localStorage.getItem(KYC_DATA_CACHE)) {
      const dataCache = JSON.parse(localStorage.getItem(KYC_DATA_CACHE) ?? "");
      localStorage.setItem(KYC_DATA_CACHE, JSON.stringify(assign(dataCache, values)));
    } else {
      localStorage.setItem(KYC_DATA_CACHE, JSON.stringify(values));
    }
  };

  // 下一步
  const handleNextStep = (values: IReqKycIndividuals | IReqKycInstitutions, scores?: number) => {
    handleCacheInfo(values);
    setsubmitInfo(assign(submitInfo, values));
    if (isIndividual) {
      scores !== undefined && setSurveyScore(scores);
      setShowOnfido(true);
      reportEvent({
        moduleName: GA_EVENT_NAME.kyc.submitIndSur
      });
    } else {
      setCurrentStep(currentStep + 1);
      const gaName =
        currentStep === 0 ? GA_EVENT_NAME.kyc.submitInsSur : GA_EVENT_NAME.kyc.submitInsDoc;
      reportEvent({
        moduleName: gaName
      });
    }
  };
  // 返回上一步
  const goback = () => {
    const count = isIndividual ? 2 : 1;
    setCurrentStep(currentStep - count);
  };

  // 获取风险等级 目前只有个人计算风险分数
  const handleGetSurveyScore = (countryCode: string) => {
    // 目前不取onfido识别的countryCode
    console.info(countryCode);
    setLoading(true);
    const params = { ...submitInfo };
    const nationality = params.nationality ?? "";
    const currentCountryScore = countriesRiskScores[nationality] ?? 20; // 国家风险分数不存在时默认为高风险20分
    const scoresSum = surveyScore + currentCountryScore;
    params.surveyScore = scoresSum;
    setsubmitInfo(params);
    params.sign = md5Encrypted(JSON.stringify(sortObj(params))).toUpperCase();

    reportEvent({
      moduleName: GA_EVENT_NAME.kyc.submitIndDoc
    });

    getKycRiskLevel(params)
      .then((res) => {
        if (res?.code == "0") {
          setRiskLevel(res.data);
          setCurrentStep(currentStep + 2);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // 按照key首字母排序对象
  const sortObj = (params: IReqKycIndividuals | IReqKycInstitutions) => {
    const obj = { ...params };
    // 如果包含phoneAreaCode字段，加签时需要去掉这个字段，因为后端不存,并删除之前的sign字段
    if (obj.phoneAreaCode) {
      delete obj.phoneAreaCode;
    }
    if (obj.sign) {
      delete obj.sign;
    }
    const sortedKeys = Object.keys(obj).sort((a, b) => a.localeCompare(b));
    const sortedObj = {};
    for (const key of sortedKeys) {
      sortedObj[key] = obj[key];
    }
    return sortedObj;
  };

  // 上传文件数据
  const getUploadInfo = (values: Array<string>) => {
    const info = { ...submitInfo };
    info.fileUrl = values;
    setsubmitInfo(assign(submitInfo, info));
  };

  // 提交
  const handleSubmit = () => {
    setLoading(true);
    const otherParams = {
      status: 4, // 提交后 status 为4 验证中 所以此处固定传 4
      type: isIndividual ? KYC_TYPE_QUERY.individuals : KYC_TYPE_QUERY.institutions
    };
    const params = { ...submitInfo, ...otherParams };
    // 合并phone传给后端 个人onfido不需要传
    if (!isIndividual) {
      params.phone = `${params.phoneAreaCode} ${params.phone}`;
    }
    params.sign = md5Encrypted(JSON.stringify(sortObj(params))).toUpperCase();

    const gaName = isIndividual ? GA_EVENT_NAME.kyc.submitRiskInd : GA_EVENT_NAME.kyc.submitRiskIns;
    reportEvent({
      moduleName: gaName
    });

    submitKyc(params)
      .then((res) => {
        if (res?.code == "0") {
          if (isIndividual) {
            setShowResultModal(true);
          } else {
            message.success(intl["profile.kyc.successMessage"]);
            router.replace(PROFILE_PATHS.PROFILE_KYC_RESULT);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // 表单
  const renderFormView = () => {
    switch (currentStep) {
      case 0:
        return (
          individualsQuestionnaire &&
          institutionsQuestionnaire && (
            <Questionnaire
              loading={loading}
              kycInfo={kycInfo}
              questionnaireArray={
                isIndividual ? individualsQuestionnaire : institutionsQuestionnaire
              }
              handleNextStep={handleNextStep}
              isIndividual={isIndividual}
              handleCacheInfo={handleCacheInfo}
            />
          )
        );
      case 1:
        if (isIndividual) {
          return (
            <IndividualsBasic
              handleGoBack={goback}
              handleNextStep={handleNextStep}
              handleCacheInfo={handleCacheInfo}
            />
          );
        } else {
          return (
            <InstitutionsBasic
              handleGoBack={goback}
              handleNextStep={handleNextStep}
              handleCacheInfo={handleCacheInfo}
            />
          );
        }
      default:
        return isIndividual ? (
          <IndividualsUploadFiles
            loading={loading}
            handleSubmit={handleSubmit}
            handleGoBack={goback}
            handleCacheInfo={handleCacheInfo}
            riskLevel={riskLevel}
            getUploadInfo={getUploadInfo}
          />
        ) : (
          <InstitutionsUploadFiles
            loading={loading}
            handleSubmit={handleSubmit}
            handleGoBack={goback}
            handleCacheInfo={handleCacheInfo}
            getUploadInfo={getUploadInfo}
          />
        );
    }
  };

  return (
    <div className={styles.kycFormContainer}>
      <div className={styles.titleContainer}>
        <div className={styles.topContainer}>
          <Breadcrumb>
            <Breadcrumb.Item>{intl["kyc.certify.location"]}:</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link href={PROFILE_PATHS.PROFILE}>{intl["profile"]}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {isIndividual
                ? intl["kyc.form.title.individual"]
                : intl["kyc.form.title.institutions"]}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className={styles.titleContent}>
            <span className={styles.titleText}>
              {isIndividual
                ? intl["kyc.form.title.individual"]
                : intl["kyc.form.title.institutions"]}
            </span>
          </div>
          <p className={styles.subitleText}>{intl["kyc.form.title.sub"]}</p>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.tipsContent}>
          {kycInfo?.remark && (
            <div className={styles.rejectTips}>
              <Image unoptimized alt="" width={15} height={15} src={tips_red} />
              <span className={styles.rejectTipText}>{kycInfo?.remark}</span>
            </div>
          )}
        </div>
        <div className={styles.stepContainer}>
          <Steps
            size="small"
            current={currentStep}
            labelPlacement="vertical"
            items={[
              {
                title: intl["kyc.form.step.title1"]
              },
              {
                title: intl["kyc.form.step.title2"]
              },
              {
                title: isIndividual
                  ? intl["kyc.form.step.title.risk"]
                  : intl["kyc.form.step.title.upload"]
              }
            ]}
          />
        </div>
        <div className={styles.formContainer}>{renderFormView()}</div>
      </div>
      {showOnfido && (
        <OnfidoComp
          onModalRequestClose={() => setShowOnfido(false)}
          onComplete={(country: string) => {
            setShowOnfido(false);
            handleGetSurveyScore(country);
          }}
        />
      )}
      {showResultModal && (
        <ModalResult
          open={showResultModal}
          resultType="success"
          textAlign="center"
          title={intl["kyc.form.individual.success.title"]}
          text={intl["kyc.form.individual.success.content"]}
          onOk={() => {
            router.replace(PROFILE_PATHS.PROFILE);
          }}
          onCancel={() => {
            router.replace(PROFILE_PATHS.PROFILE);
          }}
        />
      )}
    </div>
  );
};

export const PageKycVerification = WithKYCInfo(Verification);
