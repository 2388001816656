import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { useRouter } from "next/router";
import Image from "next/image";
import clsx from "clsx";
import styles from "@aspen/theme/Intelligence.module.less";
import { message } from "@aspen/ui";
import {
  fecthExpertAuthorDetail,
  fecthExpertAuthorList,
  fecthIntelligenceNews,
  followExpert
} from "@aspen/services";
import {
  GA_EVENT_NAME,
  getQueryValue,
  i18nUtil,
  INTELLIGENCE_PATHS,
  reportEvent,
  transformTime
} from "@aspen/libs";
import type {
  IExpertAuthor,
  INews,
  IReqExpertAuthorList,
  IReqIntelligenceNews
} from "@aspen/model";
import { NEWSTYPE } from "@aspen/model";
import { ExpertItem, NewsCard } from "@aspen/widgets";

import defaultAvatar from "@aspen/assets/images/avatar.png";

interface IProps {
  detailData: IExpertAuthor;
}

const AuthorDetail: React.FC<IProps> = (props: IProps) => {
  const [detailContent, setDetailContent] = useState<IExpertAuthor | null>(props.detailData);
  const [loading, setLoading] = useState<boolean>(false);
  // 专栏文章列表
  const [expertList, setExpertList] = useState<INews[]>([]);
  const [nextToken, setNextToken] = useState<string>("");
  const [expertLoading, setExpertLoading] = useState<boolean>(false);
  // 其它专栏作家
  const [otherExperts, setOtherExperts] = useState<IExpertAuthor[]>([]);
  const [otherExpertsLoading, setOtherExpertsLoading] = useState<boolean>(false);

  const [showMore, setShowMore] = useState<boolean | null>(null);
  const [readMore, setReadMore] = useState<boolean>(false);

  const router = useRouter();
  const id = router?.query?.id?.toString() ?? getQueryValue("id") ?? "";

  const intl = i18nUtil.t();
  const followText = intl["intelligence.expert.follow"];
  const followingText = intl["intelligence.expert.following"];
  const aboutAuthorText = intl["intelligence.expert.detail.about.the.author"];
  const readMoreText = intl["intelligence.expert.detail.read.more"];
  const closeMoreText = intl["intelligence.expert.detail.close.more"];
  const expertViews = intl["intelligence.expert.article"];
  const otherExpertsText = intl["intelligence.expert.detail.other.experts"];
  const moreText = intl["link.more"];

  const wrapTextRef = useRef<any>(null);
  const introRef = useRef<any>(null);

  const asideRef = useRef<any>(null);
  useEffect(() => {
    if (!asideRef.current) return;
    const height = asideRef.current?.clientHeight;
    document
      .getElementById("recommedExpert")
      ?.style.setProperty("--author-detail-aside-height", `${height}px`);
  }, [asideRef.current?.clientHeight]);

  // 处理简介部分的折叠和展示效果
  useEffect(() => {
    if (!wrapTextRef.current || !introRef.current) return;

    const isShowMore =
      introRef.current?.getBoundingClientRect().height >
      wrapTextRef.current?.getBoundingClientRect().height;

    setShowMore(isShowMore ? true : null);
  }, [wrapTextRef.current, introRef.current]);

  useEffect(() => {
    if (props.detailData) setDetailContent(props.detailData);
  }, [props.detailData]);

  useEffect(() => {
    // 获取专栏文章列表
    getExpertList();
    // 获取其它专栏作家
    getOthersExpertAuthor();
  }, []);

  const getDetailById = () => {
    if (id) {
      setLoading(true);
      fecthExpertAuthorDetail({ id })
        .then((res) => {
          setDetailContent(res?.data || null);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      message.error(intl["router.query.id.incorrect.tip"]);
    }
  };

  // 获取专栏文章列表
  const getExpertList = () => {
    setExpertLoading(true);
    const params: IReqIntelligenceNews = {
      limit: 10,
      action: "all",
      type: NEWSTYPE.expert,
      kolIds: detailContent?.id,
      nextToken
    };
    fecthIntelligenceNews(params)
      .then((res) => {
        if (res?.code == "0") {
          setNextToken(res?.data?.nextToken);
          if (res?.data?.rows?.length > 0) {
            setExpertList(nextToken ? expertList.concat(res?.data?.rows) : (res?.data?.rows ?? []));
          }
        }
      })
      .finally(() => {
        setExpertLoading(false);
      });
  };

  // 获取其他专栏作家 （精选的）
  const getOthersExpertAuthor = () => {
    setOtherExpertsLoading(true);
    const params: IReqExpertAuthorList = {
      limit: 6,
      expertsType: "CHOSEN"
    };
    fecthExpertAuthorList(params)
      .then((res) => {
        if (res?.code == "0") {
          setOtherExperts(res?.data?.rows ?? []);
        }
      })
      .finally(() => {
        setOtherExpertsLoading(false);
      });
  };

  // 关注
  const handleFollowClick = () => {
    followExpert({ action: "follow", followId: detailContent?.id ?? "" }).then((res) => {
      if (res?.code == "0") {
        getDetailById();
      } else {
        message.error(intl?.[res?.msg] ?? res?.msg);
      }
    });
    reportEvent({
      moduleName: GA_EVENT_NAME.intelligence.expert.follow,
      detailParams: { id: detailContent?.id ?? "" }
    });
  };

  // 取消关注
  const handleUnfollowClick = () => {
    followExpert({ action: "unfollow", followId: detailContent?.id ?? "" }).then((res) => {
      if (res?.code == "0") {
        getDetailById();
      } else {
        message.error(intl?.[res?.msg] ?? res?.msg);
      }
    });
    reportEvent({
      moduleName: GA_EVENT_NAME.intelligence.expert.unfollow,
      detailParams: { id: detailContent?.id ?? "" }
    });
  };

  // 查看文章详情
  const handleClickArticle = (id) => {
    router.push(`${INTELLIGENCE_PATHS.INTELLIGENCE_EXPERT_DETAIL}/${id}`);
  };

  const handleLoadMore = () => {
    getExpertList();
    reportEvent({ moduleName: GA_EVENT_NAME.intelligence.expert.loadMore });
  };

  // 展开更多
  const handleClickReadMore = () => {
    setReadMore(true);
    setShowMore(false);
    reportEvent({
      moduleName: GA_EVENT_NAME.intelligence.expert.readMore
    });
  };
  // 收起
  const handleClickCloseMore = () => {
    setReadMore(false);
    setShowMore(true);
    reportEvent({
      moduleName: GA_EVENT_NAME.intelligence.expert.close
    });
  };

  return (
    <section className={`${styles.expertAuthorDetail} ` + `customer`}>
      {detailContent ? (
        <>
          <div
            className={clsx(styles.topBgBox, "customerTop")}
            style={{
              backgroundImage: `url(${detailContent?.backGroundImage ?? ""})`
            }}
          />
          <Row className={styles.titleContent}>
            <Col span={24}>
              <div className={styles.topUserInfo}>
                <Image
                  alt=""
                  className={styles.avatar}
                  src={detailContent?.avatar || defaultAvatar}
                  width={134}
                  height={134}
                />
                <p className={styles.author}>{detailContent?.name}</p>
                <div className={styles.authorType}>
                  {detailContent?.keyWords?.length
                    ? detailContent?.keyWords?.map(
                        (key, index) =>
                          index < 2 && (
                            <div key={index} className={styles.type}>
                              {key?.length > 15 ? `${key?.slice(0, 15)}...` : key}
                            </div>
                          )
                      )
                    : null}
                </div>
                {detailContent?.follow ? (
                  <Button
                    type="primary"
                    loading={loading}
                    className={styles.buttonFollowing}
                    onClick={handleUnfollowClick}>
                    {followingText}
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    loading={loading}
                    className={styles.buttonFollow}
                    onClick={handleFollowClick}>
                    {followText}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
          {detailContent?.intro ? (
            <div className={styles.detailContent}>
              <div className={styles.authorIntro}>
                <p className={styles.title}>{aboutAuthorText}</p>
                <div className={styles.wrapText}>
                  <p
                    className={clsx(styles.authorSummary, readMore ? styles.showAll : "")}
                    ref={wrapTextRef}>
                    {/* 展开更多 */}
                    {showMore ? (
                      <a className={styles.readMore} onClick={handleClickReadMore}>
                        {readMoreText}
                      </a>
                    ) : null}
                    {/* 简介内容 */}
                    <span ref={introRef}>{detailContent?.intro}</span>
                    {/* 收起 */}
                    {showMore !== null && !showMore && (
                      <a className={styles.readMore} onClick={handleClickCloseMore}>
                        {closeMoreText}
                      </a>
                    )}
                  </p>
                </div>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
      <Row className={styles.row} style={{ width: "100%" }}>
        <Col flex="auto" id="recommedExpert" style={{ width: "calc(100% - 340px)" }}>
          <NewsCard
            className={styles.expertViews}
            loading={expertLoading}
            showLink={false}
            title={expertViews}>
            <div className={styles.articles}>
              {expertList?.length > 0
                ? expertList?.map((item) => (
                    <div key={item.id} className={styles.articleItem}>
                      <div className={styles.description}>
                        <div className={styles.articleType}>
                          {item?.keyWords?.length > 0
                            ? item?.keyWords?.map(
                                (key, index) =>
                                  index < 5 && (
                                    <div key={index} className={styles.type}>
                                      {key?.length > 15 ? `${key?.slice(0, 15)}...` : key}
                                    </div>
                                  )
                              )
                            : null}
                        </div>
                        <span className={styles.date}>{transformTime(+item?.newsTime)}</span>
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => handleClickArticle(item?.id)}>
                        <p className={styles.articleTitle}>{item?.title}</p>
                        <p className={styles.articleSummary}>{item?.summary}</p>
                      </div>
                      <div className={styles.divider} />
                    </div>
                  ))
                : expertLoading || <p className={styles.emptyText}>{intl?.["empty.list"]}</p>}
              {/* 分页 */}
              {!expertLoading &&
                expertList?.length > 0 &&
                (nextToken && expertList?.length >= 10 ? (
                  <div className={styles.paginationMask}>
                    <Button
                      className={styles.loadMore}
                      icon={<SyncOutlined />}
                      onClick={handleLoadMore}>
                      {intl["intelligence.loadMore"]}
                    </Button>
                  </div>
                ) : (
                  <>
                    <p className={styles.noMoreData}>{intl["noMoreData"]}</p>
                    {/* 解决分页的占位问题 */}
                    <div style={{ height: 200 }} />
                  </>
                ))}
            </div>
          </NewsCard>
        </Col>
        <Col flex="340px" ref={asideRef} className={styles.authorDetailExtraContainer}>
          <NewsCard
            className={styles.authorOtherExpert}
            loading={otherExpertsLoading}
            title={otherExpertsText}
            linkText={moreText}
            link={INTELLIGENCE_PATHS.INTELLIGENCE_EXPERT_LIST}>
            {otherExperts
              .filter((item) => item?.id !== detailContent?.id)
              ?.map((item) => (
                <ExpertItem key={item.id} data={item} showActions={false} showArticle={false} />
              ))}
          </NewsCard>
        </Col>
      </Row>
    </section>
  );
};

export const PageExpertAuthorDetail = React.memo(AuthorDetail);
