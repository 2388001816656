import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { Breadcrumb } from "antd";
import styles from "@aspen/theme/Profile.module.less";
import {
  IIndividualsForm,
  IInstitutionsForm,
  IkycInfo,
  KYC_INSTITUTIONS_KEYS_LIST,
  KYC_INDIVIDUALS_KEYS_LIST,
  KYC_STATUS,
  KYC_INSTITUTIONS_LABEL_TEXT,
  KYC_INDIVIDUALS_LABEL_TEXT,
  KYC_TYPE_QUERY
} from "@aspen/model";
import { countryCode2Info, i18nUtil, PROFILE_PATHS } from "@aspen/libs";
import { WithKYCInfo } from "@aspen/ui";
import ic_get from "@aspen/assets/images/ic_get@2x.png";
import ic_review from "@aspen/assets/images/underview.svg";
import ic_reject from "@aspen/assets/images/reject.svg";

interface IProps {
  kycInfo: IkycInfo;
  getKYCInfo: () => void;
}

const Result: React.FC<IProps> = (props) => {
  const { kycInfo } = props;
  const intl = i18nUtil.t();
  const individualsInitData = {
    name: {
      label: intl[KYC_INDIVIDUALS_LABEL_TEXT.name],
      value: ""
    },
    age: {
      label: intl[KYC_INDIVIDUALS_LABEL_TEXT.age],
      value: ""
    },
    gender: {
      label: intl[KYC_INDIVIDUALS_LABEL_TEXT.gender],
      value: ""
    },
    birthday: {
      label: intl[KYC_INDIVIDUALS_LABEL_TEXT.birthday],
      value: ""
    },
    nationality: {
      label: intl[KYC_INDIVIDUALS_LABEL_TEXT.nationality],
      value: ""
    },
    passportId: {
      label: intl[KYC_INDIVIDUALS_LABEL_TEXT.passportId],
      value: ""
    },
    phone: {
      label: intl[KYC_INDIVIDUALS_LABEL_TEXT.phone],
      value: ""
    },
    email: {
      label: intl[KYC_INDIVIDUALS_LABEL_TEXT.email],
      value: ""
    },
    contactAddress: {
      label: intl[KYC_INDIVIDUALS_LABEL_TEXT.contactAddress],
      value: ""
    },
    annualIncome: {
      label: intl[KYC_INDIVIDUALS_LABEL_TEXT.annualIncome],
      value: ""
    },
    sourceOfIncome: {
      label: intl[KYC_INDIVIDUALS_LABEL_TEXT.sourceOfIncome],
      value: ""
    },
    sourceOfFund: {
      label: intl[KYC_INDIVIDUALS_LABEL_TEXT.sourceOfFund],
      value: ""
    },
    liquidAssets: {
      label: intl[KYC_INDIVIDUALS_LABEL_TEXT.liquidAssets],
      value: ""
    },
    totalAssets: {
      label: intl[KYC_INDIVIDUALS_LABEL_TEXT.totalAssets],
      value: ""
    }
  };
  const institutionsInitData = {
    name: {
      label: intl[KYC_INSTITUTIONS_LABEL_TEXT.name],
      value: ""
    },
    businessNature: {
      label: intl[KYC_INSTITUTIONS_LABEL_TEXT.businessNature],
      value: ""
    },
    brNumber: {
      label: intl[KYC_INSTITUTIONS_LABEL_TEXT.brNumber],
      value: ""
    },
    companyRegistrationCountry: {
      label: intl[KYC_INSTITUTIONS_LABEL_TEXT.companyRegistrationCountry],
      value: ""
    },
    contactAddress: {
      label: intl[KYC_INSTITUTIONS_LABEL_TEXT.contactAddress],
      value: ""
    },
    companyType: {
      label: intl[KYC_INSTITUTIONS_LABEL_TEXT.companyType],
      value: ""
    },
    representativeName: {
      label: intl[KYC_INSTITUTIONS_LABEL_TEXT.representativeName],
      value: ""
    },
    representativeTitle: {
      label: intl[KYC_INSTITUTIONS_LABEL_TEXT.representativeTitle],
      value: ""
    },
    phone: {
      label: intl[KYC_INSTITUTIONS_LABEL_TEXT.phone],
      value: ""
    },
    email: {
      label: intl[KYC_INSTITUTIONS_LABEL_TEXT.email],
      value: ""
    },
    companyRepresentativeNationality: {
      label: intl[KYC_INSTITUTIONS_LABEL_TEXT.companyRepresentativeNationality],
      value: ""
    },
    companyRepresentativeGender: {
      label: intl[KYC_INSTITUTIONS_LABEL_TEXT.companyRepresentativeGender],
      value: ""
    },
    companyRepresentativeBirthDate: {
      label: intl[KYC_INSTITUTIONS_LABEL_TEXT.companyRepresentativeBirthDate],
      value: ""
    },
    sourceOfFund: {
      label: intl[KYC_INSTITUTIONS_LABEL_TEXT.sourceOfFund],
      value: ""
    },
    liquidAssets: {
      label: intl[KYC_INSTITUTIONS_LABEL_TEXT.liquidAssets],
      value: ""
    },
    totalAssets: {
      label: intl[KYC_INSTITUTIONS_LABEL_TEXT.totalAssets],
      value: ""
    }
  };
  const [individualsData, setIndividualsData] = useState<IIndividualsForm>(individualsInitData);
  const [institutionsData, setInstitutionsData] = useState<IInstitutionsForm>(institutionsInitData);

  const mapArray =
    kycInfo.type === KYC_TYPE_QUERY.institutions
      ? KYC_INSTITUTIONS_KEYS_LIST
      : KYC_INDIVIDUALS_KEYS_LIST;
  const tableData =
    kycInfo.type === KYC_TYPE_QUERY.institutions ? institutionsData : individualsData;
  const statusText = KYC_STATUS[kycInfo.status ?? 1];
  const icon_status =
    statusText === "Auditing" ? ic_review : statusText === "Rejected" ? ic_reject : ic_get;
  const text_status =
    statusText === "Auditing"
      ? intl["kyc.status.under.review"]
      : statusText === "Rejected"
        ? intl["kyc.rejected.text"]
        : intl["kyc.certified.text"];
  useEffect(() => {
    props.getKYCInfo();
    formatTableData();
  }, []);

  const formatTableData = () => {
    const questionnaireIndividual = intl["kyc.individual.questionnaire"];
    const questionnaireInstitutions = intl["kyc.institutions.questionnaire"];
    const questionnaireCommon = intl["kyc.common.questionnaire"];
    setIndividualsData({
      ...individualsData,
      ...fomatQuestionnaire(questionnaireIndividual),
      ...fomatQuestionnaire(questionnaireCommon)
    });
    setInstitutionsData({
      ...institutionsData,
      ...fomatQuestionnaire(questionnaireInstitutions),
      ...fomatQuestionnaire(questionnaireCommon)
    });
  };

  // 重组问卷数据
  const fomatQuestionnaire = (arr: { name: string; label: string }[]) => {
    let obj: { [key: string]: { label: string; value: string } } = {};
    arr.forEach((item) => {
      obj[item.name] = {
        label: item.label,
        value: ""
      };
    });
    return obj;
  };

  // 映射不同情况下国家、国籍
  const handleFormatValue = (lable: string) => {
    switch (lable) {
      case "nationality":
        return countryCode2Info()[kycInfo.nationality ?? ""]?.nationality;
      case "companyRegistrationCountry":
        return countryCode2Info()[kycInfo.companyRegistrationCountry ?? ""]?.commonName;
      case "companyRepresentativeNationality":
        return countryCode2Info()[kycInfo.companyRepresentativeNationality ?? ""]?.nationality;
      default:
        return kycInfo[lable]?.toString();
    }
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>{intl["kyc.certify.location"]}:</Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link href={PROFILE_PATHS.PROFILE}>{intl["profile"]}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{intl["kyc.information.title"]}</Breadcrumb.Item>
      </Breadcrumb>
      <div className={styles.kycResult}>
        <p className={styles.title}>{intl["kyc.information.title"]}</p>
        <div className={styles.kycStatus}>
          <span style={{ marginRight: 5 }}>{intl["kyc.status.under.status"]}:</span>
          <Image unoptimized src={icon_status} alt="" width={16} height={16} />
          {kycInfo?.status && (
            <span
              className={styles[KYC_STATUS[kycInfo.status]?.toLowerCase()]}
              style={{ marginLeft: 5 }}>
              {text_status}
            </span>
          )}
        </div>
        <div className={styles.kycTable}>
          {mapArray.map(
            (item: string) =>
              kycInfo[item] !== "" &&
              kycInfo[item] !== undefined && (
                <div className={styles.item} key={item}>
                  <div className={styles.inner}>
                    <p className={styles.label}>{tableData[item]?.label}</p>
                    <p className={styles.value}>{handleFormatValue(item)}</p>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </>
  );
};

export const PageKycResult = WithKYCInfo(Result);
