import React, { useEffect, useState } from "react";
import {
  LoginLayoutPortal,
  WithAliCloudNC,
  RegisterStep1,
  RegisterStep2,
  RegisterStep3,
  NVCCaptcha
} from "@aspen/widgets";
import styles from "@aspen/widgets/login/Login.module.less";
import {
  clearLocalStorage,
  clearCookies,
  i18nUtil,
  reportEvent,
  AGREEMENT_PATH,
  GA_EVENT_NAME,
  IS2FA,
  LOGIN_PATHS,
  OPEN_BIND_PHONE,
  POLICY_PATH,
  SHOW_RECAPTCHA,
  md5Encrypted,
  MD5_FIXED_STRING
} from "@aspen/libs";
import type { IInjectProps, ILoading, IPropsStep } from "@aspen/model";
import { ACCOUNT_TYPE } from "@aspen/model";
import { useRouter, withRouter } from "next/router";
import { Form } from "antd";
import { message } from "@aspen/ui";
import { login, register } from "@aspen/services";

type ILoginParams = {
  accountEmail: string;
  password: string;
  data?: any;
  type?: string;
};

interface IProps extends IInjectProps {
  updateStatus: (status: ILoading) => void;
  ncVal: Record<string, any>;
  setNcVal: (ncVal: Record<string, any>) => void;
  resetNC: () => void;
  initNC: () => void;
}

const Register: React.FC<IProps> = (props) => {
  // 滑动验证参数
  const { ncVal } = props;
  const [step, setStep] = useState<IPropsStep>(1);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [jwtToken, setJwtToken] = useState<string>("");

  const [form] = Form.useForm();
  const intl = i18nUtil.t();
  const router = useRouter();

  useEffect(() => {
    clearLocalStorage();
    clearCookies();
    // this.props.updateStatus("success");
    props.router.prefetch(AGREEMENT_PATH);
    props.router.prefetch(POLICY_PATH);
  }, []);

  const fetchLogin = (loginParams: ILoginParams) => {
    const { accountEmail, password, data, type } = loginParams;
    const params = {
      accountName: accountEmail,
      password,
      accountType: ACCOUNT_TYPE.EMAIL
    };
    const headers = {};
    const showRecaptcha = localStorage.getItem(SHOW_RECAPTCHA) !== "false";

    // 滑动验证参数
    if (showRecaptcha) {
      if (type === "nc") {
        headers["SG-Verification-Type"] = 1; // 1表示滑动验证
        headers["SG-Verification-Session-Id"] = data.sessionId;
        headers["SG-Verification-Sig"] = data.sig;
        headers["SG-Verification-Token"] = data.token;
        headers["SG-Verification-Scene"] = "nc-login-h5";
      } else {
        headers["SG-Verification-Type"] = 3; // 3表示无痕验证
        headers["SG-Verification-Data"] = data;
      }
    }

    // 2FA验证参数
    const is2fa = localStorage.getItem(IS2FA) !== "false";
    if (is2fa) {
      headers["is2fa"] = is2fa;
    }
    login(params, headers)
      .then(async (res) => {
        if (res?.code == "0") {
          setJwtToken(res?.data?.jwtToken);
          setStep(3);
        } else {
          router.push(LOGIN_PATHS.LOGIN);
        }
      })
      .catch((err) => {
        router.push(LOGIN_PATHS.LOGIN);
      })
      .finally(() => {});
  };

  const loginTemporarily = (params: ILoginParams) => {
    const showRecaptcha = localStorage.getItem(SHOW_RECAPTCHA) !== "false";
    // 是否开启阿里云验证
    if (showRecaptcha) {
      ncVal?.sessionId
        ? fetchLogin({
            ...params,
            data: ncVal,
            type: "nc"
          }) // 滑动验证提交
        : NVCCaptcha.getVal?.((nvcVal) => {
            fetchLogin({
              ...params,
              data: nvcVal,
              type: "nvc"
            }); // 无痕验证提交
          });
    } else {
      fetchLogin(params);
    }
  };

  const handleSubmit = () => {
    setSubmitLoading(true);
    form.validateFields().then((values) => {
      const params = {
        firstName: values.fullName,
        accountName: values.email,
        password: md5Encrypted(values.passwordConfirm, MD5_FIXED_STRING),
        verifyCode: values.verifyCode,
        inviteCode: values.invitationCode,
        relationshipManager: values.relationshipManager
      };
      register(params)
        .then((res) => {
          if (res?.code == "0") {
            OPEN_BIND_PHONE
              ? loginTemporarily({
                  accountEmail: values.email,
                  password: md5Encrypted(values.passwordConfirm, MD5_FIXED_STRING)
                })
              : router.push(LOGIN_PATHS.LOGIN);
            return;
          }
          message.error(intl?.[res?.msg] ?? res?.msg);
        })
        .finally(() => {
          setSubmitLoading(false);
        });

      // 通过邀请码注册的上报一个Ga事件
      if (values.inviteCode) {
        reportEvent({
          moduleName: GA_EVENT_NAME.login.inviteCodeRegister,
          detailParams: {
            inviteCode: values.inviteCode,
            relationshipManager: values.relationshipManager
          }
        });
      }
    });
  };

  return (
    <div className={styles.form}>
      {step !== 3 && (
        <Form
          style={{ display: "block" }}
          form={form}
          name="basic"
          layout="vertical"
          autoComplete="off"
          onFinish={handleSubmit}>
          <RegisterStep1 form={form} step={step} setStep={setStep} />
          <RegisterStep2 submitLoading={submitLoading} step={step} setStep={setStep} />
        </Form>
      )}
      {step === 3 && <RegisterStep3 jwtToken={jwtToken} />}
    </div>
  );
};

export const PageRegister = LoginLayoutPortal(WithAliCloudNC(withRouter(Register)));
