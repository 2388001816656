import React from "react";
import { Col, Row, Tabs } from "antd";
import styles from "@aspen/theme/Event.module.less";
import type { IInjectProps } from "@aspen/model";
import { EEventType } from "@aspen/model";
import { withRouter } from "next/router";
import { EVENT_TYPES, getQueryValue, i18nUtil, isClient } from "@aspen/libs";
import { EventList, TabLabel } from "@aspen/widgets";

interface IProps extends IInjectProps {}

interface IState {
  currentTab: string;
}

const intl = i18nUtil.t();
class EventListTab extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      currentTab: ""
    };
  }
  tabItems = [
    {
      key: EVENT_TYPES.all,
      label: <TabLabel title="event.tab.all" />
    },
    {
      key: EVENT_TYPES.online,
      label: <TabLabel title="event.tab.online" />
    },
    {
      key: EVENT_TYPES.inPerson,
      label: <TabLabel title="event.tab.InPerson" />
    }
  ];

  componentDidMount() {
    this.setState({
      currentTab:
        this.props?.router?.query?.currentTab?.toString() ||
        (isClient && getQueryValue("currentTab")) ||
        EVENT_TYPES.all
    });
  }

  handleChangeTab = (currentTab) => {
    this.props.router.replace({ query: { currentTab } });
    this.setState({
      currentTab
    });
  };

  render(): React.ReactNode {
    const { currentTab } = this.state;
    return (
      <section className={`${styles.event} customer`}>
        <div className="customerTop">
          <Row className="top">
            <Col span={24}>
              <p className="title">{intl["events.title"]}</p>
            </Col>
          </Row>
        </div>
        <Row className="section">
          <Col span={24}>
            <Tabs
              items={this.tabItems}
              onChange={this.handleChangeTab}
              className={styles.tab}
              activeKey={currentTab}
            />

            <div className={styles.list}>
              <div className={styles.flexibleContent}>
                {currentTab === EVENT_TYPES.all && <EventList />}
                {currentTab === EVENT_TYPES.online && <EventList type={EEventType.ONLINE} />}
                {currentTab === EVENT_TYPES.inPerson && <EventList type={EEventType.OFFLINE} />}
              </div>
            </div>
          </Col>
        </Row>
      </section>
    );
  }
}

export const PageEventList = withRouter(EventListTab);
