import React, { useEffect, useState, useRef } from "react";
import type { RadioChangeEvent } from "antd";
import { Spin, Checkbox, Button, Modal, Radio, Space } from "antd";
import { useRouter } from "next/router";
import styles from "@aspen/theme/Profile.module.less";
import { message } from "@aspen/ui";
import { processApplyRole, applyCustomerRole } from "@aspen/services";
import type { IVerifyParam } from "@aspen/model";
import { transformDate, reportEvent, i18nUtil, GA_EVENT_NAME } from "@aspen/libs";
import { useScrollToBottomHook } from "@aspen/hooks";
import { ModalLoadingContext } from "@aspen/store";
import { PageAuthorizationAgreement } from "../authorizationAgreement";
import { WithEmailOrGoogleVerify } from "@aspen/widgets";

const PartnerAuthorization = () => {
  const intl = i18nUtil.t();
  const [loading, setLoading] = useState(false);
  const [hasAgreement, setHasAgreement] = useState(false);
  const [rejectModalVisible, setRejectModalVisible] = useState(false);
  const [hasreadAllAgreement, setHasreadAllAgreement] = useState(false);
  const [partnerInfo, setPartnerInfo] = useState<any>();
  const [authorizationType, setAuthorizationType] = useState(null);
  const router = useRouter();

  useEffect(() => {
    applyCustomerRole().then((res) => {
      if (res?.code == "0") {
        setPartnerInfo(res.data || {});
      }
    });
  }, []);

  const handleCheckAgreement = (e: RadioChangeEvent) => {
    setHasAgreement(e.target.checked);
  };

  const handleAuthorizationType = (e: RadioChangeEvent) => {
    setAuthorizationType(e.target.value);
  };

  const verifyRef = useRef<any>(null);

  const handleSubmit = () => {
    if (authorizationType === null) {
      message.warning(intl["profile.partner.authorization.type.read.tips"], 2);
      return;
    }
    if (!hasreadAllAgreement) {
      message.warning(intl["profile.partner.authorization.agreement.read.tips"], 2);
      return;
    }
    verifyRef?.current?.handleCheckVerify();

    reportEvent({ moduleName: GA_EVENT_NAME.profile.agent.submitAuth });
  };

  const handleApprove = (verifyParam: IVerifyParam) => {
    const { verifyCode, verifyType, businessType } = verifyParam;
    const params = {
      authorizationType,
      id: partnerInfo?.id,
      status: 2,
      businessType,
      verifyCode,
      verifyType
    };
    setRejectModalVisible(false);
    handleFetch(params);

    reportEvent({ moduleName: GA_EVENT_NAME.profile.agent.approveAuth });
  };

  const handleReject = () => {
    const params = {
      id: partnerInfo?.id,
      status: 5
    };
    setRejectModalVisible(false);
    handleFetch(params);

    reportEvent({ moduleName: GA_EVENT_NAME.profile.agent.rejectAuth });
  };

  const handleFetch = (params) => {
    setLoading(true);
    processApplyRole(params)
      .then((res) => {
        if (res?.code == "0") {
          verifyRef?.current?.hideVerifyModal();
          message.success("Success", 2, () => {
            router.back();
          });
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // 阅读完协议
  const scrollRef = useRef(null);
  const readAll = () => {
    if (!hasreadAllAgreement) {
      setHasreadAllAgreement(true);
    }
  };

  useScrollToBottomHook(scrollRef, readAll);

  return (
    <section className={`${styles.profile} ` + `customer`}>
      <section className="customerTop">
        <div className={`top ${styles.profileTop}`}>
          <p className="title">{intl["profile.partner.authorization.title"]}</p>
        </div>
      </section>
      <Spin spinning={loading}>
        <div className="section">
          <div className={styles.applicationInfo}>
            <p className={styles.title}>{intl["profile.partner.authorization.information"]}</p>
            <div className={styles.dividerLine} />
            <div>
              <div className={`${styles.accountInfo} ${styles.marginBottom24}`}>
                <div className={styles.key}>{intl["profile.partner.authorization.name"]}</div>
                <div className={styles.value}>{partnerInfo?.agentName}</div>
              </div>
              <div className={styles.accountInfo}>
                <div className={styles.key}>{intl["profile.partner.authorization.email"]}</div>
                <div className={styles.value}>{partnerInfo?.agentEmail}</div>
              </div>
            </div>
            <div className={styles.dividerLine} />
            <p className={styles.title}>{intl["profile.partner.authorization.permission"]}</p>
            <div className={styles.authorizatioTh}>
              <div className={styles.authorizatioThFirst}>
                {intl["profile.partner.authorization.authorization"]}
              </div>
              <div>{intl["profile.partner.authorization.description"]}</div>
            </div>
            {intl["profile.partner.authorization.detail"].map((item, index) => {
              return (
                <div key={index}>
                  <div className={styles.authorizatioTr}>
                    <div className={styles.authorizatioTrFirst}>{item.key}</div>
                    <div className={styles.authorizatioTrSecond}>{item.detail}</div>
                  </div>
                  <div className={styles.dividerLine} />
                </div>
              );
            })}
            <div className={styles.textColorOp3}>
              <p style={{ marginBottom: 16 }}>
                {intl["profile.partner.authorization.period.title"]}
              </p>
              <p>{`${transformDate(partnerInfo?.effectiveDate)} ~ ${transformDate(
                partnerInfo?.expirationDate
              )}`}</p>
            </div>
          </div>
          <div className={styles.agreementContent}>
            <p className={styles.title}>{intl["profile.partner.authorization.agreement"]}</p>
            <div className={styles.dividerLine} />
            <div className={styles.agreementArea} ref={scrollRef}>
              <PageAuthorizationAgreement />
            </div>
            <div className={styles.authorizationType}>
              <p className={styles.title}>{intl["profile.partner.authorization.type"]}</p>
              <Radio.Group
                style={{ marginTop: "32px" }}
                onChange={handleAuthorizationType}
                value={authorizationType}>
                <Space direction="vertical">
                  <Radio value={1}>{intl["profile.partner.authorization.option.full"]}</Radio>
                  <Radio value={0}>{intl["profile.partner.authorization.option.single"]}</Radio>
                </Space>
              </Radio.Group>
            </div>
            <div className={styles.dividerLine} />
            <Checkbox style={{ margin: "24px 0" }} onChange={handleCheckAgreement}>
              {intl["profile.partner.authorization.agreement.read"]}
            </Checkbox>
            <div className={styles.btnContent}>
              <Button
                className={styles.btn}
                disabled={!hasAgreement}
                type="primary"
                onClick={handleSubmit}>
                {intl["button.authorize"]}
              </Button>
              <Button
                className={styles.btn}
                type="primary"
                onClick={() => {
                  setRejectModalVisible(true);
                  reportEvent({ moduleName: GA_EVENT_NAME.profile.agent.rejectAuthModal });
                }}>
                {intl["button.reject"]}
              </Button>
            </div>
          </div>
        </div>
        <Modal
          wrapClassName={styles.modal}
          title={intl["profile.partner.modal.authorization.reject"]}
          open={rejectModalVisible}
          confirmLoading={loading}
          onOk={handleReject}
          onCancel={() => setRejectModalVisible(false)}
          centered
          destroyOnClose
          okText={intl["button.confirm"]}
          cancelText={intl["button.cancel"]}
          width={480}>
          <p>
            {intl["profile.partner.authorization.reject"]} {partnerInfo?.agentEmail}?
          </p>
        </Modal>
        <ModalLoadingContext.Provider value={{ confirmLoading: loading }}>
          <WithEmailOrGoogleVerify
            ref={verifyRef}
            businessType={-1}
            handleConfirm={handleApprove}
          />
        </ModalLoadingContext.Provider>
      </Spin>
    </section>
  );
};

export const PagePartnerAuthorization = React.memo(PartnerAuthorization);
