import React, { useEffect, useState } from "react";
import { Col, Row, Space, Spin } from "antd";
import { useRouter } from "next/router";
import clsx from "clsx";
import styles from "@aspen/theme/Intelligence.module.less";
import { fecthIntelligenceNews } from "@aspen/services";
import type { INews, IReqIntelligenceNews } from "@aspen/model";
import { BRAND, NEWSTYPE } from "@aspen/model";
import { i18nUtil, INTELLIGENCE_PATHS, ONLY_MOBILE, systemDetection } from "@aspen/libs";
import { useAsideHeight } from "@aspen/hooks";
import { HeadRender } from "@aspen/ui";
import {
  DetailInfo,
  DownloadAppCard,
  EmptyShareDetail,
  ExpertCardItem,
  JumpApp,
  NewsCard,
  NewsItem
} from "@aspen/widgets";

interface IProps {
  detailData: INews;
}

const NewsDetail: React.FC<IProps> = (props: IProps) => {
  const [detailContent, setDetailContent] = useState<INews | null>(props.detailData);
  // 热点追踪
  const [newsList, setNewsList] = useState<INews[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  // 专栏
  const [expertList, setExpertList] = useState<INews[]>([]);
  const [expertLoading, setExpertLoading] = useState<boolean>(false);
  // 研报
  const [researchList, setResearchList] = useState<INews[]>([]);
  const [researchLoading, setResearchLoading] = useState<boolean>(false);

  const router = useRouter();

  const intl = i18nUtil.t();
  const system = systemDetection();
  const moreNewsText = intl["intelligence.news.more.news.text"];
  const expertViews = intl["intelligence.expert.views"];
  const researchReport = intl["intelligence.research.report"];
  const moreText = intl["link.more"];

  const { ref: asideRef } = useAsideHeight();

  useEffect(() => {
    if (props.detailData) setDetailContent(props.detailData);
  }, [props.detailData]);

  useEffect(() => {
    if (!ONLY_MOBILE) {
      getExpertList(); // 专栏
      getResearchList(); // 研报
    }
  }, []);

  useEffect(() => {
    if (!ONLY_MOBILE) {
      getNewsList(); // 获取 Hot News List
    }
  }, [detailContent?.newsTime]);

  // 获取 Hot News List
  const getNewsList = () => {
    setLoading(true);
    const params: IReqIntelligenceNews = {
      limit: 6,
      action: "all",
      type: NEWSTYPE.news, // 爬虫新闻
      startTime: detailContent?.newsTime
    };
    fecthIntelligenceNews(params)
      .then((res) => {
        if (res?.code == "0") {
          setNewsList(res?.data?.rows ?? []);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // 获取专栏列表
  const getExpertList = () => {
    setExpertLoading(true);
    const params: IReqIntelligenceNews = {
      limit: 3,
      action: "all",
      type: NEWSTYPE.expert
    };
    fecthIntelligenceNews(params)
      .then((res) => {
        if (res?.code == "0") {
          setExpertList(res?.data?.rows ?? []);
        }
      })
      .finally(() => {
        setExpertLoading(false);
      });
  };

  // 获取研报列表
  const getResearchList = () => {
    setResearchLoading(true);
    const params: IReqIntelligenceNews = {
      limit: 5,
      action: "all",
      type: NEWSTYPE.research // 研报
    };
    fecthIntelligenceNews(params)
      .then((res) => {
        if (res?.code == "0") {
          setResearchList(res?.data?.rows ?? []);
        }
      })
      .finally(() => {
        setResearchLoading(false);
      });
  };

  return (
    <>
      {/* 新闻详情单独处理 */}
      <HeadRender
        title={detailContent?.title}
        description={detailContent?.summary}
        image={detailContent?.images?.[0]}
      />
      <section>
        <Row className={styles.row} gutter={16}>
          <Col flex="auto">
            <Spin spinning={loading}>
              <div className={clsx(styles.newsDetail, ONLY_MOBILE && styles.app)}>
                {detailContent ? (
                  <>
                    <DetailInfo
                      type={detailContent?.verbEnum === NEWSTYPE.feed ? "feed" : "news"}
                      dateType="time"
                      detailInfo={detailContent}
                    />
                    {ONLY_MOBILE ? (
                      <JumpApp
                        id={detailContent?.id}
                        path={INTELLIGENCE_PATHS.INTELLIGENCE_NEWS_DETAIL}
                        event_action="NewsDetail"
                      />
                    ) : null}
                  </>
                ) : (
                  <EmptyShareDetail />
                )}
                {ONLY_MOBILE ||
                  (newsList?.length > 0 && (
                    <NewsCard
                      loading={loading}
                      title={moreNewsText}
                      link={INTELLIGENCE_PATHS.INTELLIGENCE_NEWS}>
                      {newsList.map((item) => (
                        <NewsItem
                          key={item.id}
                          data={item}
                          path={INTELLIGENCE_PATHS.INTELLIGENCE_NEWS_DETAIL}
                        />
                      ))}
                    </NewsCard>
                  ))}
              </div>
            </Spin>
          </Col>
          {ONLY_MOBILE || (
            <Col
              flex="340px"
              ref={asideRef}
              className={styles.detailExtraContainer}
              style={{
                position: expertLoading || researchLoading ? "static" : "sticky"
              }}>
              <Spin spinning={expertLoading || researchLoading}>
                <Space size={16} direction="vertical">
                  {/* 专栏 */}
                  {expertList?.length > 0 && (
                    <NewsCard
                      title={expertViews}
                      linkText={moreText}
                      link={INTELLIGENCE_PATHS.INTELLIGENCE_EXPERT}>
                      {expertList?.map((item) => <ExpertCardItem key={item.id} data={item} />)}
                    </NewsCard>
                  )}
                  {/* 研报 */}
                  <NewsCard
                    title={researchReport}
                    linkText={moreText}
                    link={INTELLIGENCE_PATHS.INTELLIGENCE_RESEARCH}>
                    {researchList?.map((item, index) => (
                      <div
                        key={item.id}
                        className={styles.researchCardItem}
                        onClick={() => {
                          router.push(
                            `${INTELLIGENCE_PATHS.INTELLIGENCE_RESEARCH_DETAIL}/${item?.id}`
                          );
                        }}>
                        <div className={styles.number}>{index + 1}</div>
                        <div className={styles.articleTitle}>{item.title}</div>
                      </div>
                    ))}
                  </NewsCard>
                  {system === BRAND ? <DownloadAppCard /> : null}
                </Space>
              </Spin>
            </Col>
          )}
        </Row>
      </section>
    </>
  );
};

export const PageNewsDetail = React.memo(NewsDetail);
