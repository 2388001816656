import React from "react";
import { Breadcrumb } from "antd";
import Link from "next/link";
import { withRouter } from "next/router";
import { getQueryValue, i18nUtil, WALLET_PATHS } from "@aspen/libs";
import { IInjectProps } from "@aspen/model";
import { History } from "@aspen/widgets";
interface IProps extends IInjectProps {}
interface IState {}

class WalletHistory extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  render(): React.ReactNode {
    const intl = i18nUtil.t();
    const location: string = intl["kyc.certify.location"];
    const wallet: string = intl["wallet"];
    const history = intl["wallet.history"];
    const type = getQueryValue("type");
    const currency = getQueryValue("currency");
    const fiat = getQueryValue("fiat");

    return (
      <>
        <Breadcrumb style={{ marginBottom: "24px" }}>
          <Breadcrumb.Item>{location}:</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link href={WALLET_PATHS.WALLET}>{wallet}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{history}</Breadcrumb.Item>
        </Breadcrumb>
        <History
          showViewAll={false}
          type={Number(type)}
          currency={currency?.toString()}
          fiat={Boolean(fiat)}
        />
      </>
    );
  }

  componentDidMount(): void {}
}
export const PageWalletHistory = withRouter(WalletHistory);
