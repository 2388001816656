import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Col, Row, Space, Spin } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import clsx from "clsx";
import styles from "@aspen/theme/Intelligence.module.less";
import type { IIntelligenceIResponse, INews, IReqIntelligenceNews } from "@aspen/model";
import { NEWSTYPE } from "@aspen/model";
import { fecthIntelligenceNews } from "@aspen/services";
import { GA_EVENT_NAME, i18nUtil, INTELLIGENCE_PATHS, reportEvent } from "@aspen/libs";
import { useAsideHeight } from "@aspen/hooks";
import { Expert, NewsBanner, NewsItem, NewsMenu, Report, Research } from "@aspen/widgets";
import { message } from "@aspen/ui";

interface IProps {
  data: IIntelligenceIResponse;
}

const News: React.FC<IProps> = ({ data }) => {
  const { ref: asideRef } = useAsideHeight();
  const intl = i18nUtil.t();
  const [newsList, setNewsList] = useState<INews[]>([]);
  const [nextToken, setNextToken] = useState<string>("");
  const [newsLoading, setNewsLoading] = useState<boolean>(false);

  useLayoutEffect(() => {
    setNextToken(data?.nextToken);
    setNewsList(data?.rows ?? []);
  }, []);

  useEffect(() => {
    // 如果请求有错误(不是未登录的情况，页面给出报错)
    if (data?.msg) {
      message.error(data.msg);
    }
  }, []);

  const getNewsData = () => {
    setNewsLoading(true);
    const params: IReqIntelligenceNews = {
      limit: 20,
      action: "all",
      type: NEWSTYPE.news, // 爬虫新闻
      nextToken
    };
    fecthIntelligenceNews(params)
      .then((res) => {
        setNextToken(res?.data?.nextToken);
        if (res?.data?.rows?.length > 0) {
          setNewsList(nextToken ? newsList.concat(res?.data?.rows) : (res?.data?.rows ?? []));
        }
      })
      .finally(() => {
        setNewsLoading(false);
      });
  };

  const handleLoadMore = () => {
    getNewsData();
    reportEvent({ moduleName: GA_EVENT_NAME.intelligence.news.list_loadMore });
  };

  return (
    <section className={clsx(styles.intelligence, styles.news)}>
      <Row className={styles.row}>
        <Col>
          <NewsMenu />
        </Col>
        <Col flex="auto" className={styles.middleContainer}>
          <NewsBanner />
          <div className={styles.newsContainer}>
            {/* news */}
            <div className={styles.newsList}>
              <p className={styles.newsTitle}>{intl["intelligence.news"]}</p>
              <Spin spinning={newsLoading}>
                <div className={styles.articleList}>
                  {newsList?.length > 0
                    ? newsList?.map((item) => (
                        <NewsItem
                          key={item.id}
                          data={item}
                          path={INTELLIGENCE_PATHS.INTELLIGENCE_NEWS_DETAIL}
                        />
                      ))
                    : newsLoading || <p className={styles.emptyText}>{intl?.["empty.list"]}</p>}
                  {/* 分页 */}
                  {newsList?.length > 0 &&
                    (nextToken && newsList?.length >= 15 ? (
                      <div className={styles.paginationMask}>
                        <Button
                          className={styles.loadMore}
                          icon={<SyncOutlined />}
                          onClick={handleLoadMore}>
                          {intl["intelligence.loadMore"]}
                        </Button>
                      </div>
                    ) : (
                      <>
                        <p className={styles.noMoreData}>{intl["noMoreData"]}</p>
                        {/* 解决分页的占位问题 */}
                        <div style={{ height: 230 }} />
                      </>
                    ))}
                </div>
              </Spin>
            </div>
          </div>
        </Col>
        <Col flex="276px" ref={asideRef} className={styles.rightContainer}>
          <Space size={16} direction="vertical" style={{ width: 276 }}>
            {/* 精选研报 */}
            <Research />
            {/* 推荐专栏 */}
            <Expert />
            {/* 每日市场速递 */}
            <Report title="intelligence.insights.daily.briefing" type={NEWSTYPE.daily} />
            {/* 每周市场速递 */}
            <Report title="intelligence.insights.weekly.newsletter" type={NEWSTYPE.weeklyReport} />
            {/* 月度研究报告 */}
            <Report title="intelligence.insights.monthly.review" type={NEWSTYPE.monthlyReport} />
          </Space>
        </Col>
      </Row>
    </section>
  );
};

export const PageNews = React.memo(News);
