import React from "react";
import { Row } from "antd";
import { withRouter } from "next/router";
import { connect } from "react-redux";
import type { IAssetWalletInfo, IInjectProps, IkycInfo, IPartnerInfo } from "@aspen/model";
import { CLIENT_AUTHORIZATION_STATUS } from "@aspen/model";
import styles from "@aspen/theme/Profile.module.less";
import { fecthInviterInfo, applyCustomerRole } from "@aspen/services";
import {
  USER_AUTH,
  USER_ROLE,
  certifiedKycLevel,
  getQueryValue,
  i18nUtil,
  isClient
} from "@aspen/libs";
import {
  BasicInfo,
  KYCInfomations,
  PartnerAccount,
  SafetyInfo,
  SubAccount,
  ServiceAccount
} from "@aspen/widgets";

import { WithKYCInfo } from "@aspen/ui";

interface IProps extends IInjectProps {
  kycInfo: IkycInfo;
  getKYCInfo: () => void;
  walletInfo: IAssetWalletInfo;
}

interface IState {
  userName: string;
  resetToken: string;
  showResetPasswordStep2: boolean;
  inviteInfo: {
    code: string;
    type: 0 | 1;
  };
  partnerInfo: IPartnerInfo | null;
}

class Profile extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      inviteInfo: {
        code: "",
        type: 0
      },
      userName: "",
      resetToken: "",
      showResetPasswordStep2: false,
      partnerInfo: null
    };

    this.handleClick = this.handleClick.bind(this);
    this.fetchCurrentInviterInfo = this.fetchCurrentInviterInfo.bind(this);
  }

  fetchCurrentInviterInfo(): void {
    fecthInviterInfo().then((res) => {
      if (res?.code == "0") {
        this.setState({
          inviteInfo: res?.data ?? {}
        });
      }
    });
  }

  getApplyPartnerRole = () => {
    applyCustomerRole().then((res) => {
      if (res?.code == "0") {
        this.setState({ partnerInfo: res?.data });
      }
    });
  };

  render(): React.ReactNode {
    const { partnerInfo, inviteInfo } = this.state;
    const { walletInfo, kycInfo } = this.props;
    const intl = i18nUtil.t();
    const user_auth = isClient && localStorage.getItem(USER_AUTH);
    const isShow = !(
      isClient &&
      (user_auth === USER_ROLE.ROLE_MANAGER_CUSTOMER ||
        user_auth === USER_ROLE.ROLE_MANAGER_READONLY)
    );
    return (
      <section className={`${styles.profile} ` + `customer`}>
        <section className="customerTop">
          <div className={`top ${styles.profileTop}`}>
            <p className="title">{intl["profile"]}</p>
          </div>
        </section>
        {isShow ? (
          <Row gutter={16} className="section" style={{ flexDirection: "column" }}>
            <BasicInfo inviteInfo={inviteInfo} />
            <SafetyInfo {...this.props} />
            <div id="kyc">
              <KYCInfomations />
            </div>
            {certifiedKycLevel(1) && <SubAccount kycInfo={this.props?.kycInfo} />}
            {certifiedKycLevel(1) && <ServiceAccount />}
            <div id="authorize">
              {
                // status 1 2 3是review状态， 4 8是success状态
                partnerInfo &&
                (CLIENT_AUTHORIZATION_STATUS[partnerInfo.status] ==
                  CLIENT_AUTHORIZATION_STATUS[4] ||
                  CLIENT_AUTHORIZATION_STATUS[partnerInfo.status] ==
                    CLIENT_AUTHORIZATION_STATUS[1]) ? (
                  <PartnerAccount partnerInfo={partnerInfo} reload={this.getApplyPartnerRole} />
                ) : null
              }
            </div>
          </Row>
        ) : null}
      </section>
    );
  }

  handleClick(userName: string, resetToken: string): void {
    this.setState({
      userName,
      resetToken,
      showResetPasswordStep2: true
    });
  }

  scrollToAnchor = (anchorName) => {
    if (anchorName) {
      // 找到锚点
      const anchorElement = document.getElementById(anchorName);
      // 如果对应id的锚点存在，就跳转到锚点
      if (anchorElement) {
        setTimeout(() => {
          anchorElement.scrollIntoView({ block: "end", behavior: "smooth" });
        }, 200);
      }
    }
  };

  componentDidMount() {
    const user_auth = isClient && localStorage.getItem(USER_AUTH);
    if (
      isClient &&
      (user_auth === USER_ROLE.ROLE_MANAGER_CUSTOMER ||
        user_auth === USER_ROLE.ROLE_MANAGER_READONLY)
    ) {
      return;
    }
    this.props.getKYCInfo();
    this.fetchCurrentInviterInfo();
    this.getApplyPartnerRole();
    getQueryValue("anchorPoint") && this.scrollToAnchor(getQueryValue("anchorPoint"));
  }
}

const mapStateToProps = (state) => {
  const { walletInfo } = state.wallet;
  return {
    walletInfo
  };
};
export const PageProfile = connect(mapStateToProps)(WithKYCInfo(withRouter(Profile)));
