import React, { useState, useEffect } from "react";
import { Spin, Row, Col, Tooltip, Button } from "antd";
import { EyeInvisibleOutlined, EyeOutlined, InfoCircleOutlined } from "@ant-design/icons";
import type { IAssetCurrencies, IAssetSituationalData, IAssetWalletInfo } from "@aspen/model";
import { BRAND, COMMON_CACHE_PRIORTY_KEYS } from "@aspen/model";
import { getAssetsCurrencies, getAssetsRevenues } from "@aspen/services";
import styles from "@aspen/theme/Assets.module.less";
import {
  BENCHMARK_CURRENCY_SYMBOL,
  DOWNLOAD_BILL_PATH,
  GA_EVENT_NAME,
  REFERENCE_CURRENCY,
  USER_AUTH,
  USER_ROLE,
  numberToThousands,
  decimalPointNoMoreX,
  getPNLColor,
  convertUSD2USDC,
  i18nUtil,
  reportEvent,
  isClient,
  systemDetection
} from "@aspen/libs";
import dayjs from "dayjs";
import { omit, omitBy } from "lodash-es";
import {
  EmptyCharts,
  TotalAssetsLine,
  DateRangePicker,
  TotalAssetsChart,
  AssetsProgress,
  PNLiquidChart,
  AssetsAllocationChart,
  LineChart,
  ColumnChart
} from "@aspen/widgets";
import { useAppSelector, walletInfo } from "@aspen/store";
import { useCachePriorityData } from "@aspen/hooks";
import { useRouter } from "next/router";

const intl = i18nUtil.t();
const user_auth = (isClient && window.localStorage.getItem(USER_AUTH)) || USER_ROLE.ROLE_CUSTOMER;
const roles = [USER_ROLE.ROLE_SUB, USER_ROLE.ROLE_CUSTOMER];
const showEntrance = roles.includes(user_auth);
function Assets() {
  const router = useRouter();
  const title: string = intl["overview.assetDistribution.to.assets"];
  const totalAssetsTitle: string = i18nUtil.formatMessage(
    { id: "overview.assetDistribution.title" },
    { currency: REFERENCE_CURRENCY }
  );
  const spotText: string = intl["overview.assetDistribution.spot"];
  const yieldText: string = intl["overview.assetDistribution.yield"];
  const structuredText = intl["overview.assetDistribution.structured"];
  const strategiesText: string = intl["overview.assetDistribution.strategies"];
  const venturesText: string = intl["overview.assetDistribution.ventures"];
  const vaultText: string = intl["overview.assetDistribution.vault"];
  const totalAssets: string = intl["assets.total.text"];
  const totalPNL = intl["assets.pnl.total"];
  const todayPNL = intl["assets.pnl.today"];
  const allocationTitle: string = i18nUtil.formatMessage(
    { id: "assets.allocation.title" },
    { currency: REFERENCE_CURRENCY }
  );
  const allocationTitleTips: string = i18nUtil.formatMessage(
    { id: "assets.allocation.title.tips" },
    { currency: REFERENCE_CURRENCY }
  );

  const PNLAllocationTitle: string = intl["assets.pnl.allocation.title"];
  const PNLAllocationDays: string = intl["assets.pnl.allocation.days"];
  const PNLAllocationCustom: string = intl["assets.pnl.allocation.custom"];
  const PNLAllocationDaily: string = i18nUtil.formatMessage(
    { id: "assets.pnl.allocation.daily" },
    { currency: REFERENCE_CURRENCY }
  );
  const PNLAllocationTotal: string = i18nUtil.formatMessage(
    { id: "assets.pnl.allocation.total" },
    { currency: REFERENCE_CURRENCY }
  );
  const noticeTitle: string = intl["assets.notice.title"];
  const noticeContentFirst: string = intl["assets.notice.content.first"];
  const noticeContentSecond: string = intl["assets.notice.content.second"];
  const otherText: string = intl["assets.other"];
  const tipsReal: string = intl["assets.tips.real"];
  const tipsYesterday: string = intl["assets.tips.yesterday"];

  const [allocationLoading, setAllocationLoading] = useState<boolean>(false);
  const [PNLLoading, setPNLLoading] = useState<boolean>(false);
  const [isShowAmount, setAmountInfo] = useState<boolean>(true);
  const [currenciesArr, setCurrenciesArr] = useState<Array<IAssetCurrencies>>();
  const [dailyPNLInfo, setDailyPNLInfo] = useState<IAssetSituationalData[]>();
  const [totalPNLInfo, setTotalPNLInfo] = useState<IAssetSituationalData[]>();
  const [dailyLastPNL, setDailyLastPNL] = useState<unknown | number>();
  const [totalLastPNL, setTotalLastPNL] = useState<unknown | number>();
  // 总资产 （现货 理财 结构化理财 策略 风投） 颜色
  const colorArr = {
    spot: "#3987CF",
    yield: "#A646B6",
    structured: "#E5B60B",
    strategies: "#F17E00",
    ventures: "#64A797",
    vault: "#F67070"
  };
  const currenciesColorArr = [
    "#6B84C6",
    "#7345BF",
    "#3987CF",
    "#8ADDD8",
    "#33BCB7",
    "#90C57E",
    "#E8B339"
  ]; // 币种分布颜色
  const [pnlDateInterval, setPnlDateInterval] = useState<string>("7");
  const [pnlStartDate] = useState<string>(dayjs().subtract(7, "day").format("YYYY-MM-DD"));
  const [pnlEndDate] = useState<string>(dayjs().subtract(1, "day").format("YYYY-MM-DD"));
  const walletData = useAppSelector(walletInfo);
  const system = systemDetection();
  const pieList =
    system === BRAND
      ? [
          {
            title: spotText,
            value: Number(walletData?.spotAmount)
          },
          {
            title: yieldText,
            value: Number(walletData?.yieldAmount)
          },
          {
            title: structuredText,
            value: Number(walletData?.structuredAmount)
          },
          {
            title: strategiesText,
            value: Number(walletData?.strategyAmount)
          },
          {
            title: venturesText,
            value: Number(walletData?.ventureAmount)
          },
          {
            title: vaultText,
            value: Number(walletData?.coldWalletAmount)
          }
        ]
      : [
          {
            title: spotText,
            value: Number(walletData?.spotAmount)
          },
          {
            title: structuredText,
            value: Number(walletData?.structuredAmount)
          },
          {
            title: strategiesText,
            value: Number(walletData?.fundAmount)
          },
          {
            title: venturesText,
            value: Number(walletData?.ventureAmount)
          }
        ];
  const progressList =
    system === BRAND
      ? [
          {
            title: spotText,
            value:
              BENCHMARK_CURRENCY_SYMBOL +
              numberToThousands(decimalPointNoMoreX(walletData?.spotAmount ?? 0, 2)),
            percent: Math.ceil(
              (Number(walletData?.spotAmount) / Number(walletData?.totalAmount)) * 100
            ),
            color: colorArr.spot
          },
          {
            title: yieldText,
            value:
              BENCHMARK_CURRENCY_SYMBOL +
              numberToThousands(decimalPointNoMoreX(walletData?.yieldAmount ?? 0, 2)),
            percent: Math.ceil(
              (Number(walletData?.yieldAmount) / Number(walletData?.totalAmount)) * 100
            ),
            color: colorArr.yield
          },
          {
            title: structuredText,
            value:
              BENCHMARK_CURRENCY_SYMBOL +
              numberToThousands(decimalPointNoMoreX(walletData?.structuredAmount ?? 0, 2)),
            percent: Math.ceil(
              (Number(walletData?.structuredAmount) / Number(walletData?.totalAmount)) * 100
            ),
            color: colorArr.structured
          },
          {
            title: strategiesText,
            value:
              BENCHMARK_CURRENCY_SYMBOL +
              numberToThousands(decimalPointNoMoreX(walletData?.strategyAmount ?? 0, 2)),
            percent: Math.ceil(
              (Number(walletData?.strategyAmount) / Number(walletData?.totalAmount)) * 100
            ),
            color: colorArr.strategies
          },
          {
            title: venturesText,
            value:
              BENCHMARK_CURRENCY_SYMBOL +
              numberToThousands(decimalPointNoMoreX(walletData?.ventureAmount ?? 0, 2)),
            percent: Math.ceil(
              (Number(walletData?.ventureAmount) / Number(walletData?.totalAmount)) * 100
            ),
            color: colorArr.ventures
          },
          {
            title: vaultText,
            value:
              BENCHMARK_CURRENCY_SYMBOL +
              numberToThousands(decimalPointNoMoreX(walletData?.coldWalletAmount ?? 0, 2)),
            percent: Math.ceil(
              (Number(walletData?.coldWalletAmount) / Number(walletData?.totalAmount)) * 100
            ),
            color: colorArr.vault
          }
        ]
      : [
          {
            title: spotText,
            value:
              BENCHMARK_CURRENCY_SYMBOL +
              numberToThousands(decimalPointNoMoreX(walletData?.spotAmount ?? 0, 2)),
            percent: Math.ceil(
              (Number(walletData?.spotAmount) / Number(walletData?.totalAmount)) * 100
            ),
            color: colorArr.spot
          },
          {
            title: structuredText,
            value:
              BENCHMARK_CURRENCY_SYMBOL +
              numberToThousands(decimalPointNoMoreX(walletData?.structuredAmount ?? 0, 2)),
            percent: Math.ceil(
              (Number(walletData?.structuredAmount) / Number(walletData?.totalAmount)) * 100
            ),
            color: colorArr.structured
          },
          {
            title: strategiesText,
            value:
              BENCHMARK_CURRENCY_SYMBOL +
              numberToThousands(decimalPointNoMoreX(walletData?.fundAmount ?? 0, 2)),
            percent: Math.ceil(
              (Number(walletData?.fundAmount) / Number(walletData?.totalAmount)) * 100
            ),
            color: colorArr.strategies
          },
          {
            title: venturesText,
            value:
              BENCHMARK_CURRENCY_SYMBOL +
              numberToThousands(decimalPointNoMoreX(walletData?.ventureAmount ?? 0, 2)),
            percent: Math.ceil(
              (Number(walletData?.ventureAmount) / Number(walletData?.totalAmount)) * 100
            ),
            color: colorArr.ventures
          }
        ];

  const allocationName =
    system === BRAND
      ? {
          spot: spotText,
          yield: yieldText,
          structured: structuredText,
          strategies: strategiesText,
          ventures: venturesText,
          vault: vaultText
        }
      : {
          spot: spotText,
          structured: structuredText,
          strategies: strategiesText,
          ventures: venturesText
        };

  useEffect(() => {
    initData();
  }, []);

  // 钱包数据
  useCachePriorityData<IAssetWalletInfo>(COMMON_CACHE_PRIORTY_KEYS.walletInfo);

  // 获取总资产详情
  useCachePriorityData<IAssetWalletInfo>(COMMON_CACHE_PRIORTY_KEYS.assetsLineData);

  const initData = () => {
    fetchAssetsCurrencies();
    fetchAssetsRevenues(pnlStartDate, pnlEndDate);
  };

  // 获取币种分布信息
  const fetchAssetsCurrencies = () => {
    setAllocationLoading(true);
    const params = {
      topNumber: 6
    };
    getAssetsCurrencies(params)
      .then((res) => {
        if (res?.code == "0") {
          const arr = res?.data;
          let arrFormat: any = [];
          arr &&
            arr.length > 0 &&
            arr.map((item: IAssetCurrencies) => {
              let newItem = item;
              newItem.currency = convertUSD2USDC(newItem.currency);
              if (item.currency === "other") {
                newItem.currency = "Other";
                newItem = omit(newItem, [
                  "spotQuantity",
                  "yieldQuantity",
                  "structuredAmount",
                  "strategyQuantity",
                  "fundQuantity",
                  "ventureQuantity",
                  "coldWalletQuantity"
                ]);
              } else {
                newItem = omitBy(newItem, (e: number | string) => e == 0);
              }
              arrFormat.push(newItem);
            });
          setCurrenciesArr(arrFormat);
        }
      })
      .finally(() => {
        setAllocationLoading(false);
      });
  };
  // 获取收益曲线
  const fetchAssetsRevenues = (start: string, end: string) => {
    setPNLLoading(true);
    const params = {
      from: start,
      to: end
    };
    getAssetsRevenues(params).then((res) => {
      if (res?.code == "0") {
        if (res?.data?.revenues) {
          setDailyPNLInfo(changePNLDataFormat(res?.data?.revenues));
          setDailyLastPNL(Object.values(res.data.revenues).pop());
          setPNLLoading(false);
        }
        if (res?.data?.totalRevenues) {
          setTotalPNLInfo(changePNLDataFormat(res?.data?.totalRevenues));
          setTotalLastPNL(Object.values(res?.data?.totalRevenues).pop());
          setPNLLoading(false);
        }
      } else {
        setPNLLoading(false);
      }
    });
  };

  // 收益曲线时间间隔
  const handleChangePnlDate = (interval: string) => {
    fetchAssetsRevenues(
      dayjs().subtract(Number(interval), "day").format("YYYY-MM-DD"),
      dayjs().subtract(1, "day").format("YYYY-MM-DD")
    );
    setPnlDateInterval(interval);
    clearDatePicker();

    reportEvent({ moduleName: `${GA_EVENT_NAME.assets.changePnlDate}${interval}` });
  };

  // 选择自定义时间
  const handleChangeCustomDate = (dateArr: Array<string>) => {
    fetchAssetsRevenues(dateArr[0], dateArr[1]);
    setPnlDateInterval("custom");

    reportEvent({
      moduleName: GA_EVENT_NAME.assets.changeCustomDate,
      detailParams: {
        startDate: dateArr[0],
        endDate: dateArr[1]
      }
    });
  };

  // 重构收益曲线数据格式
  const changePNLDataFormat = (info) => {
    const arr: any = [];
    Object.keys(info).map((item, index) => {
      arr.push({
        assetDate: item,
        asset: Object.values(info)[index]
          ? Number(decimalPointNoMoreX(Object.values(info)[index], 2))
          : 0
      });
    });
    return arr;
  };

  // 点击快选时间按钮 清空自定义日期
  const datePickerRef = React.useRef<{ delValue: () => void }>();
  const clearDatePicker = () => {
    datePickerRef.current?.delValue();
  };
  return (
    <section className={`${styles.assets} ` + `customer`}>
      <div className="customerTop">
        <Row className="top">
          <Col span={24}>
            <div className={styles.titlecontent}>
              <div>
                <span className="title">{title}</span>
                <div
                  style={{ display: "inline-block" }}
                  onClick={() => {
                    setAmountInfo(!isShowAmount);
                  }}>
                  {isShowAmount ? (
                    <EyeOutlined className={styles.hideIcon} />
                  ) : (
                    <EyeInvisibleOutlined className={styles.hideIcon} />
                  )}
                </div>
              </div>
              {showEntrance ? (
                <Button
                  className={styles.downloadBtn}
                  key="ok"
                  type="ghost"
                  onClick={() => {
                    router.push(DOWNLOAD_BILL_PATH);
                  }}>
                  {intl["router.title.download.bill"]}
                </Button>
              ) : null}
            </div>
          </Col>
        </Row>
      </div>
      <div className="section">
        <div className={styles.myAssets}>
          <div className={styles.title}>
            <span>{totalAssetsTitle}</span>
            <Tooltip title={tipsReal}>
              <InfoCircleOutlined style={{ paddingLeft: "10px", fontSize: "12px" }} />
            </Tooltip>
          </div>
          <div className={styles.charts}>
            <div className={styles.assetDisbutionChart}>
              <TotalAssetsChart
                totalNumber={walletData?.totalAmount}
                totalText={totalAssets}
                showAssets={isShowAmount}
                showEyeIcon={false}
                pieList={pieList}
                width={235}
                color={Object.values(colorArr)}
              />
            </div>
            <div className={styles.assetDisbutionProgess}>
              <AssetsProgress progressList={progressList} showAssets={isShowAmount} />
            </div>
            {typeof window != "undefined" && (
              <div className={styles.pnlContent}>
                <div className={styles.pnlItem}>
                  <PNLiquidChart
                    themeColor={
                      walletData?.totalRevenue
                        ? getPNLColor(walletData?.totalRevenue)
                        : getPNLColor(0)
                    }
                    content="CUM"
                    width={56}
                    height={56}
                  />
                  <div className={styles.pnlText}>
                    <div className={styles.pnlTitle}>
                      <span>{totalPNL}</span>
                      <Tooltip title={tipsYesterday}>
                        <InfoCircleOutlined style={{ paddingLeft: "10px", fontSize: "12px" }} />
                      </Tooltip>
                    </div>
                    {isShowAmount ? (
                      <div
                        className={styles.pnlValue}
                        style={{ color: getPNLColor(walletData?.totalRevenue) }}>
                        {walletData?.totalRevenue > 0 && "+"}
                        {numberToThousands(decimalPointNoMoreX(walletData?.totalRevenue, 2))}
                      </div>
                    ) : (
                      "****"
                    )}
                  </div>
                </div>
                <div className={styles.pnlItem}>
                  <PNLiquidChart
                    themeColor={
                      walletData?.todayRevenue
                        ? getPNLColor(walletData?.todayRevenue)
                        : getPNLColor(0)
                    }
                    content="Today"
                    width={56}
                    height={56}
                  />
                  <div className={styles.pnlText}>
                    <div className={styles.pnlTitle}>
                      <span>{todayPNL}</span>
                      <Tooltip
                        title={
                          <>
                            <span>{tipsReal}</span>
                            <br />
                            <span>{noticeContentSecond}</span>
                          </>
                        }>
                        <InfoCircleOutlined style={{ paddingLeft: "10px", fontSize: "12px" }} />
                      </Tooltip>
                    </div>
                    {isShowAmount ? (
                      <div
                        className={styles.pnlValue}
                        style={{ color: getPNLColor(walletData?.todayRevenue) }}>
                        {walletData?.todayRevenue !== undefined &&
                          walletData?.todayRevenue > 0 &&
                          "+"}
                        {walletData?.todayRevenue !== undefined
                          ? numberToThousands(decimalPointNoMoreX(walletData?.todayRevenue, 2))
                          : "--"}
                      </div>
                    ) : (
                      "****"
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.middleContent}>
          <div className={styles.assetSituational}>
            {walletData?.totalAmount ? (
              <TotalAssetsLine lineHeight={282} showTips totalAmount={walletData?.totalAmount} />
            ) : null}
          </div>
          <div className={styles.circleAssets}>
            <Spin spinning={allocationLoading}>
              <p className={styles.title}>
                <span>{allocationTitle}</span>
                <Tooltip
                  title={
                    <>
                      <p>{tipsReal}</p>
                      <p>{allocationTitleTips}</p>
                    </>
                  }>
                  <InfoCircleOutlined style={{ paddingLeft: "10px", fontSize: "12px" }} />
                </Tooltip>
              </p>
              {currenciesArr && currenciesArr.length > 0 ? (
                <div className={styles.charts}>
                  <AssetsAllocationChart
                    list={currenciesArr ?? []}
                    allocationName={allocationName}
                    width={500}
                    height={300}
                    radius={0.65}
                    notRemoveInteraction
                    color={currenciesColorArr}
                    otherText={otherText}
                  />
                </div>
              ) : (
                <EmptyCharts />
              )}
            </Spin>
          </div>
        </div>
        <Spin spinning={PNLLoading}>
          <div className={styles.pnlCharts}>
            <div className={styles.topArea}>
              <div className={styles.pnlTitle}>{PNLAllocationTitle}</div>
              <div className={styles.pnlDateContent}>
                <div
                  className={`${styles.pnlDateItem} ${pnlDateInterval === "7" && styles.pnlActive}`}
                  onClick={() => handleChangePnlDate("7")}>
                  7 {PNLAllocationDays}
                </div>
                <div
                  className={`${styles.pnlDateItem} ${pnlDateInterval === "30" && styles.pnlActive}`}
                  onClick={() => handleChangePnlDate("30")}>
                  30 {PNLAllocationDays}
                </div>
                <span className={styles.customize}>{PNLAllocationCustom}: </span>
                <div className={styles.datePicker}>
                  <DateRangePicker
                    ref={datePickerRef}
                    optionalRange={89}
                    changeCustomDate={(dateArr) => {
                      handleChangeCustomDate(dateArr);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={styles.pnlChartsContent}>
              <div className={styles.pnlChartsItem}>
                <div className={styles.pnlChartsItemTop}>
                  <div className={styles.pnlChartsItemTitle}>
                    <span>{PNLAllocationDaily}</span>
                    <Tooltip title={tipsYesterday}>
                      <InfoCircleOutlined style={{ paddingLeft: "10px", fontSize: "12px" }} />
                    </Tooltip>
                  </div>
                  {isShowAmount ? (
                    <div
                      className={styles.pnlChartsItemUSD}
                      style={{ color: getPNLColor(dailyLastPNL) }}>
                      {Number(dailyLastPNL) > 0 && "+"}
                      {numberToThousands(decimalPointNoMoreX(dailyLastPNL, 2))}
                    </div>
                  ) : (
                    <div className={styles.pnlChartsItemUSD}>****</div>
                  )}
                </div>
                {dailyPNLInfo ? (
                  <div className={styles.pnlChartsContainer}>
                    {dailyPNLInfo?.length >= 30 ? (
                      <LineChart
                        xField="assetDate"
                        yField="asset"
                        list={dailyPNLInfo}
                        xTickCount={8}
                        themeColor="#3987CF"
                        areaStartColor="#3987CF"
                        areaEndColor="#1F212F"
                        yAxisFormatter={(text: string) => {
                          return numberToThousands(decimalPointNoMoreX(text, 2));
                        }}
                        tooltipValueFormatter={(text) => {
                          return ` ${numberToThousands(decimalPointNoMoreX(text, 2))}`;
                        }}
                      />
                    ) : (
                      <ColumnChart
                        xField="assetDate"
                        yField="asset"
                        xTickCount={8}
                        list={dailyPNLInfo}
                        yAxisFormatter={(text: string) => {
                          return numberToThousands(decimalPointNoMoreX(text, 2));
                        }}
                        tooltipValueFormatter={(text) => {
                          return ` ${numberToThousands(decimalPointNoMoreX(text, 2))}`;
                        }}
                      />
                    )}
                  </div>
                ) : null}
              </div>
              <div className={styles.pnlChartsItem}>
                <div className={styles.pnlChartsItemTop}>
                  <div className={styles.pnlChartsItemTitle}>
                    <span>{PNLAllocationTotal}</span>
                    <Tooltip title={tipsYesterday}>
                      <InfoCircleOutlined style={{ paddingLeft: "10px", fontSize: "12px" }} />
                    </Tooltip>
                  </div>
                  {isShowAmount ? (
                    <div
                      className={styles.pnlChartsItemUSD}
                      style={{ color: getPNLColor(totalLastPNL) }}>
                      {Number(totalLastPNL) > 0 && "+"}
                      {numberToThousands(decimalPointNoMoreX(totalLastPNL, 2))}
                    </div>
                  ) : (
                    <div className={styles.pnlChartsItemUSD}>****</div>
                  )}
                </div>
                {totalPNLInfo ? (
                  <div className={styles.pnlChartsContainer}>
                    <LineChart
                      xField="assetDate"
                      yField="asset"
                      xTickCount={8}
                      list={totalPNLInfo}
                      themeColor={getPNLColor(totalLastPNL)}
                      areaStartColor={getPNLColor(totalLastPNL)}
                      areaEndColor="#1F212F"
                      yAxisFormatter={(text: string) => {
                        return numberToThousands(decimalPointNoMoreX(text, 2));
                      }}
                      tooltipValueFormatter={(text) => {
                        return `${numberToThousands(decimalPointNoMoreX(text, 2))}`;
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Spin>
        <div className={styles.bottomNotice}>
          <div className={styles.bottomNoticeTitle}>{noticeTitle}</div>
          <p>{noticeContentFirst}</p>
          <p>{noticeContentSecond}</p>
        </div>
      </div>
    </section>
  );
}

export const PageAssets = Assets;
