import React, { useEffect, useState } from "react";
import { partial } from "lodash-es";
import { useRouter } from "next/router";
import { Table, Row, Col, Spin } from "antd";
import styles from "@aspen/theme/History.module.less";
import {
  transformTime,
  decimalPointNoMoreX,
  convertUSD2USDC,
  getQueryValue,
  numberToThousands,
  i18nUtil
} from "@aspen/libs";
import { WithEmptyContent } from "@aspen/ui";
import { accountHistory } from "@aspen/services";
import { BUSINESS_TYPE } from "@aspen/model";
const AccountHistory = () => {
  const intl = i18nUtil.t();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [offset, setOffset] = useState(0);

  const router = useRouter();
  const currency = router.query?.currency?.toString() ?? getQueryValue("currency");

  const loadMore = intl["button.loadMore"];

  useEffect(() => {
    fetchData(offset);
  }, []);

  const columns = [
    {
      title: intl["column.date"],
      dataIndex: "created",
      render: (value: number) => <span>{transformTime(value)}</span>
    },
    {
      title: intl["column.type"],
      dataIndex: "businessType",
      render: (type) => (
        <div style={{ textTransform: "capitalize" }}>
          {intl[BUSINESS_TYPE[type]] || BUSINESS_TYPE[type]}
        </div>
      )
    },
    {
      title: intl["column.total"],
      dataIndex: "coldMoney",
      render: (coldMoney, { operateType }) => {
        let symbol = "";
        if (operateType === 4 || operateType === 6) {
          symbol = "+";
        } else if (operateType === 5 || operateType === 3) {
          symbol = "-";
        }
        return (
          <div style={{ textTransform: "capitalize" }}>
            {symbol}
            {numberToThousands(decimalPointNoMoreX(coldMoney))}
          </div>
        );
      }
    }
  ];

  const fetchData = (offset) => {
    setLoading(true);
    const params = {
      currency,
      offset,
      limit: 10
    };
    accountHistory(params)
      .then((res) => {
        if (res?.code == "0") {
          const { rows = [], offset } = res?.data;
          setDataSource(showLoadMore ? dataSource.concat(rows) : rows);
          setShowLoadMore(rows.length < 10 ? false : true);
          setOffset(offset);
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <section className={styles.history + " " + "customer"}>
      <div className="customerTop">
        <Row className="top">
          <Col span={24}>
            <p className="title">
              {`${intl["history.spot.account.title"]}-${convertUSD2USDC(currency?.toString())}`}
            </p>
          </Col>
        </Row>
      </div>
      <div className={styles.tableWrap}>
        <div className="section" style={{ width: "100%", marginTop: 0 }}>
          <div className={styles.history} style={{ marginTop: 0 }}>
            <Spin spinning={loading}>
              <div className={styles.historyArea}>
                <WithEmptyContent showEmpty={true}>
                  <Table
                    className={styles.table}
                    bordered={false}
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                  />
                </WithEmptyContent>

                {showLoadMore && (
                  <div className={styles.loadMore} onClick={partial(fetchData, offset + 10)}>
                    {loadMore}
                  </div>
                )}
              </div>
            </Spin>
          </div>
        </div>
      </div>
    </section>
  );
};

export const PageAccountHistory = AccountHistory;
