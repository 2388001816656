import React, { useLayoutEffect, useState } from "react";
import { Button, Col, Row, Spin } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import clsx from "clsx";
import styles from "@aspen/theme/Intelligence.module.less";
import type { IIntelligenceIResponse, INews, IReqIntelligenceNews } from "@aspen/model";
import { NEWSTYPE } from "@aspen/model";
import { NewsMenu, ResearchItem } from "@aspen/widgets";
import { fecthIntelligenceNews } from "@aspen/services";
import { GA_EVENT_NAME, i18nUtil, reportEvent } from "@aspen/libs";

interface IProps {
  data: IIntelligenceIResponse;
}

const Research: React.FC<IProps> = ({ data }) => {
  const intl = i18nUtil.t();

  const [researchList, setResearchList] = useState<INews[]>([]);
  const [nextToken, setNextToken] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useLayoutEffect(() => {
    setNextToken(data?.nextToken);
    setResearchList(data?.rows);
  }, []);

  // 获取研报列表
  const getResearchList = () => {
    setLoading(true);
    const params: IReqIntelligenceNews = {
      limit: 15,
      action: "all",
      type: NEWSTYPE.research, // 研报
      nextToken
    };

    fecthIntelligenceNews(params)
      .then((res) => {
        setNextToken(res?.data?.nextToken);
        if (res?.data?.rows?.length > 0) {
          setResearchList(
            nextToken ? researchList.concat(res?.data?.rows) : (res?.data?.rows ?? [])
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleLoadMore = () => {
    getResearchList();
    reportEvent({ moduleName: GA_EVENT_NAME.intelligence.research.list_loadMore });
  };

  return (
    <section className={clsx(styles.intelligence, styles.research)}>
      <Row className={styles.row}>
        <Col>
          <NewsMenu />
        </Col>
        <Col flex="auto">
          {/* Research Reports List */}
          <div className={styles.researchContainer}>
            <p className={styles.researchTitle}>{intl["intelligence.research"]}</p>
            <Spin spinning={loading}>
              <Row className={styles.researchList} gutter={23}>
                {researchList?.length > 0
                  ? researchList.map((item) => (
                      <Col span={8} key={item.id}>
                        <ResearchItem key={item.id} data={item} />
                      </Col>
                    ))
                  : loading || <p className={styles.emptyText}>{intl?.["empty.list"]}</p>}
                {/* 分页 */}
                {!loading &&
                  researchList?.length > 0 &&
                  (nextToken && researchList.length >= 15 ? (
                    <div className={styles.paginationMask}>
                      <Button
                        className={styles.loadMore}
                        icon={<SyncOutlined />}
                        onClick={handleLoadMore}>
                        {intl["intelligence.loadMore"]}
                      </Button>
                    </div>
                  ) : (
                    <>
                      <p className={styles.noMoreData} style={{ margin: "-10px auto 10px" }}>
                        {intl["noMoreData"]}
                      </p>
                      {/* 解决分页的占位问题 */}
                      <div style={{ height: 450 }} />
                    </>
                  ))}
              </Row>
            </Spin>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export const PageResearch = React.memo(Research);
