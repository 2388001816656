import React, { useEffect, useState } from "react";
import { Table, Row, Col, Spin } from "antd";
import styles from "@aspen/theme/Wallet.module.less";
import { transformTime, convertUSD2USDC, i18nUtil } from "@aspen/libs";
import { OPERARTOR_TYPE, WITHDRAW_WHITELIST_STATUS } from "@aspen/model";
import { getWithdrawWhiteListHistory } from "@aspen/services";
import { WithEmptyContent } from "@aspen/ui";

const WhitelistHistory = () => {
  const intl = i18nUtil.t();
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [nextToken, setNextToken] = useState<string | null>(null);

  const loadMore = intl["button.loadMore"];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    const params = { limit: 10, nextToken: nextToken };
    getWithdrawWhiteListHistory(params)
      .then((res) => {
        if (res?.code == "0") {
          setDataSource(dataSource.length > 0 ? dataSource.concat(res.data?.rows) : res.data?.rows);
          setNextToken(res.data?.nextToken);
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      title: intl["column.date"],
      dataIndex: "createTime",
      render: (value: number) => <span>{transformTime(value)}</span>
    },
    {
      title: intl["column.addressLable"],
      dataIndex: "remark",
      ellipsis: true,
      width: 200,
      render: (value: string) => (
        <span>{value ? value : intl["wallet.withdraw.select.address.item.title"]}</span>
      )
    },
    {
      title: intl["column.denomination"],
      dataIndex: "currency",
      render: (value: string) => <span>{convertUSD2USDC(value)}</span>
    },
    {
      title: intl["column.address"],
      dataIndex: "address",
      width: 175,
      textWrap: "word-break"
    },
    {
      title: intl["column.network"],
      dataIndex: "chainName"
    },
    {
      title: intl["column.addressOrigin"],
      dataIndex: "source"
    },
    {
      title: intl["column.operator"],
      dataIndex: "applyRole", //操作人类别  0本人操作，1子账号，2代理商
      render: (value: number) => <span>{intl[OPERARTOR_TYPE[value]] || "--"}</span>
    },
    {
      title: intl["column.status"],
      dataIndex: "approveStatus",
      render: (value: string) => <span>{intl[WITHDRAW_WHITELIST_STATUS[value]]}</span>
    }
  ];

  return (
    <section className={styles.wallet + " " + "customer"}>
      <div className="customerTop">
        <Row className="top">
          <Col span={24}>
            <p className="title">{intl["router.title.wallet.withdraw.whitelist.history"]}</p>
          </Col>
        </Row>
      </div>
      <div className={styles.whitelistHistory}>
        <div className="section" style={{ width: "100%", marginTop: 0 }}>
          <div className={styles.history} style={{ marginTop: 0 }}>
            <Spin spinning={loading}>
              <WithEmptyContent showEmpty={true}>
                <Table
                  className={styles.table}
                  rowKey={(record: any) => record?.id}
                  bordered={false}
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                />
              </WithEmptyContent>

              {nextToken && dataSource.length >= 10 && (
                <div className={styles.loadMore} onClick={fetchData}>
                  {loadMore}
                </div>
              )}
            </Spin>
          </div>
        </div>
      </div>
    </section>
  );
};

export const PageWhitelistHistory = WhitelistHistory;
