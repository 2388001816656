import React from "react";
import { Spin } from "antd";
import clsx from "clsx";
import styles from "@aspen/theme/Strategy.module.less";
import { COMMON_CACHE_PRIORTY_KEYS } from "@aspen/model";
import { i18nUtil } from "@aspen/libs";
import { useCacheDataPriority } from "@aspen/hooks";
import { Disclaimer } from "@aspen/widgets";
import VentureItem from "@aspen/widgets/venture/VentureItem/VentureItem";

const Fund = () => {
  const intl = i18nUtil.t();
  const title = intl["strategies.title"];

  const { data, isLoading } = useCacheDataPriority({
    key: COMMON_CACHE_PRIORTY_KEYS.funds,
    params: {
      limit: 100
    }
  });
  return (
    <div className={clsx(styles.savings, "customer")}>
      <div className="customerTop">
        <div className="top">
          <p className="title">{title}</p>
        </div>
      </div>
      <Spin spinning={isLoading}>
        <div className="section">
          <div className={styles.strategy} style={{ marginTop: "34px" }}>
            <div className={styles.strategyList}>
              {data
                ? data?.rows?.map((item, index) => (
                    <div className={styles.strategyListItem} key={index}>
                      <VentureItem data={item} key={item.id} productType="Fund" />
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </Spin>

      <Disclaimer />
    </div>
  );
};

export const PageFund = React.memo(Fund);
