import React, { useEffect, useState } from "react";
import type { INews, IReqIntelligenceArticlesRead } from "@aspen/model";
import { NEWSTYPE } from "@aspen/model";
import { articlesRead } from "@aspen/services";
import { HeadRender } from "@aspen/ui";
import { DetailInfo } from "@aspen/widgets";

interface IProps {
  detailData: INews;
}

const NoticeDetail: React.FC<IProps> = (props: IProps) => {
  const [detailContent, setDetailContent] = useState<INews>(props.detailData);

  useEffect(() => {
    if (props.detailData) {
      setDetailContent(props.detailData);
      getNoticeReaded(props.detailData);
    }
  }, [props.detailData]);

  // 消息已读请求上报
  const getNoticeReaded = (data: INews) => {
    if (!data?.hasRead) {
      const params: IReqIntelligenceArticlesRead = {
        type: NEWSTYPE.notice,
        ids: data?.id
      };
      articlesRead(params);
    }
  };

  return (
    <>
      {/* 新闻详情单独处理 */}
      <HeadRender
        title={detailContent?.title}
        description={detailContent?.summary}
        image={detailContent?.images?.[0]}
      />

      {detailContent ? (
        <DetailInfo type="notice" dateType="date" detailInfo={detailContent} />
      ) : null}
    </>
  );
};
export const PageNoticeDetail = React.memo(NoticeDetail);
