import React from "react";
import styles from "@aspen/theme/Login.module.less";

export const PagePolicy = () => {
  return (
    <div className={styles.privatePolicy}>
      <div className={styles.title}>Aspen Digital Privacy Policy</div>
      <div className={styles.content}>
        <div>
          At Aspen Digital, your privacy is very important to us. Aspen Digital (“Aspen Digital”,
          “we”, or “us”) is committed to protecting the privacy of our customers, and this policy
          applies to the online products and services offered by Aspen Digital (collectively, “Aspen
          Digital Services”) and shall form part of the User Service Agreement.
        </div>
        <div className={styles.space}>
          This Privacy Policy describes how Aspen Digital and its affiliates (referred to as “we”,
          “our” or “us” in this policy) will collect, make use of and share (i.e. “process”) your
          personal data in connection with the Aspen Digital websites, mobile apps and services.
        </div>
        <div className={styles.conTitle1}>What personal data do we process?</div>
        We process personal data about you when you interact with us, our websites, our apps or our
        services. This includes:
        <ul className={styles.space}>
          <li>
            your name, email address, username, password and other login and/or security details
            (e.g. app passcode, two-factor authentication token seed record, public PGP key), and
            login records;
          </li>
          <li>your payment details, including your wallet address(es);</li>
          <li>
            your account and portfolio details, such as live and historical instructions, trades and
            balances;
          </li>
          <li>
            your account preferences;any personal data you submit for ID verification purposes, and
            your self-reported location (including browser location and other proof of address
            information) plus the geolocation of the IP address you connect from;
          </li>
          <li>
            your marketing and other communication preferences, and a record of any consents you
            have given us;
          </li>
          <li>
            information related to the browser or device you use to access our website or apps, as
            well as data that tells us which features of the website or app are popular, or suffer
            from issues we need to fix;
          </li>
          <li>
            the content and details (e.g. date) of messages you send us (e.g. customer support
            queries); and
          </li>
          <li>customer service notes and other records.</li>

          <div className={styles.space}>
            We will aim to mark data fields as optional or mandatory when collecting personal data
            from you via forms. Note, in particular, that to create an account, engage in
            transactions, and where necessary, prove your identity, the provision of personal data
            is typically mandatory: if relevant data is not provided, then we will not be able to do
            these things and provide the services you expect.
          </div>
          <div className={styles.space}>
            We do not collect fingerprints, facial recognition data, or other biometrics, other than
            photos and/or videos of yourself and/or your photo ID that you submit during our ID
            verification processes and which may be processed by facial recognition software for
            that purpose. Where you enable biometric security (such as fingerprint or Face ID
            login), your biometrics will be handled by your device, not by us.
          </div>
        </ul>
        <div className={styles.conTitle1}>
          How do we use this personal data, and what is the legal basis for this use?
        </div>
        <div>We process this personal data for the following purposes:</div>
        <div className={styles.space}>
          To fulfill (or take steps linked to) a service agreement with you. This includes:
        </div>
        <ul>
          <li>creating your account;</li>
          <li>verifying your identity;</li>
          <li>taking deposits and fees, and paying out withdrawals;</li>
          <li>allowing you to make trades, maintaining your account and trading history;</li>
          <li>communicating with you; and</li>
          <li>providing customer services;</li>
          <li>
            As required by the Company or third parties to conduct their business and pursue their
            other legitimate interests, in particular:
          </li>
          <li>to provide services you have requested;</li>
          <li>
            to monitor, improve and protect the services on our website and mobile apps, in
            particular by looking at how they are used, testing alternatives (e.g. by “A/B testing”,
            and running “beta” version trials), and by learning from feedback and comments you
            provide;
          </li>
          <li>to personalise our website, apps and services;</li>
          <li>
            by publishing de-identified records of market data, including trading records for third
            party monitoring and research purposes;
          </li>
          <li>
            to monitor customer accounts to prevent, investigate and/or report misconduct such as
            spam, misrepresentation, security incidents, market manipulation or crime (such as
            fraud), in accordance with applicable law, and to cooperate with authorities seeking to
            do the same;
          </li>
          <li>to investigate any complaints received from you or from others;</li>
          <li>
            in connection with legal claims, compliance, regulatory or investigative purposes
            (including disclosure in connection with legal process or litigation); and
          </li>
          <li>to invite individuals to take part in market research and beta tests.</li>
          <li>Where you give us consent (so far as that consent is required):</li>
          <li>
            we will send you direct marketing in relation to our relevant products and services, or
            other products and services provided by us and carefully selected partners;
          </li>
          <li>
            we place cookies, monitor email engagement, and use other similar technologies in
            accordance with our Cookies Notice and the information provided to you when those
            technologies are used;
          </li>
          <li>
            on other occasions where we ask you for consent, we will use the data for the purpose
            which we explain at that time.
          </li>
          <li>
            For purposes which are required by law, in particular: in response to requests by
            relevant courts and public authorities, such as those conducting an investigation.
          </li>
        </ul>
        <div className={styles.conTitle1}>
          Fully-automated decision-making that could have significant effects on you
        </div>
        <div className={styles.space}>
          As the service documentation on our site and our terms of service explain, our Mobile App
          applies certain automatic processes based on the resources on your account.
        </div>
        <div className={styles.space}>
          Other significant automated decision-making that uses your personal data may also be
          employed, to protect accounts and to uphold our terms of service. In particular, if you
          attempt to access our services from a jurisdiction in respect of which our services are
          restricted, your account may be automatically restricted. In addition, if the information
          provided during any identity verification or onboarding process (including self-reported
          information, information that is derived from your ID document, proof of address, or any
          other document provided by you, or your IP address or browser location information)
          suggests that you may be in breach of our terms of service or may have provided false or
          inaccurate information, your account may be automatically restricted. You may contact
          customer support to review any restrictions applied to your account; as part of this, you
          may be asked to provide additional proof of identity and/or location, or other
          documentation with respect to your identity and/or account. During an account lockout, you
          may be unable to access your account, make any trades, or given or amend any instructions.
        </div>
        <div className={styles.conTitle1}>
          Withdrawing consent or otherwise objecting to direct marketing
        </div>
        Wherever we rely on your consent, you will always be able to withdraw that consent, although
        we may have other legal grounds for processing your data for other purposes, such as those
        set out above. In some cases, we are able to send or display marketing without your consent.
        You have an absolute right to opt-out of direct marketing, or profiling we carry out for
        direct marketing, at any time. You can do this by following the instructions in the
        communication where this is an electronic message, changing your account settings, or by
        contacting us using the details set out below.
        <div className={styles.conTitle1}>Who will we share this data with, and where?</div>
        <div>
          We will share your personal data with our affiliates so they can help deliver and improve
          our services, run our business, and comply with our legal obligations and related third
          party requests.
        </div>
        <div className={styles.space}>
          Personal data may be shared with courts or public authorities if required as described
          above, mandated by law or regulation, or required for the legal protection of our or third
          party legitimate interests, in compliance with applicable laws and regulations, and
          relevant / competent public authorities’ requests.
        </div>
        <div className={styles.space}>
          Personal data will also be accessed by employees or contractors, or shared with third
          party service providers, who will process it on our behalf for the purposes identified
          above. In particular, we use third party database hosting (primarily Aliyun); web and app
          analytics (i.e. Google Analytics); ID verification (primarily Onfido); and customer
          services and support (primarily Hubspot).
        </div>
        <div className={styles.space}>
          In the event that the business is sold or integrated with another business, your details
          will be disclosed to our advisers and any prospective purchaser’s advisers, and to the new
          owners of the business.
        </div>
        <div className={styles.conTitle1}>Your rights</div>
        <div>
          Depending on applicable law (in particular, whether the laws of the UK or EEA countries
          apply), you may have the right to ask us for a copy of personal data about you; to correct
          or delete that personal data; restrict the processing of that personal data; and to obtain
          a copy of personal data about you that you provided to us (in connection with our
          agreement with you, or with your consent), in a structured, machine readable format, and
          to ask us to port this data to (i.e. share that data with) another organization.
        </div>
        <div className={styles.space}>
          In addition, applicable law may provide the right to object to the processing of personal
          data about you, in some circumstances (in particular, where we don’t have to process the
          data to meet a contractual or other legal requirement, or where we are using the data for
          direct marketing).
        </div>
        <div className={styles.space}>
          You have the right to appeal the result of significant fully automated decisions. This
          should be done by emailing us within 5 working days from the date of the decision, which
          we will then review.
        </div>
        <div className={styles.space}>
          If these rights apply, they may however be limited, for example if fulfilling your request
          would reveal personal data about another person, would infringe the rights of another
          person or legal entity (including our rights), or if you ask us to delete or change data
          which we are required by law to keep (or have other compelling legitimate interests in
          keeping). We will inform you of relevant exemptions we rely upon when responding to any
          request you make.
        </div>
        <div className={styles.space}>
          To exercise any of these rights, or to obtain other information, you can get in touch
          using the details set out below. If you have unresolved concerns, you typically have the
          right to complain to regulators, depending on applicable law. For example, in the EEA,
          your complaint can likely be taken to data protection authorities where you live, work or
          where you believe a breach may have occurred.
        </div>
        <div className={styles.conTitle1}>How long does the Company retain data?</div>
        <div>
          Where we process personal data in connection with performing an agreement with you, we
          keep the data for 6 years from your last interaction with us.
        </div>
        <div className={styles.space}>
          Where we process personal data for marketing purposes or with your consent, we process the
          data until you ask us to stop and for a short period after this (to allow us to implement
          your requests). We also keep a record of that request indefinitely, so that we can
          continue to respect your request in future.
        </div>
        <div className={styles.space}>
          Where we process personal data for site security purposes, we retain it for 3 years.
        </div>
        <div className={styles.space}>
          Longer retention periods may apply, such as where ongoing access to records continues to
          be important to our defence of legal claims or where we are required by law or regulation
          to retain information for specific periods.
        </div>
        <div className={styles.conTitle1}>Cookies and Web Beacons</div>
        <div>
          Aspen Digital uses cookies on our website and web beacons in some emails. Cookies are
          small text files and web beacons are small graphic images. They are downloaded to your
          device when you visit a website or receive certain emails, unless you have set your
          browser to reject them.
        </div>
        <div className={styles.space}>
          We use cookies to remember your preferences and improve your overall experience of our
          site. We use web beacons to track the actions of individuals (such as email recipients)
          and measure the success and response rates of our marketing campaigns. In particular, we
          use the following:
        </div>
        <li className={styles.space}>Session Cookies</li>
        <div>
          These enable you to carry out some essential functions on our sites, such as maintaining
          log-in details for the session or a transaction. They also help by minimizing the need to
          transfer information across the internet. They are not stored on your computer and expire
          when you terminate your browser session or logout of certain areas.
        </div>
        <li className={styles.space}>Analytics</li>
        <div>
          We like to keep track of what pages and links are popular and which ones don’t get used so
          much to help us keep our site relevant and up to date. It’s also very useful to be able to
          identify trends of how people navigate our site and help us provide a more friendly
          solution.
        </div>
        <li className={styles.space}>Third-party cookies</li>
        <div>
          Analytics tracking (and most web tracking software) use cookies in order to provide
          meaningful reports about site visitors. However, analytics cookies do not collect personal
          data about website visitors.
        </div>
        <div className={styles.conTitle1}>Controlling these technologies</div>
        <div>
          If you want to delete any cookies, please check your browser or device settings (and help
          pages) for instructions on how to delete them. Your browser or device may also offer
          tracking controls for things other than cookies, such as beacons and tags.
        </div>
        <div className={styles.space}>
          Please note that by deleting our cookies or disabling future cookies, in particular the
          “strictly necessary” cookies described above, you may not be able to access certain areas
          or features of our site.
        </div>
        <div className={styles.conTitle1}>External links</div>
        Although our website and mobile apps only look to include quality, safe and relevant
        external links, users should always adopt a policy of caution before clicking any links to
        non-Company websites or apps. We cannot control, guarantee or verify their contents. They
        will have their own policies and practices, for example with regard to privacy and personal
        data, and you should acquaint yourselves with those before further engaging with those third
        party websites or apps.
        <div className={styles.conTitle1}>Changes to this Policy</div>
        We may revise this Privacy Policy from time to time. If we make a change to this notice that
        we consider material, we will take steps to notify users by a notice on the website and/or
        mobile app. Your continued use of the Aspen Digital websites, mobile apps and services will
        be subject to the updated Privacy Policy.
        <div className={styles.conTitle1}>Contact</div>
        You can contact us in relation to data protection and this privacy notice by emailing
        legal@aspendigital.co.
      </div>
    </div>
  );
};
