import React from "react";
import { withRouter } from "next/router";
import styles from "@aspen/theme/Savings.module.less";
import { Col, Divider, Row, Spin } from "antd";
import {
  ADGM_BRAND,
  type IFlexibleProducts,
  type IInjectProps,
  type TYieldTabs
} from "@aspen/model";
import {
  GA_EVENT_NAME,
  getComplianceTag,
  i18nUtil,
  reportEvent,
  saveComplianceTag,
  systemDetection,
  YIELD_TYPES
} from "@aspen/libs";
import { Compliance, Disclaimer, ModalCompliance } from "@aspen/ui";
import {
  CacheFlexibleSavingsTable,
  InvestmentPlan,
  NewFixedYield,
  SavingsTableTabs,
  StakingYield
} from "@aspen/widgets";

interface IProps extends IInjectProps {
  showToProfileBtn?: boolean;
}

interface IState {
  yieldProducts: IFlexibleProducts[];
  loading: boolean;
  currentTab: TYieldTabs;
  showCompliance: boolean;
}

class Savings extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      loading: false,
      yieldProducts: [],
      currentTab: (props?.router?.query?.tabType?.toString() as TYieldTabs) ?? YIELD_TYPES.flexible,
      showCompliance: false
    };
  }

  componentDidMount(): void {
    const { router } = this.props;
    const system = systemDetection();
    let tag = getComplianceTag(router.pathname);
    if (!tag && system !== ADGM_BRAND) {
      this._showCompliance(true);
    }
  }

  // 点击切换tab
  handleChangeTabs: (T: TYieldTabs) => void = (value: TYieldTabs) => {
    this.props.router.replace({ query: null });
    this.setState({ currentTab: value });
    reportEvent({
      moduleName: GA_EVENT_NAME.yield.switchYieldList,
      detailParams: { tab: value }
    });
  };

  render(): React.ReactNode {
    const intl = i18nUtil.t();
    const { currentTab, showCompliance } = this.state;
    const { showToProfileBtn } = this.props;
    return (
      <section className={`${styles.savings} customer`}>
        <div className="customerTop">
          <Row className="top">
            <Col span={24}>
              <p className="title">{intl["compliance.yield.title"]}</p>
            </Col>
          </Row>
        </div>
        <Row className="section">
          <Col span={24}>
            {typeof window != "undefined" && (
              <Spin spinning={this.state.loading}>
                <SavingsTableTabs currentTab={currentTab} onChange={this.handleChangeTabs} />
                <div className={styles.list}>
                  {currentTab === YIELD_TYPES.flexible && (
                    <div className={styles.flexibleContent}>
                      <CacheFlexibleSavingsTable showToProfileBtn={showToProfileBtn} />
                    </div>
                  )}
                  {/* {currentTab === YIELD_TYPES.fixed && <FixedYield />} */}
                  {currentTab === YIELD_TYPES.fixed && <NewFixedYield />}
                  {currentTab === YIELD_TYPES.staking && (
                    <StakingYield showToProfileBtn={showToProfileBtn} />
                  )}
                  {currentTab === YIELD_TYPES.DCA && <InvestmentPlan />}
                </div>
              </Spin>
            )}
          </Col>
        </Row>

        {currentTab === YIELD_TYPES.DCA && <Compliance />}
        {currentTab === YIELD_TYPES.staking && <Compliance module="staking" />}
        <Divider />
        <Disclaimer />
        {showCompliance ? (
          <ModalCompliance
            open={showCompliance}
            onConfirm={this._onComplianceOk}
            onHide={this._onComplianceCancel}
          />
        ) : undefined}
      </section>
    );
  }

  _showCompliance = (show: boolean) => {
    this.setState({ showCompliance: show });
  };
  _onComplianceOk = () => {
    const { router } = this.props;
    saveComplianceTag(router.pathname);
    this._showCompliance(false);
  };
  _onComplianceCancel = () => {
    const { router } = this.props;
    this._showCompliance(false);
    router.back();
  };
}

export const PageYield = withRouter(Savings);
