import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spin } from "antd";
import Image from "next/image";
import styles from "@aspen/theme/downBill.module.less";
import { getReportList, downloadReport } from "@aspen/services";
import type { IReportListItem } from "@aspen/model";
import { message, WithEmptyContent } from "@aspen/ui";
import { GA_EVENT_NAME, downLoadFileBlob, i18nUtil, reportEvent } from "@aspen/libs";

import pdfIcon from "@aspen/assets/images/pdf-icon.png";
import noResultsImg from "@aspen/assets/images/no_results.png";
import bg from "@aspen/assets/images/union.png";

const DownloadBill: React.FC = () => {
  const intl = i18nUtil.t();
  const FILE_NAME = intl["download.portfolio.report.filename.suffix"];
  const [loading, setLoading] = useState<boolean>(false);
  const [curBtnIdx, setCurBtnIdx] = useState<number | null>();
  const [btnLoading, setbtnLoading] = useState<boolean>(false);
  const [billList, setBillList] = useState<IReportListItem[]>([]);
  const [curUrl, setCurUrl] = useState<string>("");
  const [showPdf, setShowPdf] = useState<boolean>(false);
  const nextTokenRef = useRef<string>("");
  useEffect(() => {
    _getReportList();
  }, []);
  const _getReportList = (loadMore?: boolean, nextToken?: string) => {
    setLoading(true);
    const params = {
      limit: 12,
      nextToken
    };
    getReportList(params)
      .then((res) => {
        if (res?.code == "0") {
          const { data } = res;
          const { rows = [], nextToken } = data;
          nextTokenRef.current = nextToken;
          if (loadMore) {
            setBillList(billList.concat(rows));
          } else {
            setBillList(data?.rows);
          }
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .catch((err) => {
        message.error(intl?.[err?.msg] ?? err?.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleName = (date: string) => {
    let _date = date?.split("-");
    let nameStr;
    if (_date[0] && _date[1]) {
      nameStr = `${intl["download.portfolio.report"]}-${_date[0]}.${_date[1]}`;
    } else {
      nameStr = intl["download.portfolio.report"];
    }
    return nameStr;
  };
  const _preview = (report: IReportListItem) => {
    reportEvent({
      moduleName: GA_EVENT_NAME.portfolioReport.preview,
      detailParams: {
        date: report?.reportDate
      }
    });
    setCurUrl(report?.pdfUrl);
    setTimeout(() => {
      setShowPdf(true);
    });
  };

  const _download = (report: IReportListItem, index: number) => {
    reportEvent({
      moduleName: GA_EVENT_NAME.portfolioReport.download,
      detailParams: {
        date: report?.reportDate
      }
    });
    setCurBtnIdx(index);
    setbtnLoading(true);
    downloadReport({
      reportDate: report?.reportDate,
      reportUrl: report?.pdfUrl
    })
      .then((res) => {
        let _date = report?.reportDate?.split("-");
        let fileName = "";
        if (_date[0] && _date[1]) {
          fileName = `${FILE_NAME} (${_date[0]}${_date[1]}).pdf`;
        } else {
          fileName = FILE_NAME;
        }
        downLoadFileBlob(res, "application/pdf", fileName);
      })
      .finally(() => {
        setbtnLoading(false);
        setCurBtnIdx(null);
      });
  };
  const _renderBillList = () => {
    return billList.map((item, index) => {
      return (
        <div key={index} className={styles.billItem}>
          <div className={styles.flexRow}>
            <Image unoptimized src={pdfIcon} alt="" width="27" height="27" />
            <span className={styles.billName}>{handleName(item?.reportDate)}</span>
          </div>
          {item?.pdfUrl ? (
            <div className={styles.flexRow}>
              <Button
                className={styles.preview}
                onClick={() => {
                  _preview(item);
                }}>
                <span>{intl["download.portfolio.report.preview"]}</span>
              </Button>
              <Button
                loading={curBtnIdx === index ? btnLoading : false}
                className={styles.download}
                onClick={() => {
                  _download(item, index);
                }}>
                <span> {intl["button.download"]}</span>
              </Button>
            </div>
          ) : null}
        </div>
      );
    });
  };
  return (
    <section className={`${styles.bill} ` + `customer`}>
      <div className="customerTop">
        <Row className="top" justify="space-between">
          <Col>
            <p className="title">{intl["download.portfolio.report"]}</p>
          </Col>
        </Row>
      </div>

      <div className={styles.tableWrap}>
        <div className="section" style={{ width: "100%", marginTop: 0 }}>
          <div className={styles.myInvestmentPlan} style={{ marginTop: 0 }}>
            <div className={styles.myInvestmentPlanArea}>
              <div className={styles.bgImageWrapper}>
                <Image unoptimized src={bg} alt="" width="290" height="410" />
              </div>
              <span className={styles.title}>{intl["download.portfolio.report.title"]}</span>
              <span className={styles.subTitle}>{intl["download.portfolio.report.subTitle"]}</span>
              <WithEmptyContent showEmpty>
                <Spin spinning={loading}>
                  <div className={styles.wrapper}>
                    {billList.length ? (
                      _renderBillList()
                    ) : (
                      <div className={styles.img}>
                        <Image alt="" src={noResultsImg} width={240} height={180} />
                      </div>
                    )}
                  </div>
                </Spin>
              </WithEmptyContent>
              {nextTokenRef?.current ? (
                <div
                  className={styles.loadMore}
                  onClick={() => {
                    reportEvent({
                      moduleName: GA_EVENT_NAME.portfolioReport.loadMore
                    });
                    _getReportList(true, nextTokenRef?.current);
                  }}>
                  {intl["button.loadMore"]}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {showPdf ? (
        <div
          className={styles.pdfWrapper}
          onClick={() => {
            setShowPdf(false);
          }}>
          <iframe
            title="PortfolioReport"
            id="_iframe"
            src={curUrl}
            width="900px"
            height="900px"
            style={{ border: "none" }}
          />
        </div>
      ) : null}
    </section>
  );
};
export const PagePortfolioReport = DownloadBill;
