import React, { useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";
import { Spin, Breadcrumb, Divider, Checkbox, Tooltip, Button } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import styles from "@aspen/theme/Savings.module.less";
import type { IReqNewSubscribeFixedProducts, IYieldNewFixedItem } from "@aspen/model";
import {
  getNewFixedProductsDetail,
  availableSpots,
  subscribeNewFixedProducts
} from "@aspen/services";
import {
  transformTime,
  decimalPointNoMoreX,
  convertUSD2USDC,
  getQueryValue,
  certifiedKycLevel,
  numberToThousands,
  i18nUtil,
  reportEvent,
  AUTHORIZATION_TYPE,
  GA_EVENT_NAME,
  USER_AUTH,
  USER_ROLE_POWER,
  YIELD_PATHS,
  YIELD_TYPES,
  isComplementation
} from "@aspen/libs";
import { message, CoinImage, NumericInput, KycGuideTips, Disclaimer } from "@aspen/ui";
import cycleBigIcon from "@aspen/assets/images/cycle_big.png";
import { ModalSafetyVerification } from "@aspen/widgets";

interface IProps {
  showToProfileBtn?: boolean;
}

function SubscribeFixedYield(props: IProps) {
  const intl = i18nUtil.t();
  const location_text: string = intl["kyc.certify.location"];

  const [loading, setLoading] = useState<boolean>(false);
  const [dataInfo, setDataInfo] = useState<IYieldNewFixedItem>();
  const [available, setAvailable] = useState<number>(0);
  const [amount, setAmount] = useState<number | string>("");
  const [checkAutoRenewal, setCheckAutoRenewal] = useState<boolean>(false); // 本息
  const [checkAutoRenewalPrincipal, setCheckAutoRenewalPrincipal] = useState<boolean>(false); // 仅本金

  const [profit, setProfit] = useState<number | string>("--");
  const [showSafetyVerificationModal, setShowSafetyVerificationModal] = useState<boolean>(false);

  const router = useRouter();
  const id = router.query?.id?.toString() ?? getQueryValue("id");
  const showKycTips = !certifiedKycLevel(1);

  useEffect(() => {
    initData();
  }, []);

  const initData = () => {
    setLoading(true);
    const param = {
      id
    };
    getNewFixedProductsDetail(param)
      .then((res) => {
        if (res?.code == "0") {
          setDataInfo(res?.data);
          setCheckAutoRenewal(res?.data?.autoRenewal);
          const targetCurrency = res.data?.currency;
          const params = {
            currency: targetCurrency
          };
          availableSpots(params)
            .then((res) => {
              if (res?.code == "0") {
                setAvailable(res.data?.[targetCurrency]);
              }
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          setLoading(false);

          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  // 只支持正整数输入
  const handleChangeAmount = (e: string | number) => {
    setAmount(e);
    calculateProfit(e);
  };

  const errAmountTips = () => {
    const formatMessage = i18nUtil.formatMessage;
    const { fixedPersonalLimit } = dataInfo || {};
    const {
      personalShareLimit = 0,
      personalShareMinLimit = 0,
      shareAmount = 1
    } = fixedPersonalLimit || {};
    if (!amount) {
      return "";
    }

    if (personalShareMinLimit && amount < personalShareMinLimit) {
      return formatMessage(
        { id: "savings.new.fixed.amount.less" },
        {
          amount: numberToThousands(personalShareMinLimit),
          currency: convertUSD2USDC(dataInfo?.currency ?? "")
        }
      );
    }
    if (personalShareLimit && amount > personalShareLimit) {
      return formatMessage(
        { id: "savings.new.fixed.amount.more" },
        {
          amount: numberToThousands(personalShareLimit),
          currency: convertUSD2USDC(dataInfo?.currency ?? "")
        }
      );
    }
    if (amount > available) {
      return intl["savings.new.fixed.amount.more.available"];
    }

    if (!isComplementation(amount, shareAmount)) {
      return formatMessage(
        { id: "savings.new.fixed.amount.complementation" },
        {
          shareAmount
        }
      );
    }

    return "";
  };

  //计算预计收益
  const calculateProfit = (amount: string | number) => {
    if (amount === "") {
      setProfit("--");
      return;
    }
    const { lockPeriod = 0, apy = 0 } = dataInfo || {};
    const principal = Number(amount); // 本金
    let fixedProfit = 0;
    //单一年化
    fixedProfit = principal * (Math.pow(1 + apy * 0.01, lockPeriod / 365) - 1);
    setProfit(decimalPointNoMoreX(fixedProfit));
  };

  const fetchSubscribe = (emailCode?: string) => {
    const params: IReqNewSubscribeFixedProducts = {
      productId: dataInfo?.id ?? "",
      amount,
      autoRenewal: checkAutoRenewal,
      autoRenewalPrincipal: checkAutoRenewalPrincipal,
      verifyCode: emailCode || ""
    };

    setLoading(true);
    subscribeNewFixedProducts(params)
      .then((res) => {
        if (res?.code == "0") {
          setShowSafetyVerificationModal(false);
          message.success(intl["savings.automatic.add.success"], 1, () => {
            router.push({
              pathname: YIELD_PATHS.YIELD,
              query: { tabType: YIELD_TYPES.fixed }
            });
          });
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  //验证码
  const hideOK = (code: string) => {
    fetchSubscribe(code);
  };

  const handleSubscribe = () => {
    const authorizationType = localStorage.getItem(AUTHORIZATION_TYPE);
    if (
      USER_ROLE_POWER[localStorage.getItem(USER_AUTH) ?? ""]?.actionForOthers &&
      authorizationType === "0"
    ) {
      setShowSafetyVerificationModal(true);
    } else {
      fetchSubscribe();
    }
    reportEvent({
      moduleName: GA_EVENT_NAME.yield.submitSubscribe,
      detailParams: {
        id,
        type: YIELD_TYPES.fixed,
        name: dataInfo?.name ?? ""
      }
    });
  };

  return (
    <>
      <section className={styles.subFixedYield}>
        <div className="flex">
          <Breadcrumb>
            <Breadcrumb.Item>{location_text}: </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link href={{ pathname: YIELD_PATHS.YIELD, query: { tabType: YIELD_TYPES.fixed } }}>
                {intl["savings.fixed.location.lockUp"]}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{intl["savings.fixed.lockUp.subscribe"]}</Breadcrumb.Item>
          </Breadcrumb>
          {showKycTips ? <KycGuideTips showToProfileBtn={props?.showToProfileBtn} /> : null}
        </div>
        <Spin spinning={loading}>
          <div className={styles.container}>
            {dataInfo?.autoRenewal ? (
              <span className={styles.cycleBigIcon}>
                <Image alt="" unoptimized src={cycleBigIcon} width="105" height="106" />
              </span>
            ) : null}
            {dataInfo ? (
              <div className={styles.area}>
                <p className={styles.title}>
                  <CoinImage size={40} coins={convertUSD2USDC(dataInfo?.currency)} />
                  <span style={{ marginLeft: 16 }}>{dataInfo.name}</span>
                </p>
                <div className={styles.content}>
                  <div className={styles.leftContent}>
                    <p className={styles.secTitle}>{intl["overview.savings.subscribe"]}</p>
                    <p className={styles.thirdTitle}>{intl["savings.automatic.add.available"]}</p>
                    <p className={styles.amount}>
                      {numberToThousands(decimalPointNoMoreX(available))}{" "}
                      {convertUSD2USDC(dataInfo?.currency)}
                    </p>
                    <p className={styles.thirdTitle}>
                      {intl["savings.fixed.subscription.unitsavings.fixed.subscription.amount"]}
                    </p>
                    <div className={styles.inputContent}>
                      <NumericInput
                        bordered={false}
                        value={amount}
                        placeholder={intl["savings.new.fixed.input.placeholder"]}
                        className={styles.amountInput}
                        onChangeEvent={handleChangeAmount}
                      />
                      <span className={styles.maxBtn}>{convertUSD2USDC(dataInfo.currency)}</span>
                    </div>
                    <div className={styles.errorTips}>{errAmountTips()}</div>
                    <Divider style={{ margin: "10px 0 22px" }} />
                    <div className={styles.availableArea}>
                      <div className={styles.availableKye}>{intl["savings.new.fixed.minimum"]}</div>
                      <div className={styles.availablevalue}>
                        {dataInfo?.fixedPersonalLimit?.personalShareMinLimit ?? "--"}{" "}
                        {convertUSD2USDC(dataInfo?.currency)}
                      </div>
                    </div>
                    <div className={styles.availableArea}>
                      <div className={styles.availableKye}>{intl["savings.new.fixed.maximum"]}</div>
                      <div className={styles.availablevalue}>
                        {dataInfo?.fixedPersonalLimit?.personalShareLimit ?? "--"}{" "}
                        {convertUSD2USDC(dataInfo?.currency)}
                      </div>
                    </div>
                    <div className={styles.availableArea}>
                      <div className={styles.availableKye}>
                        <span style={{ marginRight: 4 }}>
                          {intl["savings.new.fixed.estimated.interest"]}
                        </span>
                        <Tooltip
                          title={intl["savings.new.fixed.estimated.interest.tips"]}
                          style={{ marginLeft: 4, width: 200 }}>
                          <InfoCircleOutlined className={styles.toolTipIcon} />
                        </Tooltip>
                      </div>
                      <div className={styles.availablevalue}>
                        {numberToThousands(profit)} {convertUSD2USDC(dataInfo?.currency)}
                      </div>
                    </div>
                    <div className={styles.availableArea}>
                      <div className={styles.availableKye}>
                        <span style={{ marginRight: 4 }}>
                          {intl["savings.new.fixed.redemption.period"]}
                        </span>
                        <Tooltip
                          title={intl["savings.new.fixed.redemption.period.tips"]}
                          style={{ marginLeft: 4, width: 200 }}>
                          <InfoCircleOutlined className={styles.toolTipIcon} />
                        </Tooltip>
                      </div>
                      <div className={styles.availablevalue}>
                        {`${dataInfo?.redeemConfirmPeriod} ${intl["savings.fixed.day.s"]}`}
                      </div>
                    </div>
                    {dataInfo?.autoRenewalPrincipal || dataInfo?.autoRenewal ? (
                      <div>
                        <Divider style={{ margin: "10px 0 22px" }} />
                        {dataInfo?.autoRenewal ? (
                          <div className={styles.checkbox}>
                            <Checkbox
                              style={{ marginBottom: 18 }}
                              checked={checkAutoRenewal}
                              onChange={(e) => {
                                if (checkAutoRenewalPrincipal && e.target.checked) {
                                  message.info(intl["savings.new.fixed.principle.tips"]);
                                  return;
                                }
                                setCheckAutoRenewal(e.target.checked);
                                if (e.target.checked) setCheckAutoRenewalPrincipal(false);
                              }}
                            />
                            <span className={styles.checkboxText}>
                              {intl["savings.new.fixed.next.cycle"]}
                            </span>
                            <Tooltip
                              title={intl["savings.new.fixed.next.cycle.tips"]}
                              style={{ width: 200 }}>
                              <InfoCircleOutlined className={styles.toolTipIcon} />
                            </Tooltip>
                          </div>
                        ) : null}
                        {dataInfo?.autoRenewalPrincipal ? (
                          <div className={styles.checkbox}>
                            <Checkbox
                              style={{ marginBottom: 18 }}
                              checked={checkAutoRenewalPrincipal}
                              onChange={(e) => {
                                if (checkAutoRenewal && e.target.checked) {
                                  message.info(intl["savings.new.fixed.principle.tips"]);
                                  return;
                                }
                                setCheckAutoRenewalPrincipal(e.target.checked);
                                if (e.target.checked) setCheckAutoRenewal(false);
                              }}
                            />
                            <span className={styles.checkboxText}>
                              {intl["savings.new.fixed.principle.next.cycle"]}
                            </span>
                            <Tooltip
                              title={intl["savings.new.fixed.principle.next.cycle.tips"]}
                              style={{ width: 200 }}>
                              <InfoCircleOutlined className={styles.toolTipIcon} />
                            </Tooltip>
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                    <div className={styles.btnArea}>
                      <Button
                        style={{ width: 128, marginRight: 16 }}
                        className={styles.actionBtn}
                        onClick={() => {
                          router.push({
                            pathname: YIELD_PATHS.YIELD,
                            query: { tabType: YIELD_TYPES.fixed }
                          });
                        }}>
                        {intl["button.back"]}
                      </Button>
                      <Button
                        style={{ width: 128 }}
                        disabled={amount == "" || !!errAmountTips()}
                        type="primary"
                        onClick={handleSubscribe}>
                        {intl["button.subscribe"]}
                      </Button>
                    </div>
                  </div>

                  <div className={styles.rightContent}>
                    <p className={styles.secTitle}>{intl["savings.fixed.details"]}</p>
                    <div className={styles.timeArea}>
                      <div className={styles.first}>
                        <p className={styles.firstTitle}>{intl["savings.fixed.subscription"]}</p>
                        <div className={styles.firstContent}>
                          <p>{intl["savings.fixed.cut.off"]}</p>
                          <p>{transformTime(dataInfo?.lockStartTime)}</p>
                        </div>
                      </div>
                      <div className={styles.second}>
                        <p className={styles.secondTitle}>{intl["savings.fixed.lockUp"]}</p>
                        <div className={styles.secondContent}>
                          <p>
                            {intl["savings.fixed.start"]}
                            {transformTime(dataInfo?.lockStartTime)}
                          </p>
                          <p>
                            {intl["savings.fixed.end"]}
                            {transformTime(dataInfo?.lockEndTime)}
                          </p>
                        </div>
                      </div>
                      <div className={styles.third}>
                        <p className={styles.thirdTitle}>{intl["savings.history.redemption"]}</p>
                        <div className={styles.thirdContent}>
                          <p>{intl["savings.new.fixed.Transfer.time"]}</p>
                        </div>
                      </div>
                    </div>
                    <div className={styles.availableArea}>
                      <div className={styles.availableKye}>{intl["column.denomination"]}</div>
                      <div className={styles.availablevalue}>
                        {convertUSD2USDC(dataInfo?.currency)}
                      </div>
                    </div>
                    <div className={styles.availableArea}>
                      <div className={styles.availableKye}>{intl["savings.fixed.lock.period"]}</div>
                      <div className={styles.availablevalue}>
                        {dataInfo?.lockPeriod} {intl["savings.fixed.days"]}
                      </div>
                    </div>

                    <div className={styles.availableArea}>
                      <div className={styles.availableKye}>{intl["column.apy"]}</div>
                      <div className={styles.availablevalue}>{dataInfo?.apy}%</div>
                    </div>

                    <div className={styles.availableArea}>
                      <div className={styles.availableKye}>
                        <span style={{ marginRight: 4 }}>
                          {intl["savings.new.fixed.redemption.period"]}
                        </span>
                        <Tooltip
                          title={intl["savings.new.fixed.redemption.period.tips"]}
                          style={{ marginLeft: 4, width: 200 }}>
                          <InfoCircleOutlined className={styles.toolTipIcon} />
                        </Tooltip>
                      </div>
                      <div className={styles.availablevalue}>
                        {`${dataInfo?.redeemConfirmPeriod} ${intl["savings.fixed.day.s"]}`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <ModalSafetyVerification
              confirmLoading={loading}
              visible={showSafetyVerificationModal}
              handleCancel={() => {
                setShowSafetyVerificationModal(false);
              }}
              onOk={hideOK}
              businessType={-1}
              isCustomerValidate
            />
          </div>
        </Spin>
      </section>
      <Disclaimer />
    </>
  );
}

export const PageNewSubsFixed = React.memo(SubscribeFixedYield);
