import React, { useEffect, useRef, useState } from "react";
import styles from "@aspen/widgets/login/Login.module.less";
import { useRouter } from "next/router";
import type { InputRef } from "antd";
import { Button, Form } from "antd";
import type { EmailCodeBaseProps } from "@aspen/ui";
import { EmailCodeLayout, message, WithTrimInput } from "@aspen/ui";
import { agentLogin, agentLogout, fecthCustomerSetting, getConfigInfo } from "@aspen/services";
import {
  ASSETANALYSIS,
  clearCookies,
  clearLocalStorage,
  PARTNER_PATH,
  cookieUtil,
  CUSTOMER_ID,
  CUSTOMER_INFO,
  ERROR,
  formatBugsnagMessage,
  GA_EVENT_NAME,
  getSessionStorageTokenKey,
  handleBlur,
  handleFocus,
  i18nUtil,
  IS2FA,
  IS_AUTHENTICATED,
  IS_GOOGLE_Certified,
  isEmail,
  LOGIN_ERROR_TIP_MSG,
  PASSWORD_MAX_LENGTH,
  reportEvent,
  SHOW_RECAPTCHA,
  USER,
  USER_AUTH,
  VerifyCode_MAX_LENGTH,
  md5Encrypted,
  MD5_FIXED_STRING
} from "@aspen/libs";
import { VERIFY_TYPE } from "@aspen/model";

import Bugsnag from "@bugsnag/js";
import clsx from "clsx";
import {
  LoginBaseHandle,
  ErrorTip,
  NVCCaptcha,
  LoginLayoutCMS,
  AlicloudNCCaptcha
} from "@aspen/widgets";

interface IProps extends EmailCodeBaseProps {}

const AgentLogin: React.FC<IProps> = (props: IProps) => {
  const intl = i18nUtil.t();

  const [accountName, setAccountName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [resError, setResError] = useState<string | null>(null);

  const emailInput: React.MutableRefObject<InputRef | null> = useRef<InputRef | null>(null);
  const [form] = Form.useForm();
  const router = useRouter();

  // 滑动验证参数
  const [ncVal, setNcVal] = useState<Record<string, any> | null>(null);
  // 2FA验证类型
  const [validChannel, setValidChannel] = useState<boolean>(false);
  const [supportValidChannel, setSupportValidChannel] = useState<string[]>([]);
  const [validChannelLoading, setValidChannelLoading] = useState<boolean>(false);
  const [verifyCode, setVerifyCode] = useState<string>("");

  const NCRef = useRef<{
    setVisible: (bool: boolean) => void;
    init: (scene: string, successCallback) => void;
    reset: () => void;
  } | null>();

  // 处理退出登录 信息清除等
  const init = () => {
    const error = localStorage.getItem(ERROR);
    if (error) {
      message.error(intl[error]);
    }
    emailInput?.current?.focus();
    form.setFieldsValue({ email: accountName, password });

    agentLogout().finally(() => {
      clearLocalStorage();
      clearCookies();
    });
  };

  // 获取配置信息，afsOpen 是否开启阿里云验证开关
  const getConfigData = () => {
    // 设置默认值为true，若接口有问题，不会导致登录接口显示缺乏参数 header
    localStorage.setItem(SHOW_RECAPTCHA, "true");
    localStorage.setItem(IS2FA, "true");
    // FIXME: 显示隐藏资产分析入口
    localStorage.setItem(ASSETANALYSIS, "false");

    getConfigInfo().then((res) => {
      if (res?.code == "0") {
        localStorage.setItem(SHOW_RECAPTCHA, res.data?.afsOpen);
        localStorage.setItem(IS2FA, res.data?.is2fa);
        // 资产分析
        localStorage.setItem(ASSETANALYSIS, res.data?.assetAnalysis);
      }
    });
  };

  useEffect(() => {
    init();
    getConfigData();

    // Prefetch the home page
    router.prefetch(PARTNER_PATH.DASHBOARD);
  }, []);

  useEffect(() => {
    emailInput?.current?.focus();
    setValidChannel(false);
    setSupportValidChannel([]);
  }, [accountName]);

  // ncVal: 滑动验证成功后的信息 滑动验证成功后，直接登录
  useEffect(() => {
    if (ncVal) handleSubmit();
  }, [ncVal]);

  // 获取账户的登录验证渠道
  const getValidChannel = (onSuccess?: (_supportValidChannel: string[]) => void) => {
    if (localStorage.getItem(IS2FA) === "false") return;

    setValidChannelLoading(true);
    fecthCustomerSetting({ accountName })
      .then((res) => {
        if (res?.code == "0") {
          setValidChannel(true);
          const _supportValidChannel = res?.data?.supportValidChannels ?? [];
          setSupportValidChannel(_supportValidChannel);
          onSuccess && onSuccess(_supportValidChannel);
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        setValidChannelLoading(false);
      });
  };

  const handleEmailBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    // 失焦时，获取验证渠道
    if (accountName && isEmail(accountName)) {
      getValidChannel();
    }
    handleBlur(e);
  };

  const handleSubmit: () => void = () => {
    // 若没触发input失焦，则点击登录时 先获取账户的登录验证渠道
    const is2fa = localStorage.getItem(IS2FA) !== "false";
    if (is2fa && !validChannel && !verifyCode) {
      setValidChannelLoading(true);
      getValidChannel((_supportValidChannel) => {
        // 如果未kyc或是白名单用户，直接登录
        if (_supportValidChannel.includes(VERIFY_TYPE.unrequired)) {
          recaptchaSubmit();
        }
      });
      return;
    }
    // 阿里云人机验证
    recaptchaSubmit();
    // 事件上报
    reportEvent({ moduleName: GA_EVENT_NAME.login.loginPartner });
  };

  const recaptchaSubmit: () => void = () => {
    const showRecaptcha = localStorage.getItem(SHOW_RECAPTCHA) !== "false";
    // 是否开启阿里云验证
    if (showRecaptcha) {
      ncVal?.sessionId
        ? fetchSubmit(ncVal, "nc") // 滑动验证提交
        : NVCCaptcha.getVal((nvcVal) => {
            fetchSubmit(nvcVal, "nvc"); // 无痕验证提交
          });
    } else {
      fetchSubmit();
    }
  };

  // data 人机信息数据 type nc滑动， nvc无痕
  const fetchSubmit = async (data?: any, type?: string) => {
    // 当用户点击登录时，先保存用户名，上报信息时使用
    sessionStorage.setItem(USER, accountName);

    setLoading(true);
    const params = {
      accountName,
      password: md5Encrypted(password, MD5_FIXED_STRING)
    };

    const headers = {};
    const showRecaptcha = localStorage.getItem(SHOW_RECAPTCHA) !== "false";

    // 滑动验证参数
    if (showRecaptcha) {
      if (type === "nc") {
        headers["SG-Verification-Type"] = 1; // 1表示滑动验证
        headers["SG-Verification-Session-Id"] = data.sessionId;
        headers["SG-Verification-Sig"] = data.sig;
        headers["SG-Verification-Token"] = data.token;
        headers["SG-Verification-Scene"] = "nc-login-h5";
      } else {
        headers["SG-Verification-Type"] = 3; // 3表示无痕验证
        headers["SG-Verification-Data"] = data;
      }
    }

    // 2FA验证参数
    const is2fa = localStorage.getItem(IS2FA) !== "false";
    if (is2fa) {
      headers["is2fa"] = is2fa;
      headers["validCode"] = verifyCode;
      headers["validChannel"] = supportValidChannel.includes(VERIFY_TYPE.google)
        ? VERIFY_TYPE.google
        : VERIFY_TYPE.email;
    }

    agentLogin(params, headers)
      .then((res) => {
        if (res?.code == "0") {
          const { user_id, account_id, jwtToken, isGoogleCertified, user_roles, customer_info } =
            res?.data ?? {};

          localStorage.setItem(
            USER_AUTH,
            user_roles ? user_roles.join(",").toLocaleLowerCase() : ""
          );
          sessionStorage.setItem(getSessionStorageTokenKey(location.origin), jwtToken ?? "");
          cookieUtil.set(getSessionStorageTokenKey(location.host), jwtToken ?? "");
          localStorage.setItem(IS_AUTHENTICATED, "true");
          sessionStorage.setItem(USER, accountName);
          sessionStorage.setItem(CUSTOMER_ID, account_id ?? user_id);
          cookieUtil.set(USER, accountName);
          cookieUtil.set(CUSTOMER_ID, account_id ?? user_id);
          sessionStorage.setItem(CUSTOMER_INFO, customer_info);
          localStorage.setItem(IS_GOOGLE_Certified, Number(isGoogleCertified).toString());
          router.push(PARTNER_PATH.DASHBOARD);
          setTimeout(() => {
            setLoading(false);
          }, 3000);
        } else if (res.code == "400" || res.code == "800") {
          setLoading(false);
          // 无痕验证失败，启动滑动验证
          if (!ncVal) {
            NCRef.current?.setVisible(true);
            NCRef.current?.init("nc_login_h5", (data) => {
              setNcVal(data);
              // 滑动验证通过后关闭弹框
              NCRef.current?.setVisible(false);
            });
          }
        } else if (res.code == "900") {
          setLoading(false);
          // 二次滑动验证失败
          NCRef.current?.reset();
          message.error(intl["afs.nvc.fail"] ?? res?.msg);
          Bugsnag.notify(new Error(formatBugsnagMessage(JSON.stringify(res))));
        } else {
          /*错误判断包含：
           * 1. 账号未注册，服务器端判断返回
           * 2. 账号或者密码错误，服务器端判断返回
           * 3. 密码错误超过5次，锁定2小时
           */
          setLoading(false);
          setNcVal(null);
          // 如果接口返回错误是：账号或密码错误，显示为元素报错，其他错误显示为message提示
          if (LOGIN_ERROR_TIP_MSG.includes(res?.msg)) {
            setResError(res?.msg);
          } else {
            message.error(intl[res?.msg] ?? res?.msg);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.status == 400 && !ncVal) {
          NCRef.current?.setVisible(true);
          NCRef.current?.init("nc_login_h5", (data) => {
            setNcVal(data);
            // 滑动验证通过后关闭弹框
            NCRef.current?.setVisible(false);
          });
        }
      });
  };

  const handleChangeVerifyCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    const onlyNumber = val.replace(/[^0-9]/gi, "");
    setVerifyCode(onlyNumber);
  };

  const title: string = intl["login.text.CMS"];
  const messageEmail: string = intl["register.email.incorrect"];

  const buttonDisabled =
    supportValidChannel.includes(VERIFY_TYPE.email) ||
    supportValidChannel.includes(VERIFY_TYPE.google)
      ? !(accountName && password && verifyCode && verifyCode.length >= 6)
      : !(accountName && password);

  return (
    <div className={styles.form}>
      <LoginBaseHandle />
      <div className={styles.container}>
        <p className={styles.title}> {title} </p>
        <ErrorTip errorText={resError || ""} />
        <Form
          name="basic"
          layout="vertical"
          autoComplete="off"
          form={form}
          initialValues={{ accountName, password }}
          onFinish={handleSubmit}>
          <Form.Item
            className={styles.email}
            name="email"
            validateTrigger="onBlur"
            getValueFromEvent={(e) => e.target.value.trim()}>
            <div>
              <label className={clsx(styles.label, accountName ? styles.active : "")}>
                {intl["login.email"]}
              </label>
              <WithTrimInput
                className={clsx(
                  accountName ? styles.inputHover : "",
                  resError && styles.errorFormBorder
                )}
                autoComplete="new-password"
                ref={emailInput}
                name="accountName"
                value={accountName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setAccountName(e.target.value)
                }
                onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
                  form.setFields([
                    {
                      name: "email",
                      value: e.target.value,
                      errors: undefined
                    }
                  ]);
                  handleFocus?.(e);
                }}
                onBlur={handleEmailBlur}
              />
            </div>
          </Form.Item>
          <Form.Item>
            <div>
              <label className={clsx(styles.label, password ? styles.active : "")}>
                {intl["login.password"]}
              </label>
              <WithTrimInput
                className={clsx(
                  password ? styles.inputHover : "",
                  resError && styles.errorFormBorder
                )}
                name="password"
                type="password"
                autoComplete="new-password"
                maxLength={PASSWORD_MAX_LENGTH}
                value={password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </div>
          </Form.Item>
          {/* 2FA及验证查看是否可以用如下代码代替 @赵靖 */}
          {/* <div
            className={styles.inputFillBox}
            style={{ display: currentTab === ACCOUNT_TYPE.EMAIL ? "block" : "none" }}>
            <LoginTabViewPortal {...props} accountType={ACCOUNT_TYPE.EMAIL} />
          </div> */}
          {/* 邮箱验证 */}
          {!supportValidChannel.includes(VERIFY_TYPE.google) &&
            supportValidChannel.includes(VERIFY_TYPE.email) && (
              <div className={styles.eamilGroup}>
                <Form.Item
                  name="verifyCode"
                  rules={[
                    {
                      required: true,
                      message: intl["customer.email.code.incorrect"]
                    }
                  ]}>
                  <div>
                    <label className={clsx(styles.label, verifyCode ? styles.active : "")}>
                      {intl["login.email.verify.code"]}
                    </label>
                    <WithTrimInput
                      className={verifyCode ? styles.inputHover : ""}
                      autoComplete="new-password"
                      value={verifyCode}
                      onChange={handleChangeVerifyCode}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      maxLength={VerifyCode_MAX_LENGTH}
                    />
                  </div>
                </Form.Item>
                <Form.Item>
                  <Button
                    disabled={
                      !accountName ||
                      (accountName && !isEmail(accountName)) ||
                      props.countdown != 60
                    }
                    loading={props.loading}
                    className={styles.sendEmail}
                    onClick={() => {
                      props.getEmailCode(17, accountName);
                      reportEvent({
                        moduleName: GA_EVENT_NAME.login.sendEmailCode
                      });
                    }}>
                    {props.sendMailBtn}
                  </Button>
                </Form.Item>
              </div>
            )}
          {/*谷歌验证 */}
          {supportValidChannel.includes(VERIFY_TYPE.google) && (
            <Form.Item
              name="verifyCode"
              rules={[
                {
                  required: true,
                  message: intl["customer.email.code.incorrect"]
                }
              ]}>
              <div>
                <label className={clsx(styles.label, verifyCode ? styles.active : "")}>
                  {intl["login.google.verify.code"]}
                </label>
                <WithTrimInput
                  className={verifyCode ? styles.inputHover : ""}
                  autoComplete="new-password"
                  value={verifyCode}
                  onChange={handleChangeVerifyCode}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  maxLength={VerifyCode_MAX_LENGTH}
                />
              </div>
            </Form.Item>
          )}
          <Button
            className={styles.submit}
            type="primary"
            htmlType="submit"
            disabled={buttonDisabled}
            loading={loading || validChannelLoading}>
            {intl["login"]}
          </Button>
        </Form>
        {/* 滑动验证 */}
        <AlicloudNCCaptcha ref={NCRef} />
      </div>
    </div>
  );
};

export const PagePartnerLogin: React.FC = React.memo(LoginLayoutCMS(EmailCodeLayout(AgentLogin)));
