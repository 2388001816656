import React from "react";

import styles from "./UploadFile.module.less";
import { Upload } from "antd";
import { message } from "../customMessage";
import { getOssData, getOssSignUrl } from "@aspen/services/index";
import { formatBugsnagMessage, i18nUtil, ALI_SDK_SRC, isClient } from "@aspen/libs/index";
import Bugsnag from "@bugsnag/js";
import Image from "next/image";
import Script from "next/script";

const uploadIcon = require("@aspen/assets/images/upload.png");
const appUploadIcon = require("@aspen/assets/images/ic_upload.webp");

const { Dragger } = Upload;

interface IProps {
  getUploadInfo: (urlList: Array<string> | []) => void;
  text: string | number;
  echo?: Array<string> | [];
  type?: string;
  uploadFailed?: (msg?: string) => void;
  uploadSuccess?: (url: string) => void;
  startUpload?: () => void;
  fromApp?: boolean;
}
interface IState {
  file: File | null;
  fileError: boolean | null;
  disabled: boolean;
  fileList: Array<string> | [];
}

class UploadFile extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      fileError: null,
      disabled: false,
      fileList: []
    };
  }
  componentDidMount() {
    this.props.echo && this.setState({ fileList: this.props.echo });
  }

  echoListRender = (values: Array<string>) => {
    let arr = [];
    values.map((item, index) => {
      const name = item
        ?.substring(item?.lastIndexOf("/") + 1, item?.length)
        ?.split("_")
        ?.slice(1)
        ?.join("_");
      // @ts-ignore TODO， check @赵靖
      arr.push({
        uid: `${index + 1}`,
        name,
        url: item
      });
    });
    return arr;
  };

  //上传前
  beforeUpload = async (file) => {
    const { fromApp, uploadFailed, startUpload } = this.props;
    const intl = i18nUtil.t();
    this.setState({ fileError: false });
    const { type } = this.props;
    startUpload && startUpload();
    // 获取上传信息
    // @ts-ignore TODO， check @赵靖
    const result = await getOssData({ path: type })
      .then((res) => {
        if (res?.code == "0") {
          // 校验上传的文件类型
          const allowedMimeTypes = [
            "image/png",
            "image/jpeg",
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ];
          if (!allowedMimeTypes.includes(file.type)) {
            message.error(intl["upload.type.limit"]);
            return;
          }
          return res.data;
        }
        if (fromApp) {
          uploadFailed && uploadFailed();
        } else {
          message.error("Get OSSData failed");
        }
        Bugsnag.notify(new Error(formatBugsnagMessage(JSON.stringify(res, null, "\t"))));
      })
      .catch((err) => {
        if (fromApp) {
          uploadFailed && uploadFailed(err?.msg || "Get OSSData failed");
        }
      });

    if (!result) {
      return Upload.LIST_IGNORE;
    }
    const info = {
      region: "oss-ap-southeast-1",
      accessKeyId: result.accessKey,
      accessKeySecret: result.accessSecret,
      stsToken: result.securityToken,
      bucket: result.bucket,
      secure: true
    };
    this.setState({ disabled: true });

    const isLt30M = file.size / 1024 / 1024 < 30;
    if (!isLt30M) {
      if (fromApp) {
        uploadFailed && uploadFailed(intl["upload.size.limit"]);
      } else {
        message.error(intl["upload.size.limit"]);
      }
      this.setState({ disabled: false });
      return Upload.LIST_IGNORE;
    }

    const filename = `${Date.now()}_${file.name}`;

    file.key = `${type}/${result.customerId}/${filename}`;
    file.url = `https://${info.bucket}.${result.endpoint}/${type}/${result.customerId}/${filename}`; //得到上传后的url
    file.info = info;

    return file;
  };

  render(): React.ReactNode {
    const intl = i18nUtil.t();
    const { text, getUploadInfo, echo, fromApp, uploadFailed, uploadSuccess } = this.props;
    const { fileError, fileList } = this.state;
    const props = {
      showUploadList: !fromApp,
      name: "file",
      defaultFileList: echo && echo.length ? this.echoListRender(echo) : [],
      accept: ".png, .jpg, .pdf, .svg, .doc, .docx",
      onPreview(file) {
        const params = {
          filePath: file.url
        };
        getOssSignUrl(params).then((res) => {
          // 预览时拿后台签名后地址才有权限预览
          if (res?.code == "0") {
            isClient && res?.data && window.open(res.data);
          } else {
            message.success(intl["upload.preview.tips"]);
          }
        });
      },
      customRequest: (options) => {
        const file = options.file;
        const client = new window.OSS(file.info);
        return client
          .put(file.key, file)
          .then((result) => {
            if (fromApp) {
              uploadSuccess && uploadSuccess(file.url);
            } else {
              message.success(
                i18nUtil.formatMessage({ id: "upload.success" }, { name: file.name })
              );
            }
            options.onSuccess(result, file); //upload组建上传成功方法调用
            let fileListArr = [...fileList];
            fileListArr.push(file.url);
            this.setState(
              { file, fileError: false, disabled: false, fileList: fileListArr },
              () => {
                getUploadInfo(fileListArr); //上传成功返回urlList
              }
            );
          })
          .catch((err) => {
            this.setState({ file: null, fileError: true, disabled: false });
            if (fromApp) {
              uploadFailed && uploadFailed();
            } else {
              message.error(i18nUtil.formatMessage({ id: "upload.failed" }, { name: file.name }));
            }
            options.onError(err, file); //upload组建上传失败方法调用
            Bugsnag.notify(
              new Error(
                formatBugsnagMessage(
                  `${file.name} Error,
                File info: ${JSON.stringify(file, null, "\t")}
                Error data: ${err.toString()}`,
                  "Action-bank-Upload-file"
                )
              )
            );
          });
      },
      beforeUpload: this.beforeUpload,
      onRemove: (file) => {
        const fileListArr = [...fileList].filter((e) => e !== file.url);
        this.setState({ fileList: fileListArr }, () => {
          getUploadInfo(fileListArr); //删除成功成功返回urlList
        });
      },
      onDrop(e) {
        console.info("Dropped files", e.dataTransfer.files);
      }
    };

    return (
      <>
        <Script strategy="afterInteractive" src={ALI_SDK_SRC} />
        <Dragger
          {...props}
          disabled={this.state.disabled}
          className={fromApp ? styles.appUpload : styles.uploadArea}>
          {fromApp ? (
            <div className={styles.appContent}>
              <Image unoptimized src={appUploadIcon} width={20} height={20} alt="" />
              <span className={styles.text}>{text}</span>
            </div>
          ) : (
            <p className={`${styles.uploadAreaContent} ant-upload-drag-icon`}>
              <span style={{ margin: "0 24px" }}>
                <Image unoptimized src={uploadIcon} width={40} height={40} alt="" />
              </span>
              <span className={styles.uploadText}>{text}</span>
            </p>
          )}
        </Dragger>
        {!this.state.file && fileError && !fromApp ? (
          <p className={styles.uploadFailTip}>{intl["wallet.upload.file.fail.tip"]}</p>
        ) : null}
      </>
    );
  }
}

export default React.memo(UploadFile);
