import React from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { Breadcrumb } from "antd";
import { getQueryValue, i18nUtil, WALLET_PATHS } from "@aspen/libs";
import { IVaultType, VAULT_ACTION_TYPE } from "@aspen/model";
import { VaultHistory } from "@aspen/widgets";

const VaultHistoryView: React.FC = () => {
  const intl = i18nUtil.t();
  const location = intl["kyc.certify.location"];
  const coldWallet = intl["wallet.cold.wallet"];
  const storageHistory = intl["wallet.vault.storage.history"];
  const withdrawHistory = intl["wallet.vault.withdraw.history"];

  const router = useRouter();

  const type = (router.query.type ?? getQueryValue("type")) as IVaultType;
  const currency = router.query.currency ?? getQueryValue("currency");

  return (
    <>
      <Breadcrumb style={{ marginBottom: "24px" }}>
        <Breadcrumb.Item>{location}:</Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link
            href={
              type === VAULT_ACTION_TYPE.STORAGE
                ? WALLET_PATHS.WALLET_VAULT_STORAGE
                : WALLET_PATHS.WALLET_VAULT
            }>
            {coldWallet}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {type === VAULT_ACTION_TYPE.STORAGE ? storageHistory : withdrawHistory}
        </Breadcrumb.Item>
      </Breadcrumb>
      <VaultHistory showViewAll={false} type={type} currency={currency?.toString()} />
    </>
  );
};

export const PageVaultHistory = React.memo(VaultHistoryView);
